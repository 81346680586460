import React, { useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { z } from "zod";

import modaresaLogo from "@app/assets/logos/moda-resa.svg";
import InputLabel from "@components/data-display/InputLabel";
import AddressAutocomplete from "@components/data-entry/AddressAutocomplete";
import Button from "@components/data-entry/Button";
import MultiSelect from "@components/data-entry/MultiSelect";
import { inputStyle } from "@components/data-entry/TextField";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import { organizationSchema } from "@models/Organization";
import { GetAllBrandsEndpoint } from "@services/api/brands/get-all-brands";
import { CreateOrganization } from "@services/api/organization/CreateOrganization";
import { auth } from "@services/authentication/firebase";
import useAuthentication from "@services/authentication/useAuthentication";
import TimezoneAutocomplete from "@shared/components/form/timezone-autocomplete";

export function useBrandSignupLink() {
  const { brandId } = useParams();

  useEffect(() => {
    if (brandId) {
      localStorage.setItem("signup-as-brand", brandId || "");
    }
  }, [brandId]);

  return [
    localStorage.getItem("signup-as-brand"),
    () => localStorage.removeItem("signup-as-brand"),
  ] as const;
}

const seasonsOptions = [
  { label: "Autumn / Winter - Pre collection", value: "PRE_AW" },
  { label: "Autumn / Winter", value: "AW" },
  { label: "Spring / Summer - Pre collection", value: "PRE_SS" },
  { label: "Spring / Summer", value: "SS" },
];

const marketsCategoriesOptions = [
  {
    label: "Women",
    options: [
      { label: "RTW", value: "WOMEN_RTW" },
      { label: "Bags", value: "WOMEN_BAGS" },
      { label: "Shoes", value: "WOMEN_SHOES" },
      { label: "Accessories", value: "WOMEN_ACCESSORIES" },
    ],
  },
  {
    label: "Men",
    options: [
      { label: "RTW", value: "MEN_RTW" },
      { label: "Bags", value: "MEN_BAGS" },
      { label: "Shoes", value: "MEN_SHOES" },
      { label: "Accessories", value: "MEN_ACCESSORIES" },
    ],
  },
  {
    label: "Children",
    options: [
      { label: "RTW", value: "CHILDREN_RTW" },
      { label: "Bags", value: "CHILDREN_BAGS" },
      { label: "Shoes", value: "CHILDREN_SHOES" },
      { label: "Accessories", value: "CHILDREN_ACCESSORIES" },
    ],
  },
  {
    label: "Home",
    value: "HOME",
  },
];
const marketsCategoriesOptionsFlat = marketsCategoriesOptions.flatMap((g) =>
  g.options
    ? g.options.map((o) => ({ ...o, label: `${g.label} ${o.label}` }))
    : g,
);

const activeLocationsOptions = [
  {
    label: "Paris",
    value: "PARIS",
  },
  {
    label: "London",
    value: "LONDON",
  },
  {
    label: "Milan",
    value: "MILAN",
  },
  {
    label: "New York",
    value: "NEW_YORK",
  },
  {
    label: "Tokyo",
    value: "TOKYO",
  },
];

const createOrganizationFormSchema = z.object({
  name: organizationSchema.shape.name,
  type: organizationSchema.shape.type,
  address: organizationSchema.shape.address,
  timezone: organizationSchema.shape.timezone,
  brand: z
    .object({
      id: z.string().uuid().optional(),
      numberOfStockists: z.string(),
      activeSeasons: z.array(z.string()),
      numberOfYearlyCollections: z.string(),
      activeMarkets: z.array(z.string()),
      activeLocations: z.array(
        z.object({
          label: z.string(),
          value: z.string(),
        }),
      ),
    })
    .optional(),
});
type CreateOrganizationFormData = z.infer<typeof createOrganizationFormSchema>;

export default function CreateOrganizationPage() {
  const [brandSignupId, removeBrandSignupId] = useBrandSignupLink();
  const { updateUser } = useAuthentication();
  const navigate = useNavigate();
  const { data: brands = [] } = GetAllBrandsEndpoint.useHook();

  const canChangeBrand =
    !brandSignupId || !brands.find((b) => b.id === brandSignupId);

  const form = useForm<CreateOrganizationFormData>({
    resolver: zodResolver(createOrganizationFormSchema),
    defaultValues: {
      name: "",
      type: "BRAND",
      brand: {
        id: brandSignupId || undefined,
        activeMarkets: [],
        activeLocations: [],
        activeSeasons: [],
      },
    },
  });

  const { mutateAsync: createOrganization } = CreateOrganization.useHook();

  const submit = async (formData: CreateOrganizationFormData) => {
    let data: CreateOrganization.Input;
    if (formData.type === "BRAND") {
      if (!formData.brand) {
        throw new Error("Brand is required");
      }
      data = {
        name: formData.name,
        type: formData.type,
        address: formData.address,
        timezone: formData.timezone,
        brand: {
          numberOfStockists: formData.brand.numberOfStockists,
          activeSeasons: formData.brand.activeSeasons,
          numberOfYearlyCollections: formData.brand.numberOfYearlyCollections,
          activeMarkets: formData.brand.activeMarkets,
          activeLocations: formData.brand.activeLocations.map((l) => l.value),
        },
      };
    } else {
      data = {
        name: formData.name,
        type: formData.type,
        address: formData.address,
        timezone: formData.timezone,
      };
    }

    const organization = await createOrganization({
      data,
    });

    const idTokenResult = await auth.currentUser?.getIdTokenResult();
    if (idTokenResult) {
      removeBrandSignupId();
      await updateUser(idTokenResult.token);
      toast.success(
        t("Toast.organization-{{name}}-created", { name: organization.name }),
      );
      navigate("/dashboard");
    }
  };

  const {
    formState: { errors },
  } = form;
  const address = form.watch("address");
  const timezone = form.watch("timezone");
  const activeSeasons = form.watch("brand.activeSeasons");
  const activeMarkets = form.watch("brand.activeMarkets");
  const activeLocations = form.watch("brand.activeLocations");
  const name = form.watch("name");
  const brandId = form.watch("brand.id");
  const type = form.watch("type");

  return (
    <div className="h-screen">
      <div className="absolute blur-sm pointer-events-none" />
      <div className="bg-white rounded-lg shadow-lg flex flex-col gap-4 p-4 pt-8 w-1/2 mx-auto mt-12">
        <img src={modaresaLogo} alt="Modaresa" className="w-48" />
        <h4 className="flex items-center gap-2 text-2xl font-bold">
          {t("CreateOrganizationPage.title")}
        </h4>
        <form
          onSubmit={form.handleSubmit(submit)}
          className="flex flex-col gap-2"
        >
          <div className="grid grid-cols-2 gap-2">
            <InputLabel htmlFor="type" error={errors.type?.message}>
              {t("CreateOrganizationPage.type-label")}
            </InputLabel>
            <InputLabel htmlFor="brand.id" error={errors.name?.message}>
              {t("CreateOrganizationPage.name-label")}
            </InputLabel>
            <select
              className={inputStyle({
                errored: !!errors.type,
              })}
              id="type"
              {...form.register("type")}
            >
              <option value="BRAND">Brand</option>
              <option value="AGENCY">Agency</option>
            </select>
            <CreatableSelect
              isDisabled={!canChangeBrand}
              options={brands.map((b) => ({
                label: b.name,
                value: b.id,
              }))}
              id="name"
              value={
                brandId
                  ? {
                      label: brands.find((b) => b.id === brandId)?.name ?? "",
                      value: brandId,
                    }
                  : {
                      label: name,
                      value: name,
                    }
              }
              onCreateOption={(inputValue) => {
                form.setValue("name", inputValue);
                form.setValue("brand.id", undefined);
              }}
              onChange={(v) => {
                if (v) {
                  form.setValue("name", v.label);
                  form.setValue("brand.id", v.value);
                }
              }}
            />
          </div>

          <InputLabel htmlFor="address" error={errors.address?.message}>
            {t("CreateOrganizationPage.address-label")}
          </InputLabel>
          <AddressAutocomplete
            id="address"
            defaultValue={address}
            onChange={(v) => {
              if (v) {
                form.setValue("address", v);
              }
            }}
          />

          <InputLabel htmlFor="timezone" error={errors.timezone?.message}>
            {t("CreateOrganizationPage.timezone-label")}
          </InputLabel>
          <TimezoneAutocomplete
            id="timezone"
            value={timezone}
            onChange={(v) => {
              if (v) {
                form.setValue("timezone", v);
              }
            }}
          />

          {type === "BRAND" && (
            <>
              {/** number of stockists */}
              <InputLabel
                htmlFor="brand.numberOfStockists"
                error={errors.brand?.numberOfStockists?.message}
              >
                {t("CreateOrganizationPage.number-of-stockists-label")}
              </InputLabel>
              <select
                id="brand.numberOfStockists"
                {...form.register("brand.numberOfStockists")}
                className={inputStyle({
                  errored: !!errors.brand?.numberOfStockists,
                })}
              >
                <option value="1-50">1-50</option>
                <option value="51-100">51-100</option>
                <option value="101-200">101-200</option>
                <option value="201-500">201-500</option>
                <option value="501-1000">501-1000</option>
                <option value="1001-2000">1001-2000</option>
                <option value="2001-5000">2001-5000</option>
                <option value="5001+">&gt; 5000</option>
              </select>

              {/** active seasons */}
              <InputLabel
                htmlFor="brand.activeSeasons"
                error={errors.brand?.activeSeasons?.message}
              >
                {t("CreateOrganizationPage.active-seasons-label")}
              </InputLabel>
              <MultiSelect
                options={seasonsOptions}
                hideSelectedOptions
                id="brand.activeSeasons"
                value={activeSeasons
                  .map((v) => seasonsOptions.find((o) => o.value === v))
                  .filter((v): v is SelectOptionBase => !!v)}
                onChange={(v) => {
                  if (v) {
                    form.setValue(
                      "brand.activeSeasons",
                      v.map((o) => o.value),
                    );
                  }
                }}
              />

              {/** nb exhibitions per year */}
              <InputLabel
                htmlFor="brand.numberOfYearlyCollections"
                error={errors.brand?.numberOfYearlyCollections?.message}
              >
                {t(
                  "CreateOrganizationPage.number-of-exhibitions-per-year-label",
                )}
              </InputLabel>
              <select
                id="brand.numberOfYearlyCollections"
                {...form.register("brand.numberOfYearlyCollections")}
                className={inputStyle({
                  errored: !!errors.brand?.numberOfYearlyCollections,
                })}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11+">&gt; 10</option>
              </select>

              {/** active markets & categories */}
              <InputLabel
                htmlFor="brand.activeMarkets"
                error={errors.brand?.activeMarkets?.message}
              >
                {t("CreateOrganizationPage.active-markets-label")}
              </InputLabel>
              <MultiSelect
                id="brand.activeMarkets"
                options={marketsCategoriesOptions}
                value={activeMarkets
                  .map((v) =>
                    marketsCategoriesOptionsFlat.find((o) => o.value === v),
                  )
                  .filter((v): v is SelectOptionBase => !!v)}
                onChange={(v) => {
                  if (v) {
                    form.setValue(
                      "brand.activeMarkets",
                      v.map((o) => o.value),
                    );
                  }
                }}
              />

              {/** active locations */}
              <InputLabel
                htmlFor="brand.activeLocations"
                error={errors.brand?.activeLocations?.message}
              >
                {t("CreateOrganizationPage.active-locations-label")}
              </InputLabel>
              <CreatableSelect<{ label: string; value: string }, true>
                id="brand.activeLocations"
                isMulti
                options={activeLocationsOptions}
                value={activeLocations}
                getNewOptionData={(v) => ({
                  label: v,
                  value: v.toUpperCase(),
                })}
                onChange={(v) => {
                  if (v) {
                    form.setValue(
                      "brand.activeLocations",
                      v as { label: string; value: string }[],
                    );
                  }
                }}
              />
            </>
          )}

          <Button theme="PRIMARY" type="submit" className="mt-4">
            {t("CreateOrganizationPage.submit")}
          </Button>
        </form>
      </div>
    </div>
  );
}
