import React from "react";

import { Navigate } from "react-router-dom";

import NotFound from "@pages/404";
import { isAdmin } from "@services/authentication/authentication.types";
import useAuthentication from "@services/authentication/useAuthentication";

interface AdminOnlyProps extends React.PropsWithChildren<{}> {}

export default function AdminOnly({ children }: AdminOnlyProps) {
  const { currentUser } = useAuthentication();
  if (!currentUser) {
    return <Navigate to="/login?context=brand-signin" />;
  }

  if (!isAdmin(currentUser)) {
    return <NotFound />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
