import { ContactFilters } from "@app/modules/crm/components/contacts/contacts-filter";
import { OrganizationAccount } from "@models/OrganizationAccount";
import { OrganizationContact } from "@models/OrganizationContact";
import { Portfolio } from "@models/Portfolio";
import { fullName } from "@shared/helpers/formatters";

type ContactToFilter = Pick<
  OrganizationContact,
  "firstName" | "lastName" | "email" | "position" | "markets"
> & {
  account: Pick<OrganizationAccount, "name" | "city" | "isKeyClient">;
  portfolios?: Pick<Portfolio, "id" | "name" | "collectionId">[];
};

function filterContacts<T extends ContactToFilter>(
  contacts: T[],
  filters: ContactFilters,
) {
  return contacts.filter((contact) => {
    const { account, email, position, portfolios, markets } = contact;

    if (filters.text && filters.text.length > 0) {
      const textLower = filters.text.toLowerCase();

      const hasFullName = fullName(contact).toLowerCase().includes(textLower);
      const hasAccount = account.name.toLowerCase().includes(textLower);
      const hasEmail = email.toLowerCase().includes(textLower);
      // position is supposed to be optional but has nulls instead.
      const hasPosition =
        position && position.toLowerCase().includes(textLower);
      // account.city isn't supposed to be optional
      const hasOrigin =
        account.city && account.city.toLowerCase().includes(textLower);

      if (
        !(hasFullName || hasAccount || hasEmail || hasPosition || hasOrigin)
      ) {
        return false;
      }
    }

    if (filters.markets && filters.markets.length > 0) {
      const hasMarket = filters.markets.some((m) => markets.includes(m));

      if (!hasMarket) {
        return false;
      }
    }

    if (filters.portfolios && filters.portfolios.length > 0) {
      const hasPortfolios = filters.portfolios.some((p) =>
        portfolios!.some((portfolio) => portfolio.id === p.id),
      );

      if (!hasPortfolios) {
        return false;
      }
    }

    if (filters.isKeyClient && !account.isKeyClient) {
      return false;
    }

    return true;
  });
}

export default filterContacts;
