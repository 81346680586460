import React from "react";

import { Navigate } from "react-router-dom";

import useApplicationContext from "@services/application/useApplicationContext";
import useAuthentication from "@services/authentication/useAuthentication";

export default function OnboardingNotDone({
  children,
}: Readonly<{
  children: React.JSX.Element;
}>) {
  const { currentUser } = useAuthentication();
  const { organization } = useApplicationContext();

  if (currentUser && currentUser.role === "MODARESA_ADMIN" && !organization) {
    return <Navigate to="/backoffice/list-organizations" replace />;
  }

  if (organization && organization.onboardingStatus === "DONE") {
    return <Navigate to="/dashboard" />;
  }

  return children;
}
