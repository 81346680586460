import React from "react";

import { useTranslation } from "react-i18next";

import Button from "@components/data-entry/Button";
import Modal, { useModal } from "@components/feedback/Modal";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import CollectionList, {
  useCollectionList,
} from "@shared/collections/CollectionList";
import NewBrandForAgencyForm from "@shared/collections/NewBrandForAgencyForm";

function OrganizationProfileCollections() {
  const { organization } = useOrganizationAppContext();
  const { t } = useTranslation();
  const modalState = useModal();

  const collectionListState = useCollectionList();

  // collection form
  return (
    <div className="p-4 grow space-y-4">
      <div className="flex gap-4 justify-between align-middle">
        {organization.type === "AGENCY" && (
          <>
            <Button theme="PRIMARY" onClick={() => modalState.open()}>
              {t("Shared.AgencyCollectionForm.add-brand")}
            </Button>
            <Modal
              title={t("Shared.AgencyCollectionForm.add-new-brand")}
              state={modalState}
              centeredTitle
              padding="p-9"
              aria-label={t("OrganizationProfile.collections.add-brand")}
            >
              <div className="px-20">
                <NewBrandForAgencyForm
                  organizationId={organization.id}
                  onSuccess={(brand) => {
                    modalState.close();
                    collectionListState.openModalWithBrand(brand.id);
                  }}
                />
              </div>
            </Modal>
          </>
        )}
      </div>
      {/* collection list  */}
      <CollectionList {...collectionListState} />
      <hr className="mb-10" />
    </div>
  );
}

export default OrganizationProfileCollections;
