import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { accountSchema } from "@models/Account";
import axiosInstance from "@services/api/config";

export namespace CreateBuyerAccount {
  export const inputSerializingSchema = accountSchema.pick({
    name: true,
    type: true,
    address: true,
    city: true,
    countryCode: true,
    continentCode: true,
  });
  export type Input = z.input<typeof inputSerializingSchema>;

  export const outputHydratingSchema = accountSchema.pick({
    id: true,
    name: true,
    type: true,
    address: true,
    city: true,
    countryCode: true,
    continentCode: true,
  });
  export type Output = z.output<typeof outputHydratingSchema>;

  interface MutationParams {
    data: Input;
  }

  type Params = MutationParams;

  export const path = () => `/buyer/account`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(), inputSerializingSchema.parse(params.data))
      .then((res) => outputHydratingSchema.parse(res.data));

  export function useHook() {
    return useMutation({
      mutationFn: (mutationParams: MutationParams) => call(mutationParams),
    });
  }
}
