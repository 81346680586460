import { generateMock } from "@anatine/zod-mock";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { eachDayOfInterval, endOfWeek, startOfWeek } from "date-fns";
import { z } from "zod";

import { ZonedDate, formatDateToISO } from "@helpers/Date";
import { appointmentHydratingSchema } from "@models/Appointment";
import { brandSchema } from "@models/Brand";
import { collectionSchema } from "@models/Collection";
import { meetingReportSchema } from "@models/MeetingReport";
import { organizationAccountSchema } from "@models/OrganizationAccount";
import { organizationContactSchema } from "@models/OrganizationContact";
import {
  organizationRepresentativeSchema,
  representativeSchema,
} from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import { showroomSchema } from "@models/Showroom";
import {
  AccountAppointmentTypeList,
  BusyAppointmentTypeList,
  VirtualMeetingAppsList,
} from "@models/types/enums";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace GetAppointmentsForInterval {
  const baseAppointment = appointmentHydratingSchema.pick({
    startTime: true,
    title: true,
    type: true,
    virtualMeetingApp: true,
    id: true,
    format: true,
    accountOtb: true,
    endTime: true,
    warnings: true,
    status: true,
  });
  export const accountAppointmentSchema = baseAppointment.extend({
    title: z.null(),
    format: appointmentHydratingSchema.shape.format.unwrap(),
    type: z.enum(AccountAppointmentTypeList),
    accountOtb: z.number().nullable(),
    collectionInterests: z
      .array(
        collectionSchema
          .pick({ id: true, name: true })
          .extend({ brand: brandSchema.pick({ id: true, name: true }) }),
      )
      .optional()
      .default([]),
    showroom: showroomSchema.pick({
      id: true,
      formattedAddress: true,
      timezone: true,
      city: true,
      countryCode: true,
    }),
    collection: collectionSchema
      .pick({ id: true, name: true })
      .extend({
        brand: brandSchema.pick({ id: true, name: true }),
      })
      .nullable(),
    account: organizationAccountSchema.pick({
      id: true,
      name: true,
      status: true,
      city: true,
      countryCode: true,
      isKeyClient: true,
    }),
    attendees: z.array(
      organizationContactSchema.pick({
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        markets: true,
        position: true,
        phoneNumber: true,
      }),
    ),
    seller: representativeSchema
      .pick({
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        languages: true,
      })
      .extend({
        virtualMeetingAppLinks: z
          .record(z.enum(VirtualMeetingAppsList), z.string().optional())
          .nullable(),
      }),
    portfolios: z.array(
      portfolioSchema
        .pick({ id: true, name: true, color: true, collectionId: true })
        .extend({
          manager: organizationRepresentativeSchema.pick({
            id: true,
            firstName: true,
            lastName: true,
            role: true,
          }),
          sellers: z.array(
            organizationRepresentativeSchema.pick({
              id: true,
              firstName: true,
              lastName: true,
              role: true,
              virtualMeetingApps: true,
            }),
          ),
        }),
    ),
    meetingReport: meetingReportSchema
      .pick({ otb: true, actualBudget: true, notes: true })
      .nullable(),
  });

  export const busyAppointmentSchema = accountAppointmentSchema.extend({
    type: z.enum(BusyAppointmentTypeList),
    account: z.null(),
    collection: z.null(),
    format: z.null(),
    portfolios: z.array(z.never()),
    attendees: z.array(z.never()),
    title: z.string(),
  });

  export const appointmentSchema = z.discriminatedUnion("type", [
    busyAppointmentSchema,
    accountAppointmentSchema,
  ]);
  export const outputSchema = z.array(appointmentSchema);
  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    organizationId: string;
    dayAsString: string;
  }

  export function path({ organizationId, dayAsString }: HookParams) {
    return `/organizations/${organizationId}/appointments/day/${dayAsString}`;
  }

  export const getQueryKeys = (p: HookParams) => getAPIQueryKey(path(p));

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data));
  }

  export function useHook(params: HookParams) {
    const askedDay = new Date(params.dayAsString);
    const interval = {
      start: startOfWeek(askedDay),
      end: endOfWeek(askedDay),
    };
    const everyday = eachDayOfInterval(interval);
    const queries = everyday.map((day) => {
      const dayParams: HookParams = {
        organizationId: params.organizationId,
        dayAsString: formatDateToISO(day),
      };
      return {
        queryFn: () => call(dayParams),
        queryKey: getQueryKeys(dayParams),
      };
    });

    const everyDayQueries = useQueries({
      queries,
      combine: (results) => {
        // data combination
        const data = results.flatMap((r) => r.data || []);

        // status combination
        const hasPending = results.map((r) => r.status).includes("pending");
        const hasError = results.map((r) => r.status).includes("error");
        let status = "success";
        if (hasError) status = "error";
        if (hasPending) status = "pending";

        return {
          data,
          status,
        };
      },
    });

    return everyDayQueries;
  }

  export function useInvalidate() {
    const queryClient = useQueryClient();
    return (params: HookParams) =>
      queryClient.invalidateQueries({ queryKey: getQueryKeys(params) });
  }

  /**
   * OPTIMISTIC UPDATE
   * used for drag&drop reponsivity
   * drag&drop only allows modification of seller and times, so we only optimistically update those fields
   */
  export function useOptimisticUpdate() {
    const queryClient = useQueryClient();

    return {
      revert: async (params: HookParams, data: Output) => {
        queryClient.setQueryData(getQueryKeys(params), data);
      },
      update: async (
        params: HookParams,
        submittedAppointment: {
          id: string | null;
          startTime: ZonedDate;
          showroom: {
            timezone: string;
          };
          endTime: ZonedDate;
          seller: { id: string };
        },
      ) => {
        const queryKeys = getQueryKeys(params);
        const dataBefore = queryClient.getQueryData<Output>(queryKeys) || [];
        const dataAfter = [...dataBefore];
        const appointmentIndex = dataBefore.findIndex(
          (appt) => appt.id === submittedAppointment.id,
        );
        if (appointmentIndex !== -1) {
          dataAfter[appointmentIndex] = {
            ...dataBefore[appointmentIndex],
            startTime: submittedAppointment.startTime,
            endTime: submittedAppointment.endTime,
            seller: {
              id: submittedAppointment.seller.id,
              email: "...",
              firstName: "...",
              languages: [],
              lastName: "...",
              virtualMeetingAppLinks: {},
            },
          };
        } else {
          dataAfter.push({
            ...generateMock(outputSchema.element),
            startTime: submittedAppointment.startTime,
            endTime: submittedAppointment.endTime,
            seller: {
              id: submittedAppointment.seller.id,
              email: "...",
              firstName: "...",
              languages: [],
              lastName: "...",
              virtualMeetingAppLinks: {},
            },
          });
        }

        queryClient.setQueryData(queryKeys, dataAfter);
      },
    };
  }
}
