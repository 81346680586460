import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import CalloutBox from "@components/feedback/CalloutBox";
import Loading from "@components/feedback/Loading";
import useAuthentication from "@services/authentication/useAuthentication";

export default function ResetPasswordForm() {
  const { resetPassword } = useAuthentication();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const submit = (data: { email: string }) => {
    setLoading(true);
    setError("");
    resetPassword(data.email)
      .then(() => {
        setLoading(false);
        navigate("/login");
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  if (loading) {
    return <Loading type="screen" />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        {error && <CalloutBox type="ERROR">{error}</CalloutBox>}
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="email"
        >
          Email
        </label>
        <input
          id="email"
          {...register("email", {
            required: "required",
          })}
          type="email"
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Email"
        />
        <button
          className="bg-blueGray-800 active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
          type="submit"
          title="password-lost"
        >
          Reset password
        </button>
      </form>
      <button
        className="bg-blueGray-800 active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
        type="button"
        title="password-lost"
        onClick={() => navigate("/login")}
      >
        Return to sign in
      </button>
    </>
  );
}
