import {
  MarketCategoryEnum,
  MarketCategoryGroupEnum,
} from "@models/types/enums";

export const marketCategoriesGrouped = [
  {
    label: MarketCategoryGroupEnum.WOMEN,
    options: [
      {
        label: MarketCategoryGroupEnum.READY_TO_WEAR,
        value: MarketCategoryEnum.WOMEN_READY_TO_WEAR,
      },
      {
        label: MarketCategoryGroupEnum.BAGS,
        value: MarketCategoryEnum.WOMEN_BAGS,
      },
      {
        label: MarketCategoryGroupEnum.SHOES,
        value: MarketCategoryEnum.WOMEN_SHOES,
      },
      {
        label: MarketCategoryGroupEnum.ACCESSORIES,
        value: MarketCategoryEnum.WOMEN_ACCESSORIES,
      },
      {
        label: MarketCategoryGroupEnum.JEWELLERY,
        value: MarketCategoryEnum.WOMEN_JEWELLERY,
      },
    ],
  },
  {
    label: MarketCategoryGroupEnum.MEN,
    options: [
      {
        label: MarketCategoryGroupEnum.READY_TO_WEAR,
        value: MarketCategoryEnum.MEN_READY_TO_WEAR,
      },
      {
        label: MarketCategoryGroupEnum.BAGS,
        value: MarketCategoryEnum.MEN_BAGS,
      },
      {
        label: MarketCategoryGroupEnum.SHOES,
        value: MarketCategoryEnum.MEN_SHOES,
      },
      {
        label: MarketCategoryGroupEnum.ACCESSORIES,
        value: MarketCategoryEnum.MEN_ACCESSORIES,
      },
      {
        label: MarketCategoryGroupEnum.JEWELLERY,
        value: MarketCategoryEnum.MEN_JEWELLERY,
      },
    ],
  },
  {
    label: MarketCategoryGroupEnum.CHILDREN,
    options: [
      {
        label: MarketCategoryGroupEnum.READY_TO_WEAR,
        value: MarketCategoryEnum.CHILDREN_READY_TO_WEAR,
      },
      {
        label: MarketCategoryGroupEnum.BAGS,
        value: MarketCategoryEnum.CHILDREN_BAGS,
      },
      {
        label: MarketCategoryGroupEnum.SHOES,
        value: MarketCategoryEnum.CHILDREN_SHOES,
      },
      {
        label: MarketCategoryGroupEnum.ACCESSORIES,
        value: MarketCategoryEnum.CHILDREN_ACCESSORIES,
      },
      {
        label: MarketCategoryGroupEnum.JEWELLERY,
        value: MarketCategoryEnum.CHILDREN_JEWELLERY,
      },
    ],
  },
  {
    label: MarketCategoryGroupEnum.HOME,
    options: [
      {
        label: MarketCategoryGroupEnum.HOME,
        value: MarketCategoryEnum.HOME,
      },
    ],
  },
];

// function to generate the collection name - a combination of all the selected market categories
export const createCollectionTitle = (
  selectedCategories: string[],
  t: (key: string) => string,
) => {
  // create a map to store categories grouped by gender
  const genderCategoryMap: { [gender: string]: Set<string> } = {};

  // populate the map with categories grouped by gender
  selectedCategories.forEach((category) => {
    const fullLabel = t(`Common.market-category.${category}`);
    const [gender, ...rest] = fullLabel.split(" ");

    // extract the category (e.g., "Ready-to-wear", "Bags")
    const categoryLabel = rest.join(" ");

    // initialize the set for each gender if it doesn't exist
    if (!genderCategoryMap[gender]) {
      genderCategoryMap[gender] = new Set();
    }

    // add the category to the gender's set (e.g., add "Shoes" to "Women")
    genderCategoryMap[gender].add(categoryLabel);
  });

  // create the title array by mapping through the genderCategoryMap object
  const titleArray = Object.entries(genderCategoryMap).map(
    ([gender, categories]) => {
      // join the categories for the current gender (e.g., "Bags/Accessories/Shoes")
      const categoryList = Array.from(categories).join("/");
      return `${gender} ${categoryList}`;
    },
  );

  // join all titles with ", " and handle the 'and' for the last item if needed
  const lastTitlePart = titleArray.pop();
  const title = titleArray.length
    ? `${titleArray.join(", ")} and ${lastTitlePart}`
    : lastTitlePart;

  return title;
};
