import { useMutation, useQueryClient } from "@tanstack/react-query";

import { formatDateToISO } from "@helpers/Date";
import { appointmentHydratingSchema } from "@models/Appointment";
import { showroomSchema } from "@models/Showroom";

import axiosInstance from "../config";
import { GetAppointmentsForInterval } from "./GetAppointmentsForInterval";
import { GetAppointment } from "./get-appointment";

interface HookParams {
  organizationId: string;
}

interface MutationParams {
  appointmentId: string;
  method: "soft-check-in" | "check-in" | "check-out" | "cancel";
}

const outputSchema = appointmentHydratingSchema
  .pick({
    id: true,
    accountOtb: true,
    endTime: true,
    startTime: true,
    format: true,
  })
  .extend({
    showroom: showroomSchema.pick({
      id: true,
      timezone: true,
    }),
  });

export default function useAppointmentCheckin({ organizationId }: HookParams) {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: ({ appointmentId, method }: MutationParams) =>
      axiosInstance
        .patch(
          `/organizations/${organizationId}/appointments/${appointmentId}/${method}`,
        )
        .then((res) => outputSchema.parse(res.data)),
    onSuccess: (result, { appointmentId }) => {
      // reset appointment query
      qc.invalidateQueries({
        queryKey: GetAppointment.queryKeys({ organizationId, appointmentId }),
      });
      // reset the daily calendar query for today
      qc.invalidateQueries({
        queryKey: GetAppointmentsForInterval.getQueryKeys({
          organizationId,
          dayAsString: formatDateToISO(
            result.startTime.toLocalDate(result.showroom.timezone),
          ),
        }),
      });
    },
  });
}
