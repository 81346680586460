import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { toast } from "react-toastify";

import MyProfileHeader, {
  MyProfileTab,
} from "@app/modules/my-profile/components/header";
import Login from "@app/modules/my-profile/components/login";
import PersonalInfo from "@app/modules/my-profile/components/personal-info";
import VirtualTools from "@app/modules/my-profile/components/virtual-tools";
import queryClient from "@app/queryClient";
import Loading from "@components/feedback/Loading";
import { Representative } from "@models/old/Representative";
import {
  queryKeysFetchRepresentative,
  useFetchRepresentative,
} from "@services/api/old/representatives/fetch-representative";
import { usePutAcceptInvitationRepresentative } from "@services/api/old/representatives/put-accept-invitation-representative";
import { useUpsertRepresentative } from "@services/api/old/representatives/upsert-representative";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import useAuthentication, {
  useAuthenticatedRepresentative,
} from "@services/authentication/useAuthentication";
import LogService from "@services/log/service";
import { fullName } from "@shared/helpers/formatters";

function MyProfile() {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();
  const loggedUser = useAuthenticatedRepresentative();
  const { associatedOrganizations } = loggedUser;

  const [selectedTab, setSelectedTab] = useState<MyProfileTab | undefined>();

  const { data: representative, isLoading } = useFetchRepresentative(
    organizationId,
    associatedOrganizations[0].representativeId,
  );

  const { mutateAsync: acceptInvitation } =
    usePutAcceptInvitationRepresentative(organizationId);

  useEffect(() => {
    if (representative?.invitationStatus === "INVITED") {
      acceptInvitation(representative.id).catch((err) => {
        LogService.error(err);
      });
    }
  }, [acceptInvitation, representative?.id, representative?.invitationStatus]);

  const { mutateAsync: upsertRepresentative } = useUpsertRepresentative(
    organizationId,
    representative?.id,
  );

  const { resetPassword } = useAuthentication();

  const onChangeTab = (tab: MyProfileTab) => {
    setSelectedTab(tab);
  };

  if (isLoading || !representative) {
    return (
      <div className="flex items-end justify-center">
        <Loading type="screen" />
      </div>
    );
  }

  const handleOnSubmit = (user: Representative) => {
    upsertRepresentative(user)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchRepresentative(
            organizationId,
            representative.id,
          ),
        });
        toast.success(t("MyProfile.profile-updated"));
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleOnChangePassword = async () => {
    if (!representative?.email) {
      toast.error(t("MyProfile.representative-has-no-email"));
    } else {
      await resetPassword(representative.email);
      toast.success(t("MyProfile.password-reset"));
    }
  };

  const renderTab = () => {
    switch (selectedTab) {
      case "virtual-tools":
        return <VirtualTools user={representative} onSubmit={handleOnSubmit} />;
      case "login":
        return (
          <Login
            user={representative}
            onChangePassword={handleOnChangePassword}
          />
        );
      case "personal-info":
      default:
        return <PersonalInfo user={representative} onSubmit={handleOnSubmit} />;
    }
  };

  return (
    <div className="h-full flex flex-col">
      <MyProfileHeader
        name={fullName(representative)}
        onChangeTab={onChangeTab}
      />
      <div className="flex flex-col grow">{renderTab()}</div>
    </div>
  );
}

export default MyProfile;
