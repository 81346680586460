import React from "react";

import { SubmitHandler, useForm } from "react-hook-form";

import buyerLoginSplashscreen from "@app/assets/img/buyer-login-splashscreen.png";
import modaCruisingLogo from "@app/assets/logos/moda-cruising.svg";
import Button from "@components/data-entry/Button";
import CalloutBox from "@components/feedback/CalloutBox";
import Modal, { useModal } from "@components/feedback/Modal";
import useAuthentication from "@services/authentication/useAuthentication";
import { GuestOnly } from "@shared/components/guards/authentication";

type CredentialsInput = {
  email: string;
  password: string;
};

type SignUpInput = {
  email: string;
  password: string;
  passwordConfirmation: string;
};

function SignUp({
  onSignUp,
}: {
  onSignUp: (email: string, password: string) => Promise<void>;
}) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignUpInput>();

  const signUpSubmit: SubmitHandler<SignUpInput> = (data) => {
    if (data.password !== data.passwordConfirmation) {
      setError(
        "passwordConfirmation",
        { type: "custom", message: "passwords do not match" },
        { shouldFocus: true },
      );
    } else {
      onSignUp(data.email, data.password).catch(() =>
        setError(
          "email",
          { type: "custom", message: "this email and password are invalid" },
          { shouldFocus: true },
        ),
      );
    }
  };

  return (
    <form
      className="flex flex-col gap-4 w-96"
      onSubmit={handleSubmit(signUpSubmit)}
    >
      <div>
        <label className="font-medium2" htmlFor="email">
          Email
        </label>
        <input
          id="email"
          {...register("email", {
            required: "email required",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format",
            },
          })}
          type="email"
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Email"
        />
        {errors.email && (
          <span role="alert" title="email-alert" className="text-primaryRed">
            {errors.email.message}
          </span>
        )}
      </div>
      <div>
        <label className="font-medium2" htmlFor="password">
          Password
        </label>
        <input
          id="password"
          {...register("password", {
            required: true,
            minLength: {
              value: 8,
              message: "password must be at least 8 characters",
            },
          })}
          type="password"
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Password"
        />
        {errors.password && (
          <span role="alert" className="text-primaryRed">
            {errors.password.message}
          </span>
        )}
      </div>
      <div>
        <label className="font-medium2" htmlFor="passwordConfirmation">
          Confirmation password
        </label>
        <input
          id="passwordConfirmation"
          {...register("passwordConfirmation")}
          type="password"
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          placeholder="Password"
        />
        {errors.passwordConfirmation && (
          <span role="alert" className="text-primaryRed">
            {errors.passwordConfirmation.message}
          </span>
        )}
      </div>
      <div />
      <div className="text-center mt-6">
        <Button
          theme="PRIMARY"
          title="credentials-signin"
          className="w-full"
          type="submit"
        >
          Let&#8217;s Moda:Cruise!
        </Button>
      </div>
    </form>
  );
}

export default function BuyerLoginPage() {
  const {
    googleSignIn,
    microsoftSignIn,
    credentialsSignIn,
    signUpWithEmailAndPassword,
  } = useAuthentication();

  const signUpModal = useModal();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<CredentialsInput>();

  const signInSubmit: SubmitHandler<CredentialsInput> = (data) =>
    credentialsSignIn("retailer-signin", data.email, data.password).catch(() =>
      setError(
        "email",
        { type: "custom", message: "this email and password are invalid" },
        { shouldFocus: true },
      ),
    );

  const signUp = (email: string, password: string) =>
    signUpWithEmailAndPassword("retailer-signup", email, password).then(() => {
      setValue("email", email);
      setValue("password", password);
      signUpModal.close();
    });

  return (
    <GuestOnly>
      <div className="min-h-screen flex items-center justify-center gap-8">
        <div className="max-w-md w-full space-y-4">
          <div className="flex flex-col items-center p-4 space-y-4">
            <img alt="booked" className="h-6" src={modaCruisingLogo} />
            <span className="text-primaryElectricBlue">
              Cruise your way through fashion weeks.
            </span>
          </div>
          <div>
            <h2 className="mt-6 text-3xl font-bold text-gray-900">
              Scheduling your buying itinerary has never been easier!
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Explore the ideal itinerary on Moda:Cruising to connect with your
              preferred organizations at the perfect time and place.
            </p>
          </div>
          <div className="space-y-2">
            <CalloutBox type="INFORMATIVE">
              Sign in with the same email you receive invitations on so that we
              can automatically gather all your invitations on your account for
              you.
            </CalloutBox>
            <div>
              <Button
                type="button"
                onClick={() => googleSignIn("retailer-signin")}
                className="w-full text-center border-b rounded-md border-primaryElectricBlue text-primaryElectricBlue hover:bg-primaryElectricBlue hover:text-white"
              >
                Continue with Google
              </Button>
            </div>
            <div>
              <Button
                type="button"
                onClick={() => microsoftSignIn("retailer-signin")}
                className="w-full text-center border-b rounded-md border-primaryElectricBlue text-primaryElectricBlue hover:bg-primaryElectricBlue hover:text-white"
              >
                Continue with Microsoft
              </Button>
            </div>
          </div>

          <form onSubmit={handleSubmit(signInSubmit)}>
            <div>
              <label className="font-medium2" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                {...register("email", {
                  required: "email required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                })}
                type="email"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Email"
              />
              {errors.email && (
                <span
                  role="alert"
                  title="email-alert"
                  className="text-primaryRed"
                >
                  {errors.email.message}
                </span>
              )}
            </div>
            <div>
              <label className="font-medium2" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                {...register("password", { required: "password required" })}
                type="password"
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Password"
              />
              {errors.password && (
                <span role="alert" className="text-primaryRed">
                  {errors.password.message}
                </span>
              )}
            </div>
            <div className="text-center mt-6">
              <Button
                theme="PRIMARY"
                title="credentials-signin"
                className="w-full"
                type="submit"
              >
                Sign In
              </Button>
            </div>
          </form>

          <div className="border-b bg-primaryLightestGrey flex gap-4 items-center justify-center h-16">
            <span>Don&#8217;t have an account? </span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Button
              theme="LINK"
              onClick={signUpModal.open}
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Create a free account
            </Button>

            <Modal title="Sign Up for free with your email" state={signUpModal}>
              <SignUp onSignUp={signUp} />
            </Modal>
          </div>
        </div>

        <div className="max-w-lg w-full space-y-8">
          <img src={buyerLoginSplashscreen} alt="modacruising splashscreen" />
        </div>
      </div>
    </GuestOnly>
  );
}
