import React, { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { z } from "zod";

import InputLabel from "@components/data-display/InputLabel";
import Button from "@components/data-entry/Button";
import { Brand } from "@models/Brand";
import { GetAllBrandsEndpoint } from "@services/api/brands/get-all-brands";
import { GetOrganizationCollectionsWithCapabilitiesEndpoint } from "@services/api/organization/get-collections-with-capabilities";
import { OrganizationPartnersWithOnboardedBrandEndpoint } from "@services/api/organization/post-partner-with-existing-brand";
import { OrganizationPartnersWithNonOnboardedBrandEndpoint } from "@services/api/organization/post-partner-with-new-brand";

const formValuesSchema = z.object({
  brandId: z.string().uuid().optional(),
  brandName: z.string().optional(),
});

type FormValues = z.infer<typeof formValuesSchema>;

const validNewBrandFormValuesSchema = z.object({
  brandName: z.string(),
});

const validExistingBrandFormValuesSchema = z.object({
  brandId: z.string().uuid(),
});

interface NewBrandForAgencyFormProps {
  organizationId: string;
  onSuccess?: (brand: Pick<Brand, "id" | "name">) => void;
}

function NewBrandForAgencyForm({
  organizationId,
  onSuccess,
}: NewBrandForAgencyFormProps) {
  const { t } = useTranslation();

  const { data: brands } = GetAllBrandsEndpoint.useHook();
  const { data: collections = [] } =
    GetOrganizationCollectionsWithCapabilitiesEndpoint.useHook({
      organizationId,
    });

  const [isNewBrand, setIsNewBrand] = useState(false);

  // to make sure that the dropdown shows only brands that aren't already displayed in the Collections page
  const uniqueBrandIds = Array.from(
    new Set(
      collections.map((collection) => collection?.brand?.id).filter(Boolean),
    ),
  );

  // remote actions
  const { mutateAsync: partnerWithOnboardedBrand } =
    OrganizationPartnersWithOnboardedBrandEndpoint.useHook({ organizationId });
  const { mutateAsync: partnerWithNonOnboardedBrand } =
    OrganizationPartnersWithNonOnboardedBrandEndpoint.useHook({
      organizationId,
    });

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors: formErrors },
  } = useForm<FormValues>({
    defaultValues: {
      brandId: "",
      brandName: "",
    },
    resolver: zodResolver(
      isNewBrand
        ? validNewBrandFormValuesSchema
        : validExistingBrandFormValuesSchema,
    ),
  });

  const onSubmitForm = async (data: FormValues) => {
    const parseNewBrandValues = validNewBrandFormValuesSchema.safeParse(data);
    const parseExistingBrandValues =
      validExistingBrandFormValuesSchema.safeParse(data);

    if (parseNewBrandValues.success) {
      const brand = await partnerWithNonOnboardedBrand({
        data: {
          name: parseNewBrandValues?.data?.brandName ?? "",
        },
      });
      onSuccess?.(brand);
    } else if (parseExistingBrandValues.success) {
      const brand = await partnerWithOnboardedBrand({
        data: {
          brandId: parseExistingBrandValues?.data?.brandId ?? "",
          collections: [],
        },
      });
      onSuccess?.(brand);
    }
  };

  const values = watch();
  const renderBrandNameField = () => (
    <div className="mt-5 relative">
      <InputLabel error={formErrors.brandId?.message}>
        {t("Shared.AgencyCollectionForm.brand-label")}
      </InputLabel>
      <div>
        <Controller
          name="brandId"
          control={control}
          render={({ field: { onChange } }) => (
            <CreatableSelect
              isClearable
              name="brandId"
              className="mt-1"
              options={brands
                ?.filter((brand) => !uniqueBrandIds.includes(brand.id))
                .map((brand) => ({
                  label: brand.name,
                  value: brand.id,
                }))}
              placeholder={t("Shared.AgencyCollectionForm.brand-placeholder")}
              value={
                values.brandName
                  ? { label: values.brandName, value: values.brandId }
                  : null
              }
              onCreateOption={(brandName) => {
                onChange("");
                setValue("brandName", brandName);
                setIsNewBrand(true);
              }}
              onChange={(o) => {
                onChange(o?.value || "");
                setValue("brandName", o ? o.label : "");
                setIsNewBrand(false);
              }}
              aria-label={t("Shared.AgencyCollectionForm.add-brand")}
            />
          )}
        />
      </div>
    </div>
  );

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div>
        <div>
          <p>{t("Shared.AgencyCollectionForm.choose-brand")}</p>
        </div>
        <div>{renderBrandNameField()}</div>
      </div>
      <Button
        className="whitespace-nowrap text-primaryElectricBlue hover:underline mt-4 flex w-full"
        theme="SECONDARY"
        justify="center"
        type="submit"
      >
        {t("Shared.AgencyCollectionForm.next")}
      </Button>
    </form>
  );
}
export default NewBrandForAgencyForm;
