import React from "react";

import { Navigate, Outlet, useNavigation } from "react-router-dom";

import Loading from "@components/feedback/Loading";
import { SidebarProvider, SidebarTriggerMobile } from "@components/ui/sidebar";
import useApplicationContext from "@services/application/useApplicationContext";
import useAuthentication from "@services/authentication/useAuthentication";
import ErrorBoundary from "@services/error/error-boundary";
import FrontofficeSideBar from "@shared/components/frontoffice/FrontOfficeSidebar";
import { AuthOnly } from "@shared/components/guards/authentication";
import OnboardingDone from "@shared/components/guards/onboarding-done";

function LayoutFrontOffice() {
  const navigation = useNavigation();
  const { currentUser } = useAuthentication();
  const { organization } = useApplicationContext();

  if (currentUser && currentUser.role === "MODARESA_ADMIN" && !organization) {
    return <Navigate to="/backoffice/list-organizations" replace />;
  }

  return (
    <AuthOnly>
      <OnboardingDone>
        <SidebarProvider>
          <FrontofficeSideBar />
          <main className="max-md:w-full w-content h-screen no-scrollbar flex flex-col">
            <SidebarTriggerMobile />
            {navigation.state !== "idle" ? (
              <Loading type="screen" />
            ) : (
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            )}
          </main>
        </SidebarProvider>
      </OnboardingDone>
    </AuthOnly>
  );
}

export default LayoutFrontOffice;
