import { cva } from "class-variance-authority";

export const accessTypeButtonStyle = cva(
  "text-center p-4 rounded border border-gray-400",
  {
    variants: {
      active: {
        true: "bg-primaryElectricBlue text-white shadow-lg",
        false:
          "bg-primaryLightElectricBlue text-gray-400 hover:border-primaryElectricBlue hover:text-primaryElectricBlue",
      },
    },
  },
);
