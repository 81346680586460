import React from "react";

import { cva } from "class-variance-authority";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

import Button from "@components/data-entry/Button";
import TextAreaField from "@components/data-entry/TextAreaField";
import TextField from "@components/data-entry/TextField";
import BottomBar from "@components/layout/BottomBar";
import { Appointment } from "@models/Appointment";
import { OrganizationAccount } from "@models/OrganizationAccount";
import { Showroom } from "@models/Showroom";
import { GetMeetingReport } from "@services/api/appointments/get-meeting-report";
import { PostMeetingReport } from "@services/api/appointments/post-meeting-report";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

interface FormValues {
  actualBudget: string | null;
  otb: string | null;
  notes: string | null;
}
interface MeetingReportFormProps {
  appointmentId: Appointment["id"];
  accountId: OrganizationAccount["id"];
  showroomId: Showroom["id"];
  meetingReport: Pick<
    GetMeetingReport.Output,
    "actualBudget" | "notes" | "otb"
  >;
  accountOtb: number | null;
  contactBooking: string;
  contentStyle?: "fullPage" | "drawer" | null;
  textarea?: boolean;
  closeDrawer?: () => void;
}
// Utilized cvs to enable different styling for all components incorporating MeetingReportForm
const otbAndBudgetFlexStyle = cva("flex gap-6", {
  variants: {
    otbAndBudgetFlex: {
      fullPage: "flex-wrap",
      drawer: "flex-nowrap justify-between",
    },
  },
});

const otbAndBudgetSpacingStyle = cva("flex flex-col gap-6 grow", {
  variants: {
    otbAndBudgetSpacing: {
      fullPage: "px-6",
      drawer: "mx-10",
    },
  },
});

const otbStyle = cva("flex flex-col gap-2", {
  variants: {
    otbInputWidth: {
      fullPage: "w-full max-w-sm",
      drawer: "w-full",
    },
  },
});

const totalBudgetStyle = cva("flex flex-col gap-2", {
  variants: {
    totalBudgetInputWidth: {
      fullPage: "w-full max-w-sm",
      drawer: "w-full",
    },
  },
});

const notesStyle = cva("", {
  variants: {
    notesInputWidth: {
      fullPage: "w-[49.5rem]",
      drawer: "",
    },
  },
});

function MeetingReportForm(props: MeetingReportFormProps) {
  const {
    appointmentId,
    meetingReport,
    accountOtb,
    contactBooking,
    contentStyle = "fullPage",
    textarea = false,
    closeDrawer,
    accountId,
    showroomId,
  } = props;
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    defaultValues: {
      actualBudget: meetingReport?.actualBudget?.toString() ?? null,
      otb: meetingReport?.otb?.toString() ?? null,
      notes: meetingReport?.notes ?? null,
    },
  });

  const updateMeetingReport = PostMeetingReport.useHook({
    organizationId,
    appointmentId,
    accountId,
    showroomId,
  });

  const onSubmitForm = (data: FormValues) => {
    updateMeetingReport
      .mutateAsync({
        otb: data.otb ? parseInt(data.otb, 10) : null,
        actualBudget: data.actualBudget
          ? parseInt(data.actualBudget, 10)
          : null,
        notes: data.notes,
      })
      .then(() => {
        toast.success(t("Booking.contact.edit.updated"));
        if (closeDrawer) {
          closeDrawer();
        }
      });
  };

  return (
    <form
      className="flex flex-col gap-4 grow"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <div
        className={twMerge(
          otbAndBudgetSpacingStyle({
            otbAndBudgetSpacing: contentStyle || "fullPage",
          }),
        )}
      >
        <div
          className={twMerge(
            otbAndBudgetFlexStyle({
              otbAndBudgetFlex: contentStyle || "fullPage",
            }),
          )}
        >
          <div
            className={twMerge(
              otbStyle({
                otbInputWidth: contentStyle || "fullPage",
              }),
            )}
          >
            <label className="font-medium" htmlFor="firstName">
              OTB (€):
            </label>
            <Controller
              name="otb"
              control={control}
              render={({
                fieldState: { error },
                field: { onChange, value },
              }) => (
                <TextField
                  id="otb"
                  placeholder="ex: 150 000"
                  value={value?.toString() ?? ""}
                  onChange={onChange}
                  hasError={!!error}
                  type="number"
                />
              )}
            />
            {/* Add condition `accountOtb !== 0` because if the OTB value is explicitly set to 0, "0" would be displayed below the otb input element */}
            {accountOtb !== 0 && accountOtb && (
              <p className="bg-secondaryCalendulaGold rounded-lg px-2 py-1 w-fit">
                {contactBooking} indicates: {accountOtb}
              </p>
            )}
          </div>
          <div
            className={twMerge(
              totalBudgetStyle({
                totalBudgetInputWidth: contentStyle || "fullPage",
              }),
            )}
          >
            <label className="font-medium" htmlFor="firstName">
              Target Budget (€):
            </label>
            <Controller
              name="actualBudget"
              control={control}
              render={({
                fieldState: { error },
                field: { onChange, value },
              }) => (
                <TextField
                  id="actualBudget"
                  placeholder="ex: 150 000"
                  value={value?.toString() ?? ""}
                  onChange={onChange}
                  hasError={!!error}
                  type="number"
                />
              )}
            />
          </div>
        </div>
        {/* added flex flex-col gap-2 because the spacing between label and the div below wasn't the same as for otb and total budget */}
        <div className="flex flex-col gap-2">
          <label className="font-medium" htmlFor="firstName">
            Appointment notes:
          </label>
          <div
            className={twMerge(
              notesStyle({ notesInputWidth: contentStyle || "fullPage" }),
            )}
          >
            <Controller
              name="notes"
              control={control}
              render={({
                fieldState: { error },
                field: { onChange, value },
              }) =>
                textarea ? (
                  <TextAreaField
                    id="notes"
                    placeholder="Write a note about the appointment..."
                    value={value ?? ""}
                    onChange={onChange}
                    hasError={!!error}
                    rows={7}
                  />
                ) : (
                  <TextField
                    id="notes"
                    placeholder="Write a note about the appointment..."
                    value={value ?? ""}
                    onChange={onChange}
                    hasError={!!error}
                  />
                )
              }
            />
            {errors.notes && errors.notes.type === "required" && (
              <p className="text-xs italic text-primaryRed">
                should not be seen
              </p>
            )}
          </div>
        </div>
      </div>
      <BottomBar>
        <Button theme="PRIMARY">{t("Components.buttons.save-changes")}</Button>
      </BottomBar>
    </form>
  );
}

export default MeetingReportForm;
