export const continentCodes = [
  "EU",
  "AS",
  "NA",
  "AF",
  "AN",
  "SA",
  "OC",
] as const;
export type ContinentCode = (typeof continentCodes)[number];

export const continents: Record<
  ContinentCode,
  { name: string; countries: string[] }
> = {
  EU: {
    name: "Europe",
    countries: [
      "AD",
      "AL",
      "AT",
      "BA",
      "BE",
      "BG",
      "BY",
      "CH",
      "CZ",
      "DE",
      "DK",
      "EE",
      "ES",
      "FI",
      "FO",
      "FR",
      "GB",
      "GG",
      "GI",
      "GR",
      "HR",
      "HU",
      "IE",
      "IM",
      "IS",
      "IT",
      "JE",
      "LI",
      "LT",
      "LU",
      "LV",
      "MC",
      "MD",
      "ME",
      "MK",
      "MT",
      "NL",
      "NO",
      "PL",
      "PT",
      "RO",
      "RS",
      "RU",
      "SE",
      "SI",
      "SJ",
      "SK",
      "SM",
      "UA",
    ],
  },
  AS: {
    name: "Asia",
    countries: [
      "AE",
      "AF",
      "AM",
      "AZ",
      "BD",
      "BH",
      "BN",
      "BT",
      "CC",
      "CN",
      "CX",
      "CY",
      "GE",
      "HK",
      "ID",
      "IL",
      "IN",
      "IO",
      "IQ",
      "IR",
      "JO",
      "JP",
      "KG",
      "KH",
      "KP",
      "KR",
      "KW",
      "KZ",
      "LA",
      "LB",
      "LK",
      "MM",
      "MN",
      "MO",
      "MV",
      "MY",
      "NP",
      "OM",
      "PH",
      "PK",
      "PS",
      "QA",
      "SA",
      "SG",
      "SY",
      "TH",
      "TJ",
      "TM",
      "TR",
      "TW",
      "UZ",
      "VN",
      "YE",
    ],
  },
  NA: {
    name: "North America",
    countries: [
      "AG",
      "AI",
      "AN",
      "AW",
      "BB",
      "BM",
      "BS",
      "BZ",
      "CA",
      "CR",
      "CU",
      "DM",
      "DO",
      "GD",
      "GL",
      "GP",
      "GT",
      "HN",
      "HT",
      "JM",
      "KN",
      "KY",
      "LC",
      "MQ",
      "MS",
      "MX",
      "NI",
      "PA",
      "PM",
      "PR",
      "SV",
      "TC",
      "TT",
      "US",
      "VC",
      "VG",
      "VI",
    ],
  },
  AF: {
    name: "Africa",
    countries: [
      "AO",
      "BF",
      "BI",
      "BJ",
      "BW",
      "CD",
      "CF",
      "CG",
      "CI",
      "CM",
      "CV",
      "DJ",
      "DZ",
      "EG",
      "EH",
      "ER",
      "ET",
      "GA",
      "GH",
      "GM",
      "GN",
      "GQ",
      "GW",
      "KE",
      "KM",
      "LR",
      "LS",
      "LY",
      "MA",
      "MG",
      "ML",
      "MR",
      "MU",
      "MW",
      "MZ",
      "NA",
      "NE",
      "NG",
      "RE",
      "RW",
      "SC",
      "SD",
      "SH",
      "SL",
      "SN",
      "SO",
      "ST",
      "SZ",
      "TD",
      "TG",
      "TN",
      "TZ",
      "UG",
      "YT",
      "ZA",
      "ZM",
      "ZW",
    ],
  },
  AN: {
    name: "Antarctica",
    countries: ["AQ", "GS", "TF"],
  },
  SA: {
    name: "South America",
    countries: [
      "AR",
      "BO",
      "BR",
      "CL",
      "CO",
      "EC",
      "FK",
      "GF",
      "GY",
      "PE",
      "PY",
      "SR",
      "UY",
      "VE",
    ],
  },
  OC: {
    name: "Oceania",
    countries: [
      "AS",
      "AU",
      "CK",
      "FJ",
      "FM",
      "GU",
      "KI",
      "MH",
      "MP",
      "NC",
      "NF",
      "NR",
      "NU",
      "NZ",
      "PF",
      "PG",
      "PN",
      "PW",
      "SB",
      "TK",
      "TO",
      "TV",
      "VU",
      "WF",
      "WS",
    ],
  },
};

export default continents;
