import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { contactBrandConnectionSchema } from "@models/ContactBrandConnection";
import axiosInstance from "@services/api/config";

import { GetBuyerBrands } from "./GetBuyerBrands";

export namespace InviteBuyerBrand {
  export const inputSerializingSchema = z.object({
    brandId: z.string().uuid().nullable(),
    name: z.string(),
    mainContactFirstname:
      contactBrandConnectionSchema.shape.mainContactFirstname.unwrap().unwrap(),
    mainContactLastname: contactBrandConnectionSchema.shape.mainContactLastname
      .unwrap()
      .unwrap(),
    mainContactEmail: contactBrandConnectionSchema.shape.mainContactEmail
      .unwrap()
      .unwrap(),
  });
  export type Input = z.input<typeof inputSerializingSchema>;

  export const outputHydratingSchema = z.object({});
  export type Output = z.output<typeof outputHydratingSchema>;

  interface MutationParams {
    data: Input;
  }

  type Params = MutationParams;

  export const path = () => `/buyer/brands/invite`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(), inputSerializingSchema.parse(params.data))
      .then((res) => outputHydratingSchema.parse(res.data));

  export function useHook() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: GetBuyerBrands.getQueryKeys(),
        });
      },
    });
  }
}
