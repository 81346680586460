import React, { PropsWithChildren } from "react";

export function PageTitle({ children }: PropsWithChildren) {
  return <h1 className="heading-1 p-4">{children}</h1>;
}

export function PageHeader({ children }: PropsWithChildren) {
  return (
    <div className="flex min-md:grow items-center justify-between">
      {children}
    </div>
  );
}
