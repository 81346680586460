import React from "react";

import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import Button from "@components/data-entry/Button";
import BottomBar from "@components/layout/BottomBar";
import { OnboardingStep } from "@models/old/Organization";
import OnboardingBreadcrumb from "@onboarding/components/Breadcrumb";
import { useFetchCollectionsWithCapabilities } from "@services/api/old/collections/fetch-collections-with-capabilities";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import CollectionsOverview from "@shared/collections/collection-overview";

export default function OnboardingShowroomStructurePage() {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();
  const navigate = useNavigate();

  const { data } = useFetchCollectionsWithCapabilities(organizationId);

  return (
    <div className="flex justify-center w-full">
      <div className="flex flex-col justify-between w-full h-screen">
        <header className="py-12 px-10 h-fit w-full">
          <OnboardingBreadcrumb
            currentStep={OnboardingStep.SHOWROOM_STRUCTURE}
          />
        </header>

        <div className="flex items-start justify-between grow w-full px-10">
          <section className="flex flex-col w-full gap-6">
            <h1 className="heading-1">Showroom Structure</h1>
            <div className="flex gap-6 w-full">
              <CollectionsOverview isOnboarding />
            </div>
          </section>
        </div>

        <BottomBar>
          <Button
            onClick={() => navigate(`/onboarding/information`)}
            theme="TERTIARY"
          >
            <HiChevronLeft className="w-6 h-6" />
            <span>Organization informations</span>
          </Button>
          <Button
            disabled={data === undefined || data.length === 0}
            onClick={() => navigate(`/onboarding/account-portfolio`)}
            theme="PRIMARY"
          >
            <span>Next: account portfolio</span>
            <HiChevronRight className="w-6 h-6" />
          </Button>
        </BottomBar>
      </div>
    </div>
  );
}
