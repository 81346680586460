import React, { useId } from "react";

import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";

import Button from "@components/data-entry/Button";

interface CollapseProps {
  title: React.ReactNode;
  children: React.ReactNode;
  collapsed: boolean;
  onToggle: () => void;
  disabled?: boolean;
  className?: string;
  buttonClassName?: string;
  contentClassName?: string;
  divider?: boolean;
  titleJustify?: "start" | "end" | "center" | "between";
  addText?: boolean;
  uncollapsable?: boolean;
  message?: string;
  buttonWidth?: string;
  removeTitleSpacing?: boolean;
}

function Collapse({
  title,
  children,
  collapsed,
  onToggle,
  disabled = false,
  className = "",
  buttonClassName = "",
  contentClassName = "",
  divider = false,
  titleJustify = "center",
  addText,
  uncollapsable = false,
  message,
  buttonWidth,
  removeTitleSpacing = false,
}: CollapseProps) {
  const id = useId();

  const getClassName = () => {
    if (disabled) {
      return "opacity-50 bg-primaryLightGrey cursor-not-allowed pointer-events-none";
    }
    if (uncollapsable) {
      return "cursor-not-allowed pointer-events-none";
    }
    return "cursor-pointer";
  };

  const renderIcon = () => {
    if (uncollapsable) {
      return (
        <span className="ml-2 text-right w-52 text-sm text-gray-500 italic">
          {message}
        </span>
      );
    }
    return collapsed ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />;
  };
  return (
    <div
      className={`rounded-lg ${removeTitleSpacing ? "mb-2" : "mb-4 md:mb-6"} flex flex-col ${className} ${
        collapsed ? "" : `${removeTitleSpacing ? "" : "pb-4 md:pb-6"}`
      } ${getClassName()}`}
    >
      <Button
        theme="NONE"
        type="button"
        className={`w-full pt-4 md:pt-6 px-4 md:px-6 ${buttonClassName}`}
        onClick={uncollapsable ? undefined : onToggle}
        aria-expanded={!collapsed}
        aria-controls={`children-${id}`}
        justify={titleJustify}
      >
        {title}
        {addText && !uncollapsable && (
          <span
            className={`ml-2 text-right ${buttonWidth || "w-52"} text-sm text-primar text-primaryElectricBlue`}
          >
            {collapsed ? "Hide Details" : "Show Details"}
          </span>
        )}
        {renderIcon()}
      </Button>
      {divider && collapsed && (
        <div className="h-1 border-b border-primaryLightGrey" />
      )}
      {collapsed && (
        <div
          id={`children-${id}`}
          className={`px-4 md:px-6 pb-4 md:pb-6 ${contentClassName}`}
          role="region"
          aria-labelledby={`button-${id}`}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default Collapse;
