import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import { organizationSchema } from "@models/Organization";
import axiosInstance from "@services/api/config";
import useApplicationContext from "@services/application/useApplicationContext";

export namespace CreateOrganization {
  export const createBrandOrganizationSchema = z.object({
    name: organizationSchema.shape.name,
    type: z.literal("BRAND"),
    address: organizationSchema.shape.address,
    timezone: organizationSchema.shape.timezone,
    brand: z.object({
      numberOfStockists: z.string(),
      activeSeasons: z.array(z.string()),
      numberOfYearlyCollections: z.string(),
      activeMarkets: z.array(z.string()),
      activeLocations: z.array(z.string()),
    }),
  });
  export const createAgencyOrganizationSchema = z.object({
    name: organizationSchema.shape.name,
    type: z.literal("AGENCY"),
    address: organizationSchema.shape.address,
    timezone: organizationSchema.shape.timezone,
  });
  export const inputSerializingSchema = z.discriminatedUnion("type", [
    createBrandOrganizationSchema,
    createAgencyOrganizationSchema,
  ]);

  export type Input = z.input<typeof inputSerializingSchema>;

  export const outputHydratingSchema = organizationSchema
    .pick({
      id: true,
      name: true,
      type: true,
      timezone: true,
      address: true,
      banner: true,
      onboardingStatus: true,
      accountPortfolioType: true,
      currentPlan: true,
    })
    .extend({
      brand: brandSchema.pick({
        id: true,
        name: true,
        organizationId: true,
      }),
      partnerBrands: z.array(
        brandSchema.pick({
          id: true,
          name: true,
          organizationId: true,
        }),
      ),
    });
  export type Output = z.output<typeof outputHydratingSchema>;

  interface MutationParams {
    data: Input;
  }

  type Params = MutationParams;

  export const path = () => `/organizations`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(), inputSerializingSchema.parse(params.data))
      .then((res) => outputHydratingSchema.parse(res.data));

  export function useHook() {
    const { setOrganization } = useApplicationContext();
    return useMutation({
      mutationFn: call,
      onSuccess: (organization) => {
        setOrganization(organization);
      },
    });
  }
}
