import React, { PropsWithChildren, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import InputLabel from "@components/data-display/InputLabel";
import AddressAutocomplete from "@components/data-entry/AddressAutocomplete";
import Button from "@components/data-entry/Button";
import SingleSelect from "@components/data-entry/SingleSelect";
import TextField, { inputStyle } from "@components/data-entry/TextField";
import { AccountType, accountTypeSchema } from "@models/Account";
import { AccountTypeList } from "@models/OrganizationAccount";
import { Address } from "@models/types/Address";
import { CreateBuyerAccount } from "@services/api/buyer-account/CreateBuyerAccount";
import { UpdateBuyerContact } from "@services/api/buyer-contact/UpdateBuyerContact";
import { auth } from "@services/authentication/firebase";
import useAuthentication, {
  useAuthenticatedBuyer,
} from "@services/authentication/useAuthentication";

export const buyerOnboardingFormSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  phoneNumber: z.string().nullish(),
  account: z.object({
    name: z.string().min(1),
    type: accountTypeSchema,
    address: z.string().min(1),
    city: z.string().min(1),
    countryCode: z.string().min(1),
  }),
});
export type BuyerOnboardingFormData = z.infer<typeof buyerOnboardingFormSchema>;

export default function BuyerOnboardingDone({
  children,
}: Readonly<PropsWithChildren<{}>>) {
  const [address, setAddress] = useState<Address | null>(null);
  const user = useAuthenticatedBuyer();
  const { updateUser } = useAuthentication();

  const { t } = useTranslation();
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<BuyerOnboardingFormData>({
    resolver: zodResolver(buyerOnboardingFormSchema),
    defaultValues: {
      firstName: user.contact.firstName,
      lastName: user.contact.lastName,
      email: user.contact.email,
      phoneNumber: user.contact.phoneNumber,
      account: {
        name: user.account?.name,
        type: user.account?.type,
        address: user.account?.address ?? undefined,
        city: user.account?.city ?? undefined,
        countryCode: user.account?.countryCode ?? undefined,
      },
    },
  });
  const accountName = watch("account.name");
  const accountType = watch("account.type");

  const { mutateAsync: createBuyerAccount } = CreateBuyerAccount.useHook();
  const { mutateAsync: updateBuyerContact } = UpdateBuyerContact.useHook();
  if (user.account) {
    return children;
  }

  async function submit(data: BuyerOnboardingFormData) {
    await createBuyerAccount({ data: data.account });
    await updateBuyerContact({
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber ?? null,
      },
    });
    const token = await auth.currentUser?.getIdToken();
    if (token) await updateUser(token);
  }

  return (
    <form className="px-4" onSubmit={handleSubmit(submit)}>
      <h4 className="mt-4 mb-4 text-lg font-bold">Your profile</h4>

      <div className="grid grid-cols-2 gap-x-4">
        <InputLabel htmlFor="firstName" error={errors.firstName?.message}>
          {t("BuyerProfileGuard.firstName-label")}
        </InputLabel>
        <InputLabel htmlFor="lastName" error={errors.lastName?.message}>
          {t("BuyerProfileGuard.lastName-label")}
        </InputLabel>
        <input
          className={inputStyle({
            errored: !!errors.firstName,
            className: "mb-4",
          })}
          id="firstName"
          type="text"
          placeholder={t("BuyerProfileGuard.firstName-placeholder")}
          {...register("firstName")}
        />

        <input
          className={inputStyle({
            errored: !!errors.lastName,
            className: "mb-4",
          })}
          id="lastName"
          type="text"
          placeholder={t("BuyerProfileGuard.lastName-placeholder")}
          {...register("lastName")}
        />
      </div>

      <InputLabel htmlFor="email" error={errors.email?.message}>
        {t("BuyerProfileGuard.email-label")}
      </InputLabel>
      <input
        className={inputStyle({ errored: !!errors.email, className: "mb-4" })}
        id="email"
        type="email"
        placeholder={t("BuyerProfileGuard.email-placeholder")}
        {...register("email")}
      />

      <InputLabel htmlFor="phoneNumber" error={errors.phoneNumber?.message}>
        {t("BuyerProfileGuard.phoneNumber-label")}
      </InputLabel>
      <input
        className={inputStyle({
          errored: !!errors.phoneNumber,
          className: "mb-4",
        })}
        id="phoneNumber"
        type="text"
        placeholder={t("BuyerProfileGuard.phoneNumber-placeholder")}
        {...register("phoneNumber")}
      />

      <hr />
      <h4 className="mt-4 mb-4 text-lg font-bold">Your company</h4>

      <div className="grid grid-cols-2 gap-x-4">
        <InputLabel
          htmlFor="account.name"
          error={errors.account?.name?.message}
        >
          {t("BuyerProfileGuard.account.name-label")}
        </InputLabel>
        <InputLabel
          htmlFor="account.type"
          error={errors.account?.type?.toString()}
        >
          {t("BuyerProfileGuard.account.type-label")}
        </InputLabel>

        <TextField
          className="mb-4"
          id="account.name"
          placeholder={t("BuyerProfileGuard.account.name-placeholder")}
          onChange={(v) => setValue("account.name", v)}
          value={accountName}
        />

        {/** accounttype field */}

        <SingleSelect
          id="account.type"
          className="mb-4"
          options={Object.values(AccountTypeList).map((at) => ({
            label: t(`Common.account-type.${at}`),
            value: at,
          }))}
          value={
            accountType
              ? {
                  label: t(`Common.account-type.${accountType}`),
                  value: accountType,
                }
              : undefined
          }
          onChange={(v) =>
            v ? setValue("account.type", v.value as AccountType) : null
          }
        />
      </div>
      {/** account address field */}
      <InputLabel htmlFor="account.address">
        {t("BuyerProfileGuard.account.address-label")}
      </InputLabel>
      <AddressAutocomplete
        className="mb-4"
        defaultValue={address}
        onChange={(addr: Address | undefined) => {
          setAddress(addr || null);
          if (addr) {
            setValue("account.address", addr.formattedAddress);
            setValue("account.city", addr.city);
            setValue("account.countryCode", addr.countryCode);
          }
        }}
      />

      <Button theme="PRIMARY" type="submit">
        Submit
      </Button>
    </form>
  );
}
