import { useContext } from "react";

import { AuthenticationContext } from "./AuthenticationContext";
import {
  AuthenticatedAdmin,
  AuthenticatedBuyer,
  AuthenticatedRepresentative,
  User,
  isAdmin,
  isBuyer,
  isRepresentative,
} from "./authentication.types";

export default function useAuthentication() {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error(
      "useAuthentication must be used within a AuthenticationProvider",
    );
  }
  return context;
}

/**
 * Hook helper that makes sure the current user is authenticated
 * It will throw an error if user is not authenticated
 *
 * ex: const user: User = useAuthenticatedUser();
 */
export function useAuthenticatedUser(): User {
  const { currentUser } = useAuthentication();
  if (!currentUser) {
    throw new Error("user not authenticated");
  }
  return currentUser;
}

export function useAuthenticatedAdmin(): AuthenticatedAdmin {
  const user = useAuthenticatedUser();
  if (!isAdmin(user)) {
    throw new Error("user is not an admin");
  }
  return user;
}

export function useAuthenticatedBuyer(): AuthenticatedBuyer {
  const user = useAuthenticatedUser();
  if (!isBuyer(user)) {
    throw new Error("user is not a buyer");
  }
  return user;
}

export function useAuthenticatedRepresentative(): AuthenticatedRepresentative {
  const user = useAuthenticatedUser();
  if (!isRepresentative(user)) {
    throw new Error("user is not a representative");
  }
  return user;
}
