import { useState } from "react";

export function arrayFilterUnique(item: any, index: number, array: any[]) {
  return array.indexOf(item) === index;
}

export function arrayFilterDuplicateIds<T extends { id: string }>(
  item: T,
  index: number,
  array: T[],
) {
  const foundAtIndex = array.findIndex((obj) => obj.id === item.id);
  return index === foundAtIndex;
}

export function arrayFilterIsIdInArray<T extends { id: string }>(
  idArray: string[],
) {
  return (item: T) => idArray.includes(item.id);
}

export function hasAtLeastOneItem<T>(array: T[]): array is [T, ...T[]] {
  return array.length > 0;
}

export function toNonEmptyArray<T>(array: T[]) {
  if (!hasAtLeastOneItem(array)) {
    throw new Error("Array is empty. Expected at least one item.");
  }
  return array;
}

export function arrayFilterRemoveNull<S>(value: S | undefined): value is S {
  return value != null;
}

function removeUniqueValue<T>(array: T[], value: T) {
  const s = new Set(array);
  s.delete(value);
  return Array.from(s);
}

function addUniqueValue<T>(array: T[], value: T) {
  const s = new Set(array);
  s.add(value);
  return Array.from(s);
}

export function toggleValue<T>(toggle: boolean, value: T, array: T[]) {
  return toggle
    ? addUniqueValue(array, value)
    : removeUniqueValue(array, value);
}

export function removeValueWithId<T extends { id: string }>(
  array: T[],
  id: string,
) {
  return array.filter((i) => i.id !== id);
}

export function useUniqueStringArray(initial: string[]) {
  const [state, setState] = useState(initial);

  const add = (value: string) => setState(addUniqueValue(state, value));
  const remove = (value: string) => setState(removeUniqueValue(state, value));
  const toggle = (value: string) =>
    setState(toggleValue(!state.includes(value), value, state));

  return {
    state,
    add,
    remove,
    toggle,
  };
}

export function getById<T extends { id: string }>(id: string, array: T[]) {
  return array.find((item) => item.id === id);
}
