import React from "react";

import { useParams } from "react-router-dom";

import AppointmentInformationCard from "@app/modules/crm/components/account/appointment-info";
import MeetingReportForm from "@app/modules/crm/components/account/meeting-report/form";
import MeetingReportPageTitle from "@app/modules/crm/components/account/meeting-report/page-title";
import Loading from "@components/feedback/Loading";
import { GetAppointment } from "@services/api/appointments/get-appointment";
import { GetMeetingReport } from "@services/api/appointments/get-meeting-report";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import LogService from "@services/log/service";

function MeetingReport() {
  const { appointmentId } = useParams();
  if (!appointmentId) {
    throw new Error("Appointment ID is required");
  }

  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const {
    data: appointment,
    isPending: isAppointmentPending,
    isError: isAppointmentError,
    error: appointmentError,
  } = GetAppointment.useHook({
    organizationId,
    appointmentId,
  });

  const {
    data: meetingReport,
    isLoading: isMeetingReportLoading,
    isError: isMeetingReportError,
    error: meetingReportError,
  } = GetMeetingReport.useHook({
    organizationId,
    appointmentId,
  });

  if (isAppointmentPending || isMeetingReportLoading) {
    return (
      <div className="flex items-center justify-center my-10">
        <Loading type="screen" />
      </div>
    );
  }

  if (isAppointmentError) {
    LogService.error("error displaying appointment informations");
    LogService.error(appointmentError);
    return (
      <div className="flex items-center justify-center my-10">
        {isAppointmentError && <div>{`Ooops ${appointmentError}`}</div>}
      </div>
    );
  }

  if (isMeetingReportError || meetingReport === undefined) {
    LogService.error("error displaying meeting report informations");
    LogService.error(meetingReportError);
    return (
      <div className="flex items-center justify-center my-10">
        {isMeetingReportError && <div>{`Ooops ${meetingReportError}`}</div>}
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full gap-6 pt-8">
      <MeetingReportPageTitle
        season={appointment.showroom.season}
        year={appointment.showroom.year}
      />
      <AppointmentInformationCard appointment={appointment} />
      <MeetingReportForm
        showroomId={appointment.showroom.id}
        accountId={appointment.account.id}
        appointmentId={appointmentId}
        meetingReport={meetingReport}
        accountOtb={appointment.accountOtb}
        contactBooking={appointment.attendees[0].firstName}
      />
    </div>
  );
}

export default MeetingReport;
