import React from "react";

import { useTranslation } from "react-i18next";
import { HiMail, HiOutlineLogout } from "react-icons/hi";
import {
  HiCalendarDays,
  HiChevronDown,
  HiLifebuoy,
  HiOutlineBriefcase,
  HiOutlineBuildingStorefront,
  HiOutlineCog,
  HiOutlineHome,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
} from "react-icons/hi2";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import modaresaLogoShort from "@app/assets/logos/moda-resa-logo.svg";
import modaresaLogo from "@app/assets/logos/moda-resa.svg";
import { cn } from "@app/lib/utils";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@components/ui/collapsible";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarRail,
  SidebarSeparator,
  SidebarTrigger,
  useSidebar,
} from "@components/ui/sidebar";
import { OrganizationPlan, organizationPlanSchema } from "@models/Organization";
import { UserRole } from "@models/types/enums";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { User } from "@services/authentication/authentication.types";
import useAuthentication, {
  useAuthenticatedUser,
} from "@services/authentication/useAuthentication";
import useCrisp from "@shared/helpers/crisp";

import {
  ACL_ADMINS_AGENTS_MANAGERS,
  ACL_ADMINS_MANAGERS,
} from "../access-control/helpers";

interface SidebarItemDef {
  path: string;
  label: string;
  icon?: React.JSX.Element;
  aclRoles?: UserRole[];
  subItems?: SidebarItemDef[];
  plans: OrganizationPlan[];
}

const sideBarItems: SidebarItemDef[] = [
  {
    path: "/dashboard",
    label: "Components.sidebar.dashboard",
    aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    plans: [organizationPlanSchema.Values.PRO],
    icon: <HiOutlineHome className="w-6 h-6" />,
  },
  {
    path: "/showroom",
    label: "Components.sidebar.sales-campaigns",
    aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    plans: [organizationPlanSchema.Values.PRO],
    icon: <HiOutlineBuildingStorefront className="w-6 h-6" />,
  },
  {
    path: "/crm",
    label: "Components.sidebar.crm.name",
    aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    plans: [
      organizationPlanSchema.Values.PRO,
      organizationPlanSchema.Values.LITE,
    ],
    icon: <HiOutlineUserGroup className="w-6 h-6" />,
    subItems: [
      {
        label: "Components.sidebar.crm.accounts",
        path: "/crm/accounts",
        plans: [
          organizationPlanSchema.Values.PRO,
          organizationPlanSchema.Values.LITE,
        ],
      },
      {
        label: "Components.sidebar.crm.contacts",
        path: "/crm/contacts",
        plans: [
          organizationPlanSchema.Values.PRO,
          organizationPlanSchema.Values.LITE,
        ],
      },
      {
        label: "Components.sidebar.crm.competitors-groups",
        path: "/crm/competitors",
        aclRoles: ACL_ADMINS_MANAGERS,
        plans: [
          organizationPlanSchema.Values.PRO,
          organizationPlanSchema.Values.LITE,
        ],
      },
      {
        label: "Components.sidebar.crm.accounts-archived",
        path: "/crm/archived-accounts",
        plans: [
          organizationPlanSchema.Values.PRO,
          organizationPlanSchema.Values.LITE,
        ],
      },
      {
        label: "Components.sidebar.crm.contacts-archived",
        path: "/crm/archived-contacts",
        plans: [
          organizationPlanSchema.Values.PRO,
          organizationPlanSchema.Values.LITE,
        ],
      },
    ],
  },
  {
    path: "/representatives/list",
    label: "Components.sidebar.organization-representatives",
    aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    plans: [
      organizationPlanSchema.Values.PRO,
      organizationPlanSchema.Values.LITE,
    ],
    icon: <HiOutlineBriefcase className="w-6 h-6" />,
  },
  {
    path: "/organization-profile",
    label: "Components.sidebar.organization-profile.name",
    aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    plans: [
      organizationPlanSchema.Values.PRO,
      organizationPlanSchema.Values.LITE,
    ],
    icon: <HiOutlineCog className="w-6 h-6" />,
    subItems: [
      {
        label: "Components.sidebar.organization-profile.information",
        path: "/organization-profile/information",
        plans: [
          organizationPlanSchema.Values.PRO,
          organizationPlanSchema.Values.LITE,
        ],
      },
      {
        label: "Components.sidebar.organization-profile.collections",
        path: "/organization-profile/collections",
        plans: [
          organizationPlanSchema.Values.PRO,
          organizationPlanSchema.Values.LITE,
        ],
      },
      {
        label: "Components.sidebar.organization-profile.portfolios",
        path: "/organization-profile/portfolios",
        plans: [
          organizationPlanSchema.Values.PRO,
          organizationPlanSchema.Values.LITE,
        ],
      },
    ],
  },
  {
    path: "/calendar",
    label: "Components.sidebar.calendar",
    icon: <HiCalendarDays className="w-6 h-6" />,
    plans: [organizationPlanSchema.Values.PRO],
  },
  {
    path: "/invitations",
    aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    label: "Components.sidebar.invitations",
    icon: <HiMail className="w-6 h-6" />,
    plans: [organizationPlanSchema.Values.PRO],
  },
];

function filterSideBarItems(
  items: SidebarItemDef[],
  currentUser: User,
  currentPlan: OrganizationPlan,
) {
  return items.filter(
    (item) =>
      (!item.aclRoles || item.aclRoles.includes(currentUser?.role)) &&
      item.plans.includes(currentPlan),
  );
}

function FrontofficeSideBar() {
  const { t } = useTranslation();
  const { open, openMobile, isMobile } = useSidebar();
  const location = useLocation();
  const user = useAuthenticatedUser();
  const {
    organization: { currentPlan },
  } = useOrganizationAppContext();
  const { impersonation, stopImpersonation, signOut } = useAuthentication();
  const { trigger: openCrisp, isCrispOpen } = useCrisp();
  const navigate = useNavigate();

  const isOpen = (!isMobile && open) || (isMobile && openMobile);
  return (
    <Sidebar
      side="left"
      variant="sidebar"
      collapsible="icon"
      className="z-sidebar"
    >
      <SidebarHeader
        className={cn("my-4 flex items-center justify-center gap-2", {
          "flex-row": isOpen,
          "flex-col-reverse": !isOpen,
        })}
      >
        <img
          className="h-5"
          alt="modaresa logo"
          src={isOpen ? modaresaLogo : modaresaLogoShort}
        />
        <SidebarTrigger />
      </SidebarHeader>
      <SidebarContent
        className={isOpen ? "px-2" : "flex flex-col items-center gap-2"}
      >
        {filterSideBarItems(sideBarItems, user, currentPlan).map((item) =>
          item.subItems ? (
            <Collapsible
              key={item.path}
              defaultOpen
              className="group/collapsible"
            >
              <SidebarMenuButton
                isActive={location.pathname.startsWith(item.path)}
                asChild
                className="rounded-lg"
              >
                <CollapsibleTrigger
                  onClick={() =>
                    isOpen
                      ? null
                      : navigate(item.subItems?.[0].path ?? item.path)
                  }
                >
                  {item.icon} <span>{t(item.label)}</span>
                  <HiChevronDown className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-180" />
                </CollapsibleTrigger>
              </SidebarMenuButton>
              <CollapsibleContent className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95">
                <SidebarMenuSub>
                  {item.subItems.map((sub) => (
                    <SidebarMenuSubButton
                      isActive={location.pathname === sub.path}
                      key={sub.path}
                      asChild
                      className="focus:font-medium rounded-lg"
                    >
                      <NavLink to={sub.path}>
                        {sub.icon}
                        <span>{t(sub.label)}</span>
                      </NavLink>
                    </SidebarMenuSubButton>
                  ))}
                </SidebarMenuSub>
              </CollapsibleContent>
            </Collapsible>
          ) : (
            <SidebarMenuButton
              isActive={location.pathname === item.path}
              key={item.path}
              asChild
              className="rounded-lg"
            >
              <NavLink to={item.path}>
                {item.icon}
                <span>{t(item.label)}</span>
              </NavLink>
            </SidebarMenuButton>
          ),
        )}
        <SidebarMenuButton
          onClick={() => openCrisp()}
          className="border border-primaryElectricBlue text-primaryElectricBlue hover:bg-primaryElectricBlue hover:text-white active:bg-primaryElectricBlue active:text-white rounded-lg"
        >
          <HiLifebuoy />
          <span>
            {t(
              isCrispOpen
                ? "Booking.close-crisp-support"
                : "Booking.crisp-support",
            )}
          </span>
        </SidebarMenuButton>
      </SidebarContent>
      <SidebarFooter>
        <SidebarMenu>
          <SidebarSeparator />
          <SidebarMenuButton asChild className="rounded-lg">
            <NavLink to="/my-profile">
              <HiOutlineUserCircle className="w-6 h-6" />
              <span>{t("Components.sidebar.profile")}</span>
            </NavLink>
          </SidebarMenuButton>
          {/* IMPERSONATION OR LOG OUT BUTTONS */}
          {impersonation ? (
            <SidebarMenuButton
              className="bg-red-500 text-white hover:bg-red-600 hover:text-white rounded-lg"
              onClick={() => stopImpersonation()}
            >
              <HiOutlineLogout className="w-6 h-6" />
              <span>{t("Components.sidebar.stop-impersonation")}</span>
            </SidebarMenuButton>
          ) : (
            <SidebarMenuButton onClick={() => signOut()} className="rounded-lg">
              <HiOutlineLogout className="w-6 h-6" />
              <span>{t("Components.sidebar.logout")}</span>
            </SidebarMenuButton>
          )}
        </SidebarMenu>
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}

export default FrontofficeSideBar;
