import React from "react";

import { addHours } from "date-fns";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { HiExclamation, HiOutlineCalendar } from "react-icons/hi";
import { toast } from "react-toastify";

import queryClient from "@app/queryClient";
import {
  getAppointmentFormatIcon,
  getAppointmentFormatLabel,
  getAppointmentVirtualMeetingAppLabel,
} from "@calendar/helpers/formatters";
import Button from "@components/data-entry/Button";
import CustomDatePicker from "@components/data-entry/CustomDatepicker";
import MultiSelect from "@components/data-entry/MultiSelect";
import Select from "@components/data-entry/Select";
import TimePicker from "@components/data-entry/TimePicker";
import { SelectOptionBase } from "@components/data-entry/wrapper/ReactSelect";
import BottomBar from "@components/layout/BottomBar";
import { arrayFilterUnique } from "@helpers/Array";
import { LocalDate, ZonedDate, combineDateAndTime } from "@helpers/Date";
import { Collection } from "@models/Collection";
import { Organization } from "@models/Organization";
import { OrganizationAccount } from "@models/OrganizationAccount";
import { Representative } from "@models/OrganizationRepresentative";
import { Showroom } from "@models/Showroom";
import { SpecialRequestInput } from "@models/SpecialRequest";
import {
  AppointmentFormat,
  AppointmentFormatList,
  AppointmentTypeEnum,
  VirtualMeetingAppChoice,
  VirtualMeetingAppsList,
} from "@models/types/enums";
import { BookingUpsertSpecialRequestEndpoint } from "@services/api/booking/upsert-special-request";
import { queryKeysFetchSpecialRequests } from "@services/api/old/booking/fetch-special-requests";
import { getCodeByLanguage, getLanguageByCode } from "@services/languages";

type FormValues = {
  selectedDate: Date;
  fromHour: Date;
  toHour: Date;
  languages: SelectOptionBase[];
  format: AppointmentFormat;
  virtualMeetingApp: VirtualMeetingAppChoice | undefined;
};

interface SpecialRequestFormProps {
  selectedSpecialRequest?: SpecialRequestInput;
  invitationId: string;
  showroom: Pick<Showroom, "id" | "timezone"> & {
    sellers: Pick<Representative, "languages">[];
  };
  organization: Pick<Organization, "name">;
  account: Pick<OrganizationAccount, "name">;
  appointmentType: AppointmentTypeEnum;
  collection: Pick<Collection, "id" | "name"> | null;
  onSuccess: (
    specialRequest: BookingUpsertSpecialRequestEndpoint.Output,
  ) => void;
  onError: (error: string) => void;
  onCancel: () => void;
}

export default function SpecialRequestForm({
  selectedSpecialRequest,
  invitationId,
  showroom,
  organization,
  account,
  appointmentType,
  collection,
  onSuccess,
  onError,
  onCancel,
}: SpecialRequestFormProps) {
  const availableLanguages = showroom.sellers
    .flatMap((seller) => seller.languages)
    .filter(arrayFilterUnique);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    getValues,
    setValue,
    watch,
  } = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: {
      selectedDate: selectedSpecialRequest?.selectedDate || undefined,
      fromHour: selectedSpecialRequest?.fromHour || undefined,
      toHour: selectedSpecialRequest?.toHour || undefined,
      languages: selectedSpecialRequest?.languages?.map((code) => ({
        label: getLanguageByCode(code.toLowerCase()),
        value: code.toLowerCase(),
      })),
      format: selectedSpecialRequest?.format || undefined,
      virtualMeetingApp: selectedSpecialRequest?.virtualMeetingApp || undefined,
    },
  });

  const fromHour = watch("fromHour");
  const toHour = watch("toHour");
  const formatSelected = watch("format");

  const { mutateAsync: upsertSpecialRequest } =
    BookingUpsertSpecialRequestEndpoint.useHook({
      invitationId,
      specialRequestId: selectedSpecialRequest?.id,
    });

  const onSubmitForm = (data: FormValues) => {
    const startTime = ZonedDate.fromLocalDate(
      combineDateAndTime(data.selectedDate, data.fromHour) as LocalDate,
      showroom.timezone,
    );

    const endTime = ZonedDate.fromLocalDate(
      combineDateAndTime(data.selectedDate, data.toHour) as LocalDate,
      showroom.timezone,
    );

    upsertSpecialRequest({
      data: {
        id: selectedSpecialRequest?.id,
        invitationId,
        startTime,
        endTime,
        languages: data.languages.map((l) => l.value),
        format: data.format,
        virtualMeetingApp: data.virtualMeetingApp || null,
        showroomId: showroom.id,
        collectionId:
          appointmentType !== AppointmentTypeEnum.WALKTHROUGH
            ? collection?.id!
            : null,
        type: appointmentType,
      },
    })
      .then(async (specialRequest) => {
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchSpecialRequests(invitationId),
        });
        if (selectedSpecialRequest?.id) {
          toast.success(
            t(
              "Booking.AppointmentBookingSlotSelect.special-request-form.request-updated",
            ),
          );
        } else {
          toast.success(
            t(
              "Booking.AppointmentBookingSlotSelect.special-request-form.request-created",
            ),
          );
        }
        onSuccess(specialRequest);
      })
      .catch((err) => {
        onError(err.message);
      });
  };
  return (
    <form
      className="flex flex-col h-full"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <div className="px-4 py-6 lg:p-10 flex flex-col grow gap-10">
        <div className="flex flex-col gap-4">
          <p>
            {t(
              "Booking.AppointmentBookingSlotSelect.special-request-form.sentence-unable-to-find",
            )}
          </p>
          <p>
            {t(
              "Booking.AppointmentBookingSlotSelect.special-request-form.sentence-no-guarantee",
            )}
          </p>
          <p className="bg-primaryLightElectricBlue rounded-xl py-4 px-6 mt-4">
            {appointmentType === AppointmentTypeEnum.WALKTHROUGH ? (
              <Trans
                i18nKey="Booking.AppointmentBookingSlotSelect.special-request-form.blue-box-walkthrough"
                values={{
                  organizationName: organization.name,
                  accountName: account.name,
                }}
                components={{
                  bold: <strong className="font-bold" />,
                  br: <br />,
                }}
              >
                Booking.AppointmentBookingSlotSelect.special-request.blue-box-walkthrough
              </Trans>
            ) : (
              <Trans
                i18nKey="Booking.AppointmentBookingSlotSelect.special-request-form.blue-box-collection"
                values={{
                  organizationName: organization.name,
                  accountName: account.name,
                  collectionName: collection?.name,
                }}
                components={{
                  bold: <strong className="font-bold" />,
                  br: <br />,
                }}
              >
                Booking.AppointmentBookingSlotSelect.special-request.blue-box-collection
              </Trans>
            )}
          </p>
        </div>
        <div className="flex flex-col gap-6">
          <div className="w-full lg:w-2/3 xl:w-1/2">
            <label className="font-medium" htmlFor="selectedDate">
              {t(
                "Booking.AppointmentBookingSlotSelect.special-request-form.selected-date-label",
              )}
            </label>
            <div className="mt-2">
              <Controller
                name="selectedDate"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <CustomDatePicker
                    placeholder={
                      <div className="flex justify-center items-center gap-2 w-full lg:w-80">
                        <HiOutlineCalendar />{" "}
                        <span className="text-grey">
                          {t(
                            "Booking.AppointmentBookingSlotSelect.special-request-form.selected-date-placeholder",
                          )}
                        </span>
                      </div>
                    }
                    value={value}
                    onChange={(date) => {
                      if (date === null) {
                        onChange();
                      } else {
                        onChange(date);
                      }
                    }}
                    intlOptions={{
                      month: "numeric",
                      day: "numeric",
                      year: "numeric",
                    }}
                  />
                )}
              />
              {errors.selectedDate &&
                errors.selectedDate.type === "required" && (
                  <p className="text-xs italic text-primaryRed">
                    {t("Common.form.this-is-required")}
                  </p>
                )}
            </div>
          </div>
          <div className="flex flex-col w-full" data-testid="from-to-hour">
            <div className="flex items-center">
              <label className="font-medium" htmlFor="fromHour">
                {t(
                  "Booking.AppointmentBookingSlotSelect.special-request-form.from-hour-label",
                )}
              </label>
              <div className="w-28 mx-2">
                <Controller
                  name="fromHour"
                  control={control}
                  rules={{
                    validate: {
                      isBeforeClosing: (openingHourValue) =>
                        getValues().toHour === undefined ||
                        openingHourValue < getValues().toHour ||
                        t(
                          "Booking.AppointmentBookingSlotSelect.special-request-form.fromHour-should-be-before-closing",
                        ),
                    },
                    required: t(
                      "Booking.AppointmentBookingSlotSelect.special-request-form.fromHour-required",
                    ),
                  }}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <TimePicker
                      id="fromHour"
                      name="fromHour"
                      className="inline w-44"
                      placeholder={t(
                        "Booking.AppointmentBookingSlotSelect.special-request-form.timepicker-placeholder",
                      )}
                      options={{
                        firstHourOfDay: 6,
                        totalHours: 16,
                        timeStep: 15,
                      }}
                      onBlur={onBlur}
                      onChange={(newOpeningHour) => {
                        if (!newOpeningHour) {
                          return; // should not be able to unselect openingHour
                        }
                        if (newOpeningHour >= toHour) {
                          setValue("toHour", addHours(newOpeningHour, 1));
                        } else {
                          setValue("toHour", toHour); // refreshes the input
                        }
                        onChange(newOpeningHour);
                      }}
                      defaultValue={value}
                    />
                  )}
                />
              </div>
              <label className="font-medium" htmlFor="toHour">
                {t(
                  "Booking.AppointmentBookingSlotSelect.special-request-form.to-hour-label",
                )}
              </label>
              <div className="w-28 mx-2">
                <Controller
                  name="toHour"
                  control={control}
                  rules={{
                    validate: {
                      isAfterOpening: (value) =>
                        getValues().fromHour === undefined ||
                        value > getValues().fromHour ||
                        t(
                          "Booking.AppointmentBookingSlotSelect.special-request-form.endHour-should-be-after-opening",
                        ),
                    },
                    required: t(
                      "Booking.AppointmentBookingSlotSelect.special-request-form.endHour-required",
                    ),
                  }}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <TimePicker
                      id="toHour"
                      name="toHour"
                      className="inline w-44"
                      placeholder={t(
                        "Booking.AppointmentBookingSlotSelect.special-request-form.timepicker-placeholder",
                      )}
                      onChange={onChange}
                      onBlur={onBlur}
                      minTime={fromHour}
                      options={{
                        firstHourOfDay: 6,
                        totalHours: 16,
                        timeStep: 15,
                      }}
                      defaultValue={value}
                    />
                  )}
                />
              </div>
            </div>
            {errors.fromHour?.message && (
              <p className="text-xs mb-2 italic text-primaryRed">
                {`${errors.fromHour?.message}`}
              </p>
            )}
            {errors.toHour?.message && (
              <p className="text-xs mb-2 italic text-primaryRed">
                {`${errors.toHour?.message}`}
              </p>
            )}
            <div className="text-sm flex items-center">
              <Trans
                i18nKey="Booking.AppointmentBookingSlotSelect.special-request-form.organization-timezone"
                values={{
                  showroomTimezone: showroom.timezone,
                }}
                components={{
                  icon: (
                    <HiExclamation className="inline-block mr-2 fill-statusOrangeDark" />
                  ),
                }}
              >
                Booking.AppointmentBookingSlotSelect.special-request-form.organization-timezone
              </Trans>
            </div>
          </div>
          <div className="flex items-center flex-col lg:flex-row gap-2 w-full">
            <label className="font-medium w-full lg:w-40" htmlFor="format">
              {t(
                "Booking.AppointmentBookingSlotSelect.special-request-form.appointment-format-label",
              )}
            </label>
            <div className="mt-2 w-full lg:w-48">
              <Controller
                name="format"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Select
                    id="format"
                    name="format"
                    className={error && "border-primaryRed"}
                    placeholder={t(
                      "Booking.AppointmentBookingSlotSelect.special-request-form.appointment-format-placeholder",
                    )}
                    defaultValue={{
                      label: getAppointmentFormatLabel(value),
                      icon: getAppointmentFormatIcon(value),
                      value,
                    }}
                    onChange={onChange}
                    options={[
                      {
                        key: "format_grp1",
                        label: "formats",
                        options: AppointmentFormatList.map(
                          (format: AppointmentFormat) => ({
                            icon: getAppointmentFormatIcon(format),
                            label: getAppointmentFormatLabel(format),
                            value: format,
                          }),
                        ),
                      },
                    ]}
                  />
                )}
              />
              {errors.format && errors.format.type === "required" && (
                <p className="text-xs italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
          {formatSelected === "VIRTUAL" && (
            <div className="flex items-center flex-col lg:flex-row gap-2 w-full">
              <label
                className="font-medium w-full lg:w-36"
                htmlFor="virtualMeetingApp"
              >
                {t(
                  "Booking.AppointmentBookingSlotSelect.special-request-form.virtual-tool-label",
                )}
              </label>
              <div className="mt-2 w-full lg:w-48">
                <Controller
                  name="virtualMeetingApp"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="virtualMeetingApp"
                      name="virtualMeetingApp"
                      placeholder={t(
                        "Booking.AppointmentBookingSlotSelect.special-request-form.virtual-tool-placeholder",
                      )}
                      defaultValue={
                        value
                          ? {
                              label:
                                getAppointmentVirtualMeetingAppLabel(value),
                              value,
                            }
                          : null
                      }
                      onChange={onChange}
                      firstOption={{
                        label: getAppointmentVirtualMeetingAppLabel("ANY"),
                        value: "ANY",
                      }}
                      options={[
                        {
                          key: "virtual_meeting_app.grp1",
                          label: "virtual meeting tool",
                          options: VirtualMeetingAppsList.map(
                            (app: VirtualMeetingAppChoice) => ({
                              label: getAppointmentVirtualMeetingAppLabel(app),
                              value: app,
                            }),
                          ),
                        },
                      ]}
                    />
                  )}
                />
                {errors.virtualMeetingApp &&
                  errors.virtualMeetingApp.type === "required" && (
                    <p className="text-xs italic text-primaryRed">
                      {t("Calendar.appointment.form.error.required")}
                    </p>
                  )}
              </div>
            </div>
          )}
          <div className="flex items-center flex-col lg:flex-row gap-2 w-full">
            <label className="font-medium w-full lg:w-40" htmlFor="languages">
              {t(
                "Booking.AppointmentBookingSlotSelect.special-request-form.language-label",
              )}
            </label>
            <div className="mt-2">
              <Controller
                name="languages"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <MultiSelect
                    id="languages"
                    name="languages"
                    placeholder={t(
                      "Booking.AppointmentBookingSlotSelect.special-request-form.language-placeholder",
                    )}
                    value={value}
                    onChange={onChange}
                    options={availableLanguages.map<SelectOptionBase>((l) => ({
                      label: t(`Common.language.${l}`),
                      value: getCodeByLanguage(t(`Common.language.${l}`)),
                    }))}
                    className={error && "border-primaryRed"}
                  />
                )}
              />
              {errors.languages && errors.languages.type === "required" && (
                <p className="text-sm italic text-primaryRed">
                  {t("Common.form.this-is-required")}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <BottomBar theme="WHITE">
        <Button type="button" onClick={onCancel}>
          {t("Components.buttons.cancel")}
        </Button>
        <Button type="submit" theme="PRIMARY" disabled={!isValid}>
          {t(
            "Booking.AppointmentBookingSlotSelect.special-request-form.send-request",
          )}
        </Button>
      </BottomBar>
    </form>
  );
}
