import React, { useCallback } from "react";

// import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { VscCalendar } from "react-icons/vsc";
import { toast } from "react-toastify";
import { z } from "zod";

import Button from "@components/data-entry/Button";
import CustomDatePicker from "@components/data-entry/CustomDatepicker";
import { ShowroomSeason, ShowroomSeasonList } from "@models/types/enums";
import { UpdateOrderDeadline } from "@services/api/order-deadline/update-order-deadline";

export interface SeasonWithDeadline {
  brandId?: string;
  season: ShowroomSeason;
  year: number;
  orderDeadline?: Date | undefined;
}

export interface OrderDeadlineFormCRMProps {
  seasons: SeasonWithDeadline[];
  closeModal?: () => void;
}
function OrderDeadlineFormCRM({
  seasons,
  closeModal,
}: OrderDeadlineFormCRMProps) {
  const { t } = useTranslation();

  const { mutateAsync: updateOrderDeadline0 } = UpdateOrderDeadline.useHook({
    brandId: seasons[0].brandId || "",
    season: seasons[0].season,
    year: seasons[0].year,
  });

  const { mutateAsync: updateOrderDeadline1 } = UpdateOrderDeadline.useHook({
    brandId: seasons[1].brandId || "",
    season: seasons[1].season,
    year: seasons[1].year,
  });

  const { mutateAsync: updateOrderDeadline2 } = UpdateOrderDeadline.useHook({
    brandId: seasons[2].brandId || "",
    season: seasons[2].season,
    year: seasons[2].year,
  });

  const { mutateAsync: updateOrderDeadline3 } = UpdateOrderDeadline.useHook({
    brandId: seasons[3].brandId || "",
    season: seasons[3].season,
    year: seasons[3].year,
  });

  const onSaveDeadlines = useCallback(
    async (data: FormValues) => {
      try {
        const updatePromises = data.deadlines
          .map((deadline, index) => {
            if (!deadline.deadline) return null; // skip submission if deadline is null or undefined
            const updateHook = [
              updateOrderDeadline0,
              updateOrderDeadline1,
              updateOrderDeadline2,
              updateOrderDeadline3,
            ][index];
            return updateHook({ deadline: deadline.deadline });
          })
          .filter(Boolean); // remove null entries from the array

        await Promise.all(updatePromises);

        toast.success(t("Shared.order-deadline.updated"));
        if (closeModal) {
          closeModal();
        }
      } catch (error) {
        toast.error(t("Shared.order-deadline.update-error"));
      }
    },
    [
      updateOrderDeadline0,
      updateOrderDeadline1,
      updateOrderDeadline2,
      updateOrderDeadline3,
    ],
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      deadlines: seasons.map((item) => ({
        deadline: item.orderDeadline,
        season: item.season,
        year: item.year,
        brandId: item.brandId,
      })),
    },
  });

  // create zod schema for form validation
  const formValuesSchema = z.object({
    deadlines: z.array(
      z.object({
        deadline: z.date().optional(),
        season: z.enum(ShowroomSeasonList),
        year: z.number(),
        brandId: z.string().optional(),
      }),
    ),
  });

  type FormValues = z.infer<typeof formValuesSchema>;

  return (
    <form onSubmit={handleSubmit(onSaveDeadlines)}>
      <table className="table-auto w-full border-collapse">
        <thead>
          <tr className="bg-gray-100">
            <th className="border px-4 py-2">
              {t("CRM.order-deadline.season")}
            </th>
            <th className="border px-4 py-2">
              {" "}
              {t("CRM.order-deadline.deadline")}
            </th>
          </tr>
        </thead>
        <tbody>
          {seasons.map((season, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">
                {season.season} {season.year}
              </td>
              <td className="border px-4 py-2">
                <div className="relative" key={`deadline-${index}`}>
                  <Controller
                    name={`deadlines.${index}.deadline`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomDatePicker
                        id={`date-${index}`}
                        value={value || null}
                        placeholder={
                          <div className="flex justify-center items-center gap-2">
                            <VscCalendar />
                            <span className="text-grey">
                              {t(
                                "Shared.order-deadline.selected-date-placeholder",
                              )}
                            </span>
                          </div>
                        }
                        minDate={new Date()}
                        onChange={(date) => {
                          onChange(date);
                        }}
                        widthClass="w-32" // to make sure the input width is same before and after selecting the date
                      />
                    )}
                  />
                </div>
                {errors.deadlines?.[index]?.deadline && (
                  <p className="text-xs italic text-primaryRed text-center pt-2">
                    {errors.deadlines[index].deadline?.message}
                  </p>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        className="whitespace-nowrap text-white hover:underline mt-4 flex w-full"
        theme="PRIMARY"
        justify="center"
        type="submit"
      >
        {t("Shared.order-deadline.save-deadlines")}
      </Button>
    </form>
  );
}

export default OrderDeadlineFormCRM;
