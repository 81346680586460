import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { Organization } from "@models/Organization";
import {
  AccountStatusEnum,
  organizationAccountSchema,
} from "@models/OrganizationAccount";
import { organizationContactSchema } from "@models/OrganizationContact";
import { organizationRepresentativeSchema } from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";

import axiosInstance from "../config";
import { getAPIQueryKey } from "../helper";

export namespace GetContactsEndpoint {
  export type Params = {
    organizationId: Organization["id"];
  };

  export const outputSchema = z.array(
    organizationContactSchema.extend({
      account: organizationAccountSchema
        .pick({
          id: true,
          name: true,
          city: true,
          countryCode: true,
          isKeyClient: true,
        })
        .extend({
          collectionInformationByAccounts: z.array(
            z.object({
              collectionId: z.string(),
              status: AccountStatusEnum,
            }),
          ),
        }),
      portfolios: z.array(
        portfolioSchema
          .pick({
            id: true,
            name: true,
            collectionId: true,
          })
          .extend({
            manager: organizationRepresentativeSchema.pick({
              firstName: true,
              lastName: true,
              role: true,
            }),
          }),
      ),
    }),
  );

  export type Output = z.infer<typeof outputSchema>;
  export type OutputItem = Output[number];

  export const path = ({ organizationId }: Params) =>
    `/organizations/${organizationId}/contacts`;

  export const call = (params: Params) =>
    axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data));

  export const queryKeys = (params: Params) => getAPIQueryKey(path(params));

  export function useHook(params: Params) {
    return useQuery({
      queryKey: queryKeys(params),
      queryFn: () => call(params),
    });
  }
}
