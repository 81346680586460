import React, { useState } from "react";

import { Portal } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { Field } from "react-spreadsheet-import/types/types";

import { Button } from "@components/ui/button";
import { accountSchema } from "@models/Account";
import { AccountStatusEnum } from "@models/OrganizationAccount";
import { GetOrganizationCollectionsEndpoint } from "@services/api/organization/get-collections";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { countriesOptions } from "@services/countries";
import { translateAccountType } from "@shared/helpers/translater";

export function useAccountImportFields(): Field<string>[] {
  const { t } = useTranslation();
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();
  const { data: collections = [] } = GetOrganizationCollectionsEndpoint.useHook(
    {
      organizationId,
    },
  );

  return [
    {
      label: t("SpreadsheetImporter.name.label"),
      key: "name",
      alternateMatches: [
        "Account name",
        "name",
        "company",
        "organization",
        "société",
        "entreprise",
        "nom",
      ],
      fieldType: {
        type: "input",
      },
      example: "Galeries Lafayette",
      validations: [
        {
          rule: "required",
          errorMessage: t("SpreadsheetImporter.name.required"),
          level: "error",
        },
      ],
    },
    {
      label: t("SpreadsheetImporter.type.label"),
      key: "type",
      alternateMatches: [
        "Account type",
        "type",
        "type d'entreprise",
        "type d'organisation",
      ],
      fieldType: {
        type: "select",
        options: Object.values(accountSchema.shape.type.Values).map((v) => ({
          label: translateAccountType(v),
          value: v,
        })),
      },
      example: Object.values(accountSchema.shape.type.Values).join(", "),
      validations: [
        {
          rule: "required",
          errorMessage: t("SpreadsheetImporter.type.required"),
          level: "error",
        },
        {
          rule: "regex",
          value: `(${Object.values(accountSchema.shape.type.Values).join(")|(")})`,
          errorMessage: t("SpreadsheetImporter.type.invalid"),
          level: "error",
        },
      ],
    },
    {
      label: t("SpreadsheetImporter.city.label"),
      key: "city",
      alternateMatches: ["Account main city", "city", "ville"],
      example: "Paris, Milan, New York",
      fieldType: {
        type: "input",
      },
    },
    {
      label: t("SpreadsheetImporter.country.label"),
      key: "country",
      alternateMatches: [
        "Account main country",
        "country",
        "pays",
        "country code",
      ],
      example: "FR, IT, US",
      fieldType: {
        type: "select",
        options: countriesOptions,
      },
    },
    ...collections.map(
      ({ brand: { name: brandName }, name, id }) =>
        ({
          label: t("SpreadsheetImporter.status.label-{{brandName}}-{{name}}", {
            brandName,
            name,
          }),
          key: id,
          alternateMatches: [name],
          example: "ACTIVE, PROSPECT, NOT_ACCESSIBLE",
          fieldType: {
            type: "select",
            options: Object.values(AccountStatusEnum.Values).map((status) => ({
              label: status,
              value: status,
            })),
          },
        }) satisfies Field<string>,
    ),
  ];
}

export default function SpreadsheetImporter({
  fields,
}: {
  fields: Field<string>[];
}) {
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = console.log;
  return (
    <>
      <Button
        className="rounded-full"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        Upload spreadsheet
      </Button>
      <Portal>
        <ReactSpreadsheetImport
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onSubmit={onSubmit}
          fields={fields}
          selectHeaderStepHook={async (headerValues, data) => {
            console.log(headerValues, data);
            return { headerValues, data };
          }}
        />
      </Portal>
    </>
  );
}
