import React from "react";

import { Navigate } from "react-router-dom";

import useApplicationContext from "@services/application/useApplicationContext";

export default function OnboardingDone({
  children,
}: Readonly<{
  children: React.JSX.Element;
}>) {
  const { organization } = useApplicationContext();

  if (!organization) {
    return <Navigate to="/onboarding/create-organization" />;
  }
  if (organization.onboardingStatus !== "DONE") {
    return <Navigate to="/onboarding/information" />;
  }

  return children;
}
