import { z } from "zod";

import { continentCodes } from "@services/continents";

export const continentCodeSchema = z.enum(continentCodes);
export type ContinentCode = z.infer<typeof continentCodeSchema>;
export const accountTypeSchema = z.enum([
  "BOUTIQUE",
  "DEPARTMENT",
  "FRANCHISE",
  "MULTIBRAND",
  "ONLINE",
]);
export type AccountType = z.infer<typeof accountTypeSchema>;

export const accountSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  type: accountTypeSchema,
  address: z.string().nullish(),
  city: z.string().nullish(),
  countryCode: z.string().nullish(),
  continentCode: continentCodeSchema.nullish(),
});
export type Account = z.infer<typeof accountSchema>;
