import React, { useState } from "react";

import { Portal } from "@headlessui/react";
import { useQueries } from "@tanstack/react-query";
import { format } from "date-fns";
import { t } from "i18next";
import { FaRegHourglassHalf } from "react-icons/fa6";
import { HiOutlinePlusCircle, HiOutlineUpload } from "react-icons/hi";
import {
  HiOutlineBuildingOffice2,
  HiOutlineUserCircle,
  HiOutlineUsers,
} from "react-icons/hi2";
import { MdOutlineUnfoldMore } from "react-icons/md";
import { useMatch, useNavigate } from "react-router-dom";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";

import { useCRMDrawerContext } from "@app/modules/crm/context/crm-drawers";
import { PageHeader, PageTitle } from "@components/data-display/Page";
import Tabs, { TabsProps } from "@components/data-display/Tabs";
import Modal, { useModal } from "@components/feedback/Modal";
import { useBreakpoint } from "@components/layout/Breakpoint";
import { Button } from "@components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu";
import { ShowroomSeason, ShowroomSeasonList } from "@models/types/enums";
import { useFetchAccountsCount } from "@services/api/old/accounts/fetch-accounts-count";
import { useFetchAccountsArchivedCount } from "@services/api/old/accounts/fetch-archived-count";
import { useFetchContactsArchivedCount } from "@services/api/old/contacts/fetch-archived-count";
import { useFetchContactsCount } from "@services/api/old/contacts/fetch-contacts-count";
import { GetOrderDeadline } from "@services/api/order-deadline/get-order-deadline";
import { useGetCompetitorsGroupsCount } from "@services/api/organization/get-competitors-groups-count";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";
import { AccessControl } from "@shared/components/access-control";
import {
  ACL_ADMINS_AGENTS_MANAGERS,
  ACL_ADMINS_MANAGERS,
  useUserRole,
} from "@shared/components/access-control/helpers";

import { useAccountImportFields } from "./AccountImporter";
import OrderDeadlineFormCRM from "./OrderDeadlineFormCRM";

interface UpcomingSeason {
  season: ShowroomSeason;
  year: number;
  brandId?: string | undefined;
}

interface UpcomingSeasonsWithDeadlines extends UpcomingSeason {
  orderDeadline?: Date;
}

// function to calculate the next four fashion show seasons based on today's date
export const getNextFourFashionShows = (
  currentDate: Date,
  brandId?: string,
) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // adjusted to represent the actual month (1 = January, 12 = December)

  interface FashionSeason {
    name: string;
    startMonth: number;
    endMonth: number;
  }

  const fashionSeasons: FashionSeason[] = [
    { name: ShowroomSeasonList[1], startMonth: 2, endMonth: 3 }, // AW
    { name: ShowroomSeasonList[2], startMonth: 4, endMonth: 5 }, // PRE_SS
    { name: ShowroomSeasonList[3], startMonth: 9, endMonth: 10 }, // SS
    { name: ShowroomSeasonList[0], startMonth: 12, endMonth: 1 }, // PRE_AW
  ];

  // store all the upcoming seasons
  const upcomingSeasons: UpcomingSeason[] = [];

  // find the upcoming season
  const findNextSeason = (referenceMonth: number) => {
    let currentSeason = fashionSeasons.find(
      (season) =>
        (referenceMonth >= season.startMonth &&
          referenceMonth <= season.endMonth) || // falls between start and end
        (season.startMonth > season.endMonth &&
          (referenceMonth >= season.startMonth ||
            referenceMonth <= season.endMonth)), // for seasons spanning year-end (e.g., dec-jan)
    );

    // if the current month is not in any season's range, find the next closest upcoming season
    if (!currentSeason) {
      [currentSeason] = fashionSeasons
        .filter((season) => season.startMonth >= referenceMonth) // filter seasons starting after current month
        .sort((a, b) => a.startMonth - b.startMonth); // find the closest future season

      // if no future season exists (meaning the rest are next year), get the earliest season
      if (!currentSeason) {
        [currentSeason] = fashionSeasons.sort(
          (a, b) => a.startMonth - b.startMonth,
        );
      }
    }

    return currentSeason;
  };

  let referenceMonth = currentMonth;
  let referenceYear = currentYear;

  while (upcomingSeasons.length < 4) {
    const currentSeason = findNextSeason(referenceMonth);

    // fashion weeks are made to showcase future seasonal collections, around 6 months in advance
    let monthsAhead = currentSeason.endMonth + 6;

    if (currentSeason.endMonth < currentSeason.startMonth) {
      // e.g. // PRE_AW -> automatically add one more year since it spans over the year-end (monthsAhead will be less than 12)
      referenceYear += 1;
    }
    if (monthsAhead > 12) {
      referenceYear += 1; // increment referenceYear by 1 if monthsAhead exceeds 12
      monthsAhead %= 12;
    }

    if (currentSeason) {
      const seasonDetails: UpcomingSeason = {
        season: currentSeason.name as ShowroomSeason,
        year:
          currentSeason.name === ShowroomSeasonList[2] // this condition is needed because PRE_SS's endMonth + 6 will always be less than 12
            ? referenceYear + 1 // therefore, it won't change the year, but PRE_SS should always refer to the next year
            : referenceYear,
      };

      if (brandId) {
        seasonDetails.brandId = brandId;
      }

      upcomingSeasons.push(seasonDetails);

      referenceMonth = currentSeason.endMonth + 1; //  use the month after the endMonth as the new reference
    }
  }

  return upcomingSeasons;
};

export default function CRMHeader() {
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const { isAdmin, isManager } = useUserRole();

  const navigate = useNavigate();
  const modalState = useModal();
  const match = useMatch("/crm/*");

  const currentFile =
    match && match?.params ? Object.values(match!.params)[0] : "";

  const { data: accountsCount } = useFetchAccountsCount(organizationId);
  const { data: accountsArchivedCount } =
    useFetchAccountsArchivedCount(organizationId);
  const { data: contactsCount } = useFetchContactsCount(organizationId);
  const { data: contactsArchivedCount } =
    useFetchContactsArchivedCount(organizationId);
  const { data: competitorsGroupsCount } = useGetCompetitorsGroupsCount(
    {
      organizationId,
    },
    {
      enabled: isAdmin || isManager,
    },
  );

  const crmContext = useCRMDrawerContext();

  const crmTabs: TabsProps["items"] = [
    {
      label: t("CRM.header.tabs.accounts"),
      tab: "accounts",
      count: accountsCount?.count,
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    },
    {
      label: t("CRM.header.tabs.contacts"),
      tab: "contacts",
      count: contactsCount?.count,
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    },
    {
      label: t("CRM.header.tabs.competitors-groups"),
      tab: "competitors",
      count: competitorsGroupsCount?.count,
      aclRoles: ACL_ADMINS_MANAGERS,
    },
    {
      label: t("CRM.header.tabs.accounts-archived"),
      tab: "archived-accounts",
      count: accountsArchivedCount?.count,
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    },
    {
      label: t("CRM.header.tabs.contacts-archived"),
      tab: "archived-contacts",
      count: contactsArchivedCount?.count,
      aclRoles: ACL_ADMINS_AGENTS_MANAGERS,
    },
  ];

  const currentTab = crmTabs.find((item) => item.tab === currentFile)?.tab;

  //* order deadline
  const { organization } = useOrganizationAppContext();
  const brandId = organization.brand?.id;

  const upcomingSeasons = getNextFourFashionShows(new Date(), brandId); // set the today's date as a starting point

  const queryArray = Array.from(upcomingSeasons).map((fashionSeason) => ({
    queryKey: GetOrderDeadline.getQueryKeys({
      brandId: fashionSeason.brandId,
      season: fashionSeason.season,
      year: fashionSeason.year,
    }),
    queryFn: () =>
      GetOrderDeadline.call({
        brandId: fashionSeason.brandId,
        season: fashionSeason.season,
        year: fashionSeason.year,
      }),
  }));

  // fetch order deadline data
  const { data: orderDeadlines } = useQueries({
    queries: queryArray,
    combine: (queries) => ({
      data: queries.flatMap((q) => q.data || []),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });

  // upcoming seasons with their corresponding season, year, brandId and order deadlines
  const upcomingSeasonsWithDeadlines: UpcomingSeasonsWithDeadlines[] =
    upcomingSeasons.map((season) => {
      const matchingDeadline = orderDeadlines.find(
        (deadline) =>
          deadline.season === season.season &&
          deadline.year === season.year &&
          deadline.brandId === season.brandId,
      )?.deadline;

      return {
        ...season,
        orderDeadline: matchingDeadline,
      };
    });
  const accountFields = useAccountImportFields();

  const nextOrderDeadline = upcomingSeasonsWithDeadlines.find(
    (s) => !!s.orderDeadline,
  );

  const isDesktop = useBreakpoint("md");

  return (
    <>
      <PageHeader>
        <PageTitle>{t("CRM.header.title")}</PageTitle>
        <div className="px-4 flex flex-col md:flex-row gap-2">
          {organization.type === "BRAND" && (
            <div>
              <Button
                size={isDesktop ? "lg" : "sm"}
                className="rounded-full"
                variant={nextOrderDeadline ? "ghost" : "default"}
                onClick={modalState.open}
              >
                {!nextOrderDeadline ? (
                  t("Shared.order-deadline.add-order-deadlines")
                ) : (
                  <>
                    <div className="text-primaryDarkGrey flex items-center space-x-1">
                      <FaRegHourglassHalf className="my-auto w-2.5 mr-1" />
                      <p>
                        {t("Shared.order-deadline.order-deadline-season", {
                          season: nextOrderDeadline.season,
                          year: nextOrderDeadline.year,
                        })}
                      </p>
                    </div>
                    <p className="flex items-center ">
                      {format(
                        nextOrderDeadline.orderDeadline as Date,
                        "MMM d, yyyy",
                      )}
                    </p>
                    <MdOutlineUnfoldMore />
                  </>
                )}
              </Button>
              <Modal
                title={t("Shared.order-deadline.order-deadlines")}
                state={modalState}
                centeredTitle
                padding="p-6"
                aria-label={t("Shared.order-deadline.order-deadlines")}
              >
                <OrderDeadlineFormCRM
                  seasons={upcomingSeasonsWithDeadlines}
                  closeModal={modalState.close}
                />
              </Modal>
            </div>
          )}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className="rounded-full" size="lg">
                <span>{t("CRM.header.add-new")}</span>
                <HiOutlinePlusCircle className="w-6 h-6" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <AccessControl roles={ACL_ADMINS_AGENTS_MANAGERS}>
                <DropdownMenuItem
                  onClick={() => {
                    navigate("/crm/accounts");
                    crmContext.openAccountUpsertDrawer();
                  }}
                >
                  <HiOutlineBuildingOffice2 className="w-6 h-6" />
                  {t("CRM.header.add-new-account")}
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => {
                    navigate("/crm/contacts");
                    crmContext.openContactUpsertDrawer();
                  }}
                >
                  <HiOutlineUserCircle className="w-6 h-6" />
                  {t("CRM.header.add-new-contact")}
                </DropdownMenuItem>
              </AccessControl>
              <AccessControl roles={ACL_ADMINS_MANAGERS}>
                <DropdownMenuItem
                  onClick={() => {
                    navigate("/crm/competitors");
                    crmContext.openCompetitorsGroupUpsertDrawer();
                  }}
                >
                  <HiOutlineUsers className="w-6 h-6" />
                  {t("CRM.header.add-new-competitors-group")}
                </DropdownMenuItem>
              </AccessControl>
              <AccessControl roles={ACL_ADMINS_AGENTS_MANAGERS}>
                <DropdownMenuItem
                  onClick={() => {
                    setUploadModal(true);
                  }}
                >
                  <HiOutlineUpload className="w-6 h-6" />
                  {t("CRM.header.upload-crm-spreadsheet")}
                </DropdownMenuItem>
              </AccessControl>
            </DropdownMenuContent>
          </DropdownMenu>
          <Portal>
            <ReactSpreadsheetImport
              isOpen={uploadModal}
              onClose={() => setUploadModal(false)}
              onSubmit={console.log}
              fields={accountFields}
              selectHeaderStepHook={async (headerValues, data) => {
                console.log(headerValues, data);
                return { headerValues, data };
              }}
            />
          </Portal>
        </div>
      </PageHeader>
      <Tabs
        aria-label="CRM-tabs"
        className="mt-6 mb-4 border-b"
        items={crmTabs}
        tab={currentTab}
        handleClick={(item) => {
          navigate(`/crm/${item.tab}`);
        }}
      />
    </>
  );
}
