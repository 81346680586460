import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { collectionSchema } from "@models/Collection";
import { organizationContactSchema } from "@models/OrganizationContact";
import { specialRequestSchema } from "@models/SpecialRequest";
import { zonedDateSerializingSchema } from "@models/types/Dates";
import {
  appointmentFormatSchema,
  appointmentTypeSchema,
  virtualMeetingAppWithANYSchema,
} from "@models/types/enums";
import axiosInstance from "@services/api/config";

export namespace BookingUpsertSpecialRequestEndpoint {
  export const inputSerializingSchema = z.object({
    invitationId: z.string().uuid(),
    showroomId: z.string().uuid(),
    collectionId: z.string().uuid().nullable(),
    id: z.string().uuid().optional(),
    startTime: zonedDateSerializingSchema,
    endTime: zonedDateSerializingSchema,
    format: appointmentFormatSchema,
    virtualMeetingApp: virtualMeetingAppWithANYSchema.nullable(),
    type: appointmentTypeSchema,
    languages: z.array(z.string()),
  });
  export type Input = z.input<typeof inputSerializingSchema>;

  export const outputHydratingSchema = specialRequestSchema
    .pick({
      id: true,
      showroomId: true,
      startTime: true,
      endTime: true,
      format: true,
      invitationId: true,
      languages: true,
      status: true,
      type: true,
      virtualMeetingApp: true,
    })
    .extend({
      attendees: z.array(
        organizationContactSchema.pick({
          email: true,
          firstName: true,
          id: true,
          lastName: true,
          phoneNumber: true,
        }),
      ),
      collection: collectionSchema.pick({ id: true, name: true }),
    });
  export type Output = z.output<typeof outputHydratingSchema>;

  interface HookParams {
    invitationId: string;
    specialRequestId?: string;
  }

  interface MutationParams {
    data: Input;
  }

  type Params = HookParams & MutationParams;

  export const path = ({ invitationId, specialRequestId }: Params) =>
    specialRequestId
      ? `/bookings/${invitationId}/special-requests/${specialRequestId}`
      : `/bookings/${invitationId}/special-requests`;

  export const call = (params: Params) =>
    params.specialRequestId
      ? axiosInstance
          .put(path(params), inputSerializingSchema.parse(params.data))
          .then((res) => outputHydratingSchema.parse(res.data))
      : axiosInstance
          .post(path(params), inputSerializingSchema.parse(params.data))
          .then((res) => outputHydratingSchema.parse(res.data));

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        // @todo add queries to invalidate
        queryClient.invalidateQueries({
          queryKey: [],
        });
      },
    });
  }
}
