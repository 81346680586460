import React from "react";

import InlineTableFilterSelect from "@components/data-entry/inline-filter/select";
import InlineTableFilterText from "@components/data-entry/inline-filter/text";
import InlineTableFilterWrapper from "@components/data-entry/inline-filter/wrapper";
import { usePrefixedTranslation } from "@helpers/Translation";
import { AccountStatus } from "@models/OrganizationAccount";
import { translateAccountStatus } from "@shared/helpers/translater";

type FilterValues = {
  accountStatus: AccountStatus | "any";
  collectionId: string | "any";
};

type Props = {
  onChange: (values: FilterValues) => void;
  values?: FilterValues;
  collections: {
    brand: {
      name: string;
    };
    id: string;
    name: string;
  }[];
};

export default function AccountStatusFilter({
  collections,
  onChange,
  values = {
    accountStatus: "any",
    collectionId: "any",
  },
}: Props) {
  const { pt, t } = usePrefixedTranslation("Invitation.AccountStatusFilter");

  const handleValueChange =
    (key: keyof FilterValues) => (value: FilterValues[typeof key]) => {
      const newValues: FilterValues = {
        ...values,
        [key]: value,
      };
      onChange(newValues);
    };

  return (
    <InlineTableFilterWrapper>
      <InlineTableFilterText>{pt("account-status-is")}</InlineTableFilterText>
      <InlineTableFilterSelect<FilterValues["accountStatus"]>
        value={values.accountStatus}
        options={[
          {
            label: pt("any-account-status"),
            value: "any",
          },
          {
            label: t(translateAccountStatus("ACTIVE")),
            value: "ACTIVE",
          },
          {
            label: t(translateAccountStatus("PROSPECT")),
            value: "PROSPECT",
          },
        ]}
        onChange={handleValueChange("accountStatus")}
      />
      <InlineTableFilterText>{pt("for-collection")}</InlineTableFilterText>
      <InlineTableFilterSelect<FilterValues["collectionId"]>
        value={values.collectionId}
        options={[
          {
            label: pt("any-collections"),
            value: "any",
          },
          ...collections.map((c) => ({
            label: `${c.brand.name} - ${c.name}`,
            value: c.id,
          })),
        ]}
        onChange={handleValueChange("collectionId")}
      />
    </InlineTableFilterWrapper>
  );
}
