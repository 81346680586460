import React, { useRef } from "react";

import { useTranslation } from "react-i18next";

import ReactSelectWrapper, {
  ReactSelectWrapperProps,
  SelectOptionBase,
} from "@components/data-entry/wrapper/ReactSelect";

export const MULTI_SELECT_LANG = {
  SELECT_ALL: "Common.select-all",
};

function useSelectAll({
  options = [],
  value = [],
  onChange,
  isOptionDisabled = () => false,
}: {
  options: MultiSelectProps["options"];
  value: MultiSelectProps["value"];
  onChange: MultiSelectProps["onChange"];
  isOptionDisabled: MultiSelectProps["isOptionDisabled"];
}) {
  const valueRef = useRef(value);
  valueRef.current = value;

  const { t } = useTranslation();

  // flatten the array of options to get all the possible values
  const actualOptions = options
    .flatMap((og) => ("options" in og ? og.options : og))
    .filter((o) => !isOptionDisabled(o, value));

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: t("Common.select-all"),
  };

  const isSelectAllSelected = () =>
    valueRef.current.length === actualOptions.length;

  const isOptionSelected = (option: SelectOptionBase) =>
    !isOptionDisabled(option, valueRef.current) &&
    (valueRef.current.some(({ value: v }) => v === option.value) ||
      isSelectAllSelected());

  const getOptions = () =>
    options.length > 2 ? [selectAllOption, ...options] : options;

  const handleChange: ReactSelectWrapperProps<true>["onChange"] = (
    newValue,
    actionMeta,
  ) => {
    const { action, option, removedValue } = actionMeta;
    if (
      option &&
      action === "select-option" &&
      option.value === selectAllOption.value
    ) {
      onChange(actualOptions);
    } else if (
      (option &&
        action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      onChange([]);
    } else if (
      option &&
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      onChange(actualOptions.filter(({ value: v }) => v !== option.value));
    } else {
      onChange((newValue as SelectOptionBase[]) || []);
    }
  };

  return {
    onChange: handleChange,
    options: getOptions(),
    isOptionSelected,
  };
}

export type MultiSelectProps = ReactSelectWrapperProps<true> & {
  disabled?: boolean;
  value?: SelectOptionBase[];
  onChange: (options: SelectOptionBase[]) => void;
};

export default function MultiSelect(props: MultiSelectProps) {
  const {
    disabled,
    value = [],
    isOptionDisabled,
    onChange: onChangeProp,
    options: optionsProp = [],
  } = props;

  const { isOptionSelected, onChange, options } = useSelectAll({
    options: optionsProp,
    value,
    onChange: onChangeProp,
    isOptionDisabled,
  });

  return (
    <ReactSelectWrapper
      isMulti
      {...props}
      closeMenuOnSelect={false}
      onChange={onChange}
      value={value}
      isOptionSelected={isOptionSelected}
      options={options}
      isDisabled={disabled}
    />
  );
}
