import React, { useEffect, useState } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import CalloutBox from "@components/feedback/CalloutBox";
import Loading from "@components/feedback/Loading";
import { OnboardingStep } from "@models/old/Organization";
import OnboardingBreadcrumb from "@onboarding/components/Breadcrumb";
import OnboardingFormContainer from "@onboarding/components/OnboardingFormContainer";
import OrganizationAPI from "@services/api/old/organization/OrganizationAPI";
import useApplicationContext from "@services/application/useApplicationContext";
import { OrganizationInformationFormData } from "@shared/organization/organization-information-form";

export default function OnboardingOrganizationConfirmationPage() {
  const { organization } = useApplicationContext();
  const navigate = useNavigate();

  const organizationId = organization?.id;

  const [onboardingInformation, setOnboardingInformation] = useState<
    OrganizationInformationFormData | undefined
  >(undefined);

  useEffect(() => {
    if (organizationId !== undefined) {
      OrganizationAPI.getOrganizationInformation(organizationId).then(
        (organizationInformation) => {
          setOnboardingInformation({
            ...organizationInformation,
            bannerFile: null,
          });
        },
      );
    }
  }, [organizationId]);

  const onSubmitOnboardingForm = async (
    organizationOnboardingInformation: OrganizationInformationFormData,
  ) =>
    OrganizationAPI.updateOrganizationInformation(
      organizationId || "",
      organizationOnboardingInformation,
    ).then((org) => {
      toast.success(`Organization ${org.name} updated`);
      navigate(`/onboarding/showroom-structure`);
    });

  if (!organizationId) {
    return <Navigate to="/onboarding/create-organization" />;
  }

  return (
    <div className="flex justify-center w-screen">
      <div className="flex flex-col justify-between w-full h-screen">
        <header className="my-12 px-10 h-fit">
          <OnboardingBreadcrumb
            currentStep={OnboardingStep.ORGANIZATION_CONFIRMATION}
          />
        </header>
        {onboardingInformation ? (
          <div className="flex flex-col items-start grow overflow-x-hidden">
            <div className="px-10">
              <h1 className="heading-1">{onboardingInformation.name}</h1>
              <CalloutBox className="mt-2 w-full lg:max-w-[28rem]">
                Please check the following information to continue
              </CalloutBox>
            </div>
            <OnboardingFormContainer
              existing={onboardingInformation}
              onSubmit={onSubmitOnboardingForm}
            />
          </div>
        ) : (
          <Loading type="screen" />
        )}
      </div>
    </div>
  );
}
