import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { ShowroomSeasonList } from "@models/types/enums";
import axiosInstance from "@services/api/config";

import { GetBuyerBrands } from "../brands/GetBuyerBrands";

export namespace CreateOrderDeadlineRequest {
  export const inputSerializingSchema = z.object({
    brandId: z.string().uuid(),
    season: z.enum(ShowroomSeasonList),
    year: z.number(),
  });
  export type Input = z.input<typeof inputSerializingSchema>;

  interface MutationParams {
    data: Input;
  }

  type Params = MutationParams;

  export const path = () => `/buyer/brands/request-order-deadline`;

  export const call = (params: Params) =>
    axiosInstance.post(path(), inputSerializingSchema.parse(params.data));

  export function useHook() {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) => call(mutationParams),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: GetBuyerBrands.getQueryKeys(),
        });
      },
    });
  }
}
