import { useEffect } from "react";

import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import deepmerge from "deepmerge";
import { concat, pipe, sortBy } from "remeda";
import { z } from "zod";

import { appointmentHydratingSchema } from "@models/Appointment";
import { brandSchema } from "@models/Brand";
import { collectionSchema } from "@models/Collection";
import { meetingReportSchema } from "@models/MeetingReport";
import { Organization } from "@models/Organization";
import { organizationAccountSchema } from "@models/OrganizationAccount";
import { organizationContactSchema } from "@models/OrganizationContact";
import {
  organizationRepresentativeSchema,
  representativeSchema,
  virtualMeetingAppSchema,
} from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import { Showroom, showroomSchema } from "@models/Showroom";
import {
  AccountAppointmentTypeList,
  AppointmentTypeList,
  BusyAppointmentTypeList,
  VirtualMeetingAppsList,
} from "@models/types/enums";
import { UpsertAppointmentEndpoint } from "@services/api/appointments/upsert-appointment";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

import { GetAppointment } from "../appointments/get-appointment";

export namespace GetDailyCalendarEndpoint {
  /**
   * SCHEMAS
   */
  const baseAppointment = appointmentHydratingSchema.pick({
    startTime: true,
    title: true,
    type: true,
    virtualMeetingApp: true,
    id: true,
    format: true,
    accountOtb: true,
    endTime: true,
    warnings: true,
    status: true,
  });
  export const accountAppointmentSchema = baseAppointment.extend({
    title: z.null(),
    format: appointmentHydratingSchema.shape.format.unwrap(),
    type: z.enum(AccountAppointmentTypeList),
    accountOtb: z.number().nullable(),
    collectionInterests: z
      .array(
        collectionSchema
          .pick({ id: true, name: true })
          .extend({ brand: brandSchema.pick({ id: true, name: true }) }),
      )
      .optional()
      .default([]),
    showroom: showroomSchema.pick({
      id: true,
      formattedAddress: true,
      timezone: true,
      city: true,
      countryCode: true,
    }),
    collection: collectionSchema
      .pick({ id: true, name: true })
      .extend({
        brand: brandSchema.pick({ id: true, name: true }),
      })
      .nullable(),
    account: organizationAccountSchema.pick({
      id: true,
      name: true,
      status: true,
      city: true,
      countryCode: true,
      isKeyClient: true,
    }),
    attendees: z.array(
      organizationContactSchema.pick({
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        markets: true,
        position: true,
        phoneNumber: true,
      }),
    ),
    seller: representativeSchema
      .pick({
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        languages: true,
      })
      .extend({
        virtualMeetingAppLinks: z
          .record(z.enum(VirtualMeetingAppsList), z.string().optional())
          .nullable(),
      }),
    portfolios: z.array(
      portfolioSchema
        .pick({ id: true, name: true, color: true, collectionId: true })
        .extend({
          manager: organizationRepresentativeSchema.pick({
            id: true,
            firstName: true,
            lastName: true,
            role: true,
          }),
          sellers: z.array(
            organizationRepresentativeSchema.pick({
              id: true,
              firstName: true,
              lastName: true,
              role: true,
              virtualMeetingApps: true,
            }),
          ),
        }),
    ),
    meetingReport: meetingReportSchema
      .pick({ otb: true, actualBudget: true, notes: true })
      .nullable(),
  });

  export const busyAppointmentSchema = accountAppointmentSchema.extend({
    type: z.enum(BusyAppointmentTypeList),
    account: z.null(),
    collection: z.null(),
    format: z.null(),
    portfolios: z.array(z.never()),
    attendees: z.array(z.never()),
    title: z.string(),
  });

  export const appointmentSchema = z.discriminatedUnion("type", [
    busyAppointmentSchema,
    accountAppointmentSchema,
  ]);

  export type AccountAppointment = z.infer<typeof accountAppointmentSchema>;
  export type BusyAppointment = z.infer<typeof busyAppointmentSchema>;
  export type AppointmentJSON = z.input<typeof appointmentSchema>;
  export type Appointment = z.infer<typeof appointmentSchema>;

  export const checkIsBusyAppointment = (a: {
    type: Appointment["type"];
  }): a is z.infer<typeof busyAppointmentSchema> => a.type === "BUSY";

  export const checkIsAccountAppointment = (a: {
    account: any;
  }): a is z.infer<typeof accountAppointmentSchema> => a.account !== null;

  export const outputItemSchema = representativeSchema
    .pick({
      id: true,
      firstName: true,
      lastName: true,
      languages: true,
    })
    .extend({
      virtualMeetingApps: virtualMeetingAppSchema,
      appointmentTypes: z.array(z.enum(AppointmentTypeList)),
      appointments: z.array(appointmentSchema),
    });

  export type OutputItem = z.infer<typeof outputItemSchema>;
  export type Output = Array<OutputItem>;

  export const path = ({ organizationId, showroomId, dayAsString }: Params) =>
    `/organizations/${organizationId}/showrooms/${showroomId}/calendar/daily/${dayAsString}`;

  export const call = (params: Params) =>
    axiosInstance
      .get<Output>(path(params))
      .then((r) => r.data.map((d) => outputItemSchema.parse(d)));

  export interface Params {
    organizationId: Organization["id"];
    showroomId: Showroom["id"] | undefined;
    dayAsString: string;
  }

  export const queryKeys = (params: Params) => getAPIQueryKey(path(params));

  export function query(params: Params): UseQueryOptions<Output, Error> {
    return {
      queryKey: queryKeys(params),
      queryFn: () => call(params),
      enabled: !!params.showroomId,
      refetchInterval: 1000 * 5,
    };
  }

  export function useHook(params: Params) {
    const calendarQuery = useQuery(query(params));
    const queryClient = useQueryClient();

    useEffect(() => {
      if (calendarQuery.status === "success") {
        calendarQuery.data.forEach((seller) => {
          seller.appointments.forEach((appt) => {
            // save the appointment data in cache
            queryClient.setQueryData(
              GetAppointment.queryKeys({
                appointmentId: appt.id,
                organizationId: params.organizationId,
              }),
              appt,
            );
          });
        });
      }
    }, [calendarQuery.status]);

    return calendarQuery;
  }

  export const removeAppointmentFromQueryData = (
    dailyCalendar: Output,
    appointmentId: string,
  ): Output =>
    dailyCalendar.map((organizationMember) => {
      let { appointments } = organizationMember;
      appointments = appointments.filter(
        (appointment) => appointment.id !== appointmentId,
      );
      return {
        ...organizationMember,
        appointments,
      };
    });

  const updateAppointmentInDailyCalendar = (
    dailyCalendar: Output,
    appointment: Appointment,
  ): Output =>
    removeAppointmentFromQueryData(dailyCalendar, appointment.id).map(
      (organizationMember) => {
        let { appointments } = organizationMember;
        if (
          "seller" in appointment &&
          organizationMember.id === appointment.seller.id
        ) {
          appointments = pipe(
            appointments,
            concat([appointment]),
            sortBy((a) => a.startTime.realDate),
          );
        }
        return {
          ...organizationMember,
          appointments,
        };
      },
    );

  export const retrieveAppointment = (
    dailyCalendar: Output,
    appointmentId: string,
  ): Appointment | undefined => {
    let appointment;
    dailyCalendar.forEach((seller) => {
      seller.appointments.forEach((a) => {
        if (a.id === appointmentId) {
          appointment = a;
        }
      });
    });
    return appointment;
  };

  export function useInvalidate() {
    const queryClient = useQueryClient();

    return (params: Params) =>
      queryClient.invalidateQueries({ queryKey: queryKeys(params) });
  }

  /**
   * OPTIMISTIC UPDATE
   */
  export function useOptimisticUpdate() {
    const queryClient = useQueryClient();

    return {
      revert: async (params: Params, data: Output) => {
        queryClient.setQueryData(queryKeys(params), data);
      },
      update: async (
        params: Params,
        newAppointment: UpsertAppointmentEndpoint.Input,
      ) => {
        const updateCache = <T extends {}, U extends {}>(
          oldValue: T,
          newValue: U,
          oldCalendar: Output,
          queryKey: string[],
        ) => {
          const mergedAppointment = deepmerge(oldValue, newValue, {
            clone: true,
            customMerge: (key) => {
              // avoids serializing ZonedDate objects when merging
              if (["startTime", "endTime"].includes(key)) {
                return (source, dest) => dest;
              }
              // replaces arrays only when set
              if (["attendees", "portfolios", "warnings"].includes(key)) {
                return (source, dest) => (dest.length === 0 ? dest : source);
              }
              return undefined;
            },
          });

          const newCalendar = updateAppointmentInDailyCalendar(
            oldCalendar,
            mergedAppointment as Appointment,
          );

          queryClient.setQueryData(queryKey, newCalendar);
        };

        const queryKey = GetDailyCalendarEndpoint.queryKeys(params);

        await queryClient.cancelQueries({ queryKey });
        const oldCalendar = queryClient.getQueryData<Output>(queryKey);
        if (!oldCalendar || !newAppointment.id)
          throw new Error("optimistic update failed");
        const oldAppointment = retrieveAppointment(
          oldCalendar,
          newAppointment.id,
        );

        if (!oldAppointment) throw new Error("optimistic update failed");

        // old and new appointment have to be of the same type
        if (
          !checkIsBusyAppointment(oldAppointment) &&
          !checkIsBusyAppointment(newAppointment)
        ) {
          updateCache(oldAppointment, newAppointment, oldCalendar, queryKey);
        } else if (
          checkIsBusyAppointment(oldAppointment) &&
          checkIsBusyAppointment(newAppointment)
        ) {
          updateCache(oldAppointment, newAppointment, oldCalendar, queryKey);
        } else {
          console.error(
            `optimistic update for appointment ${oldAppointment.id} failed because its type changed`,
          );
        }

        return oldCalendar;
      },
    };
  }
}
