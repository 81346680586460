import React from "react";

import { useTranslation } from "react-i18next";
import { Outlet, matchPath, useLocation, useNavigate } from "react-router-dom";

import { PageHeader, PageTitle } from "@components/data-display/Page";
import Tabs, { TabsProps } from "@components/data-display/Tabs";

function OrganizationProfileLayout() {
  const { t } = useTranslation();
  const organizationProfileTabs: TabsProps["items"] = [
    {
      label: t("OrganizationProfile.tabs.information"),
      tab: "/organization-profile/information",
    },
    {
      label: t("OrganizationProfile.tabs.collections"),
      tab: "/organization-profile/collections",
    },
    {
      label: t("OrganizationProfile.tabs.portfolios"),
      tab: "/organization-profile/portfolios",
    },
  ];
  const navigate = useNavigate();
  const url = useLocation();

  return (
    <div className="h-full">
      <PageHeader>
        <PageTitle>{t("OrganizationProfileLayout.title")}</PageTitle>
      </PageHeader>
      <Tabs
        aria-label="organization-profile-tabs"
        className="border-b border-primaryDarkGrey sticky top-0 right-0 bg-white z-header"
        items={organizationProfileTabs}
        tab={url.pathname}
        handleClick={(item) => {
          navigate(`${item.tab}`);
        }}
        isTabActive={(item, currentTab) =>
          matchPath(`${item.tab}`, `${currentTab}`) !== null
        }
      />
      <Outlet />
    </div>
  );
}

export default OrganizationProfileLayout;
