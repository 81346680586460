import { generateMock } from "@anatine/zod-mock";
import { UseQueryOptions } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { z } from "zod";

import LogService from "@services/log/service";

export type Params<
  Required extends any[],
  Optional extends Record<string, any> | never = never,
> = Required | (Optional extends never ? never : [...Required, Optional]);

export type PathFunction<P extends any[]> = (...params: P) => string;
export type AxiosFunction<P extends any[], T> = (...params: P) => Promise<T>;
export class Endpoint<P extends any[], T> {
  pathFn;

  axiosFn;

  queryKeys;

  constructor(
    pathFn: PathFunction<P>,
    axiosFn: AxiosFunction<P, T>,
    queryKeys: string[],
  ) {
    this.pathFn = pathFn;
    this.axiosFn = axiosFn;
    this.queryKeys = queryKeys;
  }

  path(...params: P): string {
    return this.pathFn(...params);
  }

  query(...params: P): UseQueryOptions<T> {
    return {
      queryKey: [...this.queryKeys, ...params],
      queryFn: () => this.axiosFn(...params),
    };
  }
}

export function handleParsingError<I, O>(
  outputSchema: Zod.Schema<O, z.ZodTypeDef, any>,
  data: I,
  errorMessage: string,
) {
  const parsedData = outputSchema.safeParse(data);
  if (parsedData.success) {
    return parsedData.data;
  }

  toast.error(errorMessage);
  LogService.error(parsedData.error.issues);
  return Promise.reject(new Error("Failed to parse data"));
}

export function developmentMock<Schema extends Zod.Schema>(
  outputSchema: Schema,
): Promise<z.infer<Schema>> {
  if (process.env.NODE_ENV === "development") {
    return Promise.resolve(generateMock(outputSchema));
  }
  return Promise.reject(new Error("Failed to fetch buyer invitations"));
}

export function getAPIQueryKey(path: string): string[] {
  return path.split("/");
}

/**
 * This interface describes the available options on some API query hooks
 * We use a custom type instead of using UseQueryOptions because typescript makes it hard
 */
export interface CustomQueryOptions {
  enabled?: boolean;
}
