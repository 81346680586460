import { z } from "zod";

import { AccountStatusEnum } from "./OrganizationAccount";

export const CollectionInformationByAccountSchema = z.object({
  collectionId: z.string(),
  accountId: z.string(),
  status: AccountStatusEnum,
});

export type CollectionInformationByAccount = z.infer<
  typeof CollectionInformationByAccountSchema
>;
