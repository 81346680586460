import React, { useState } from "react";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Button from "@components/data-entry/Button";
import Modal, { useModal } from "@components/feedback/Modal";
import { AppointmentTypeEnum, InvitationStatusEnum } from "@models/types/enums";

import { BlueRow } from "../../components/data-display/BlueRow";
import countries from "../../services/countries";
import BookedAppointmentCardContainer from "./BookedAppointmentCardContainer";
import DisplayOrderDeadlineModal from "./DisplayOrderDeadlineModal";
import DisplayOrderDeadlinePerInvitation from "./DisplayOrderDeadlinePerInvitation";

interface Appointment {
  id: string;
  type: AppointmentTypeEnum;
}

interface Account {
  id: string;
  name: string;
}

interface Organization {
  id: string;
  name: string;
}

interface OpeningDays {
  day: Date;
  keyAccountsOpeningHour: Date | null;
  keyAccountsClosingHour: Date | null;
  customOpeningHour: Date | null;
  customClosingHour: Date | null;
}

interface Brand {
  id: string;
  name: string;
}

interface Collection {
  id: string;
  name: string;
  brand: Brand;
}

interface Showroom {
  id: string;
  city: string;
  countryCode: string;
  season: string;
  year: number;
  openingDays: OpeningDays[];
  collections?: Collection[] | undefined;
}

interface Contact {
  id: string;
  account: Account;
}

export interface BuyerInvitation {
  id: string;
  invitationId: string;
  invitationStatus: InvitationStatusEnum;
  showroom: Showroom;
  appointments: Appointment[];
  contact: Contact;
  organization: Organization;
}

export interface BuyerInvitationProps {
  buyerInvitation: BuyerInvitation;
}

// display the status for the "status" collumn
function getStatusDisplay(status: string) {
  if (status === "INVITED" || status === "FOLLOWED_UP") {
    return "Invited";
  }

  if (status === "BOOKED" || status === "BOOKED_BY_ORGANIZATION") {
    return "Booked";
  }

  if (status === "CANCELLED" || status === "CANCELLED_BY_ORGANIZATION") {
    return "Cancelled";
  }

  if (status === "JOKER" || status === "FAILED" || status === "NOT_INVITED") {
    return null;
  }

  return null;
}

// render buttons in the "action" collumn based on the status
function renderButtons(
  status: string,
  id: string,
  invitationId: string,
  t: Function,
  navigate: Function,
) {
  if (status === "INVITED" || status === "FOLLOWED_UP") {
    return (
      <Button
        theme="PRIMARY"
        className="w-40 items-center justify-center"
        onClick={() => navigate(`/booking/${invitationId}`)}
        aria-label={t("BuyerAccount.buyer-invitations.buttons.book")}
      >
        {t("BuyerAccount.buyer-invitations.buttons.book")}
      </Button>
    );
  }

  if (status === "BOOKED" || status === "BOOKED_BY_ORGANIZATION") {
    return (
      <Button
        theme="SECONDARY"
        className="w-40 items-center justify-center"
        onClick={() => navigate(`/booking/${invitationId}`)}
        aria-label={t("BuyerAccount.buyer-invitations.buttons.edit")}
      >
        {t("BuyerAccount.buyer-invitations.buttons.edit")}
      </Button>
    );
  }

  if (status === "CANCELLED" || status === "CANCELLED_BY_ORGANIZATION") {
    return (
      <Button
        theme="PRIMARY"
        className="w-40 items-center justify-center"
        onClick={() => navigate(`/booking/${invitationId}`)}
        aria-label={t("BuyerAccount.buyer-invitations.buttons.book-again")}
      >
        {t("BuyerAccount.buyer-invitations.buttons.book-again")}
      </Button>
    );
  }

  // for JOKER, FAILED, NOT_INVITED - don't display anything
  return null;
}

function BuyerInvitationRowsDisplay({ buyerInvitation }: BuyerInvitationProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modalState = useModal();
  const [modalContent, setModalContent] = useState<
    "deadlines" | "appointments"
  >("deadlines");

  const appointmentCount = buyerInvitation.appointments.length;

  let uniqueBrands: Map<string, { brandName: string; collectionName: string }>;
  let hasMultipleBrands: boolean = false;
  let hasUniqueBrand: boolean = false;
  let uniqueBrandInfo:
    | { id: string; name: string; collectionName: string }
    | undefined;
  let hasBuyingAppointment: boolean = false;

  // Check if at least one appointment from the invitation is a BUYING_APPOINTMENT,
  // to know whether the order deadline should be displayed or not
  hasBuyingAppointment = buyerInvitation.appointments.some(
    (appointment) =>
      appointment.type === AppointmentTypeEnum.BUYING_APPOINTMENT,
  );

  if (buyerInvitation.showroom.collections) {
    uniqueBrands = new Map<
      string,
      { brandName: string; collectionName: string }
    >();

    buyerInvitation.showroom.collections.forEach((collection) => {
      uniqueBrands.set(collection.brand.id, {
        brandName: collection.brand.name,
        collectionName: collection.name,
      });
    });

    hasMultipleBrands = uniqueBrands.size > 1;

    if (uniqueBrands.size === 1) {
      const [id, { brandName, collectionName }] = Array.from(
        uniqueBrands.entries(),
      )[0];
      uniqueBrandInfo = { id, name: brandName, collectionName };
      hasUniqueBrand = true;
    }
  }

  const { season, year } = buyerInvitation.showroom;

  const openDeadlinesModal = () => {
    setModalContent("deadlines");
    modalState.open();
  };

  const openAppointmentsModal = () => {
    setModalContent("appointments");
    modalState.open();
  };

  return (
    <BlueRow colsClass="grid-cols-8 px-2">
      {/* organization name */}
      <p className="py-3 ml-2 font-light text-left text-sm truncate flex items-center col-span-1 pl-1">
        {buyerInvitation.organization.name}
      </p>
      {/* opening and closing day */}
      <p className="py-3 font-light text-left text-sm truncate flex items-center col-span-1">
        {format(
          new Date(
            buyerInvitation.showroom.openingDays[
              buyerInvitation.showroom.openingDays.length - 1
            ].day,
          ),
          "MMM d yyyy",
        )}{" "}
        - {format(buyerInvitation.showroom.openingDays[0].day, "MMM d yyyy")} -{" "}
      </p>
      {/* city + country */}
      <p className="py-3 px-2 font-light text-left text-sm  truncate flex items-center col-span-1">
        {buyerInvitation.showroom.city},{" "}
        {countries[buyerInvitation.showroom.countryCode]?.name}
      </p>
      {/* season + year */}
      <p className="py-3 px-2 font-light text-left text-sm  truncate flex items-center col-span-1">
        {buyerInvitation.showroom.season} {buyerInvitation.showroom.year}
      </p>
      {/* invitation status */}
      <div className="py-3 pl-3 font-light text-left text-sm truncate flex items-center col-span-1">
        {getStatusDisplay(buyerInvitation.invitationStatus)}
      </div>
      {/* order deadline */}
      <div className="py-3 pl-4 font-light text-left text-sm truncate flex items-center col-span-1">
        {(() => {
          if (hasBuyingAppointment && hasMultipleBrands) {
            return (
              <Button
                className="whitespace-nowrap text-primaryElectricBlue hover:underline"
                theme="NONE"
                onClick={openDeadlinesModal}
                aria-label={t(
                  "BuyerAccount.buyer-invitations.buttons.view-deadlines",
                )}
              >
                {t("BuyerAccount.buyer-invitations.buttons.view-deadlines")}
              </Button>
            );
          }
          // if there is a single brand, display the order deadline
          if (hasBuyingAppointment && hasUniqueBrand && uniqueBrandInfo) {
            return (
              <DisplayOrderDeadlinePerInvitation
                brandId={uniqueBrandInfo.id}
                season={season}
                year={year}
              />
            );
          }

          // if there is no buying appointment, display "-"
          return "-";
        })()}
      </div>

      {/* appointment count */}
      <div className="py-3 pl-3 font-light text-left text-sm truncate flex items-center col-span-1">
        {appointmentCount > 0 ? (
          <Button
            className="whitespace-nowrap text-primaryElectricBlue hover:underline"
            theme="NONE"
            onClick={openAppointmentsModal}
            aria-label={t(
              "BuyerAccount.buyer-invitations.buttons.appointments",
            )}
          >
            {appointmentCount}{" "}
            {appointmentCount === 1 ? "appointment" : "appointments"}
          </Button>
        ) : (
          "-"
        )}
      </div>

      <div className="py-3 px-2 font-light text-left text-sm truncate flex items-center col-span-1">
        {renderButtons(
          buyerInvitation.invitationStatus,
          buyerInvitation.id,
          buyerInvitation.invitationId,
          t,
          navigate,
        )}
      </div>

      <Modal
        title={t(
          modalContent === "deadlines"
            ? "BuyerAccount.buyer-invitations.modal.all-order-deadlines"
            : "BuyerAccount.buyer-invitations.modal.all-appointments",
        )}
        centeredTitle
        customWidth={
          modalContent === "appointments" ? "w-[52vw]" : "max-w-[90vw]"
        }
        state={modalState}
      >
        {modalContent === "deadlines" ? (
          <div className="p-4">
            {buyerInvitation.showroom.collections?.map((collection) => (
              <div key={collection.id} className="mb-4">
                <DisplayOrderDeadlineModal
                  key={collection.id}
                  uniqueBrands={uniqueBrands}
                  season={season}
                  year={year}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="p-2 max-h-[70vh] overflow-y-auto">
            {buyerInvitation.appointments.map((appt) => (
              <div key={appt.id} className="mb-4">
                <BookedAppointmentCardContainer
                  key={appt.id}
                  appointmentId={appt.id}
                />
              </div>
            ))}
          </div>
        )}
      </Modal>
    </BlueRow>
  );
}

export default BuyerInvitationRowsDisplay;
