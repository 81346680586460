import React from "react";

import { format, intlFormat, parse } from "date-fns";
import DatePicker, {
  CalendarProps,
  DatePickerProps,
} from "react-multi-date-picker";

import Button from "@components/data-entry/Button";
import { extractDateInformation, formatDate } from "@helpers/Date";
import { ellipseWithCount } from "@shared/helpers/formatters";

function isDateInArray(date: Date, array: Date[]) {
  return array.find((d) => formatDate(date) === formatDate(d)) !== undefined;
}

export function CustomDatePickerRender(
  value: string,
  openCalendar: any,
  placeholder: React.ReactNode,
  intlOptions: Parameters<typeof intlFormat>["1"],
  id?: string,
  widthClass = "w-full",
) {
  const dateFormat = "yyyy/MM/dd";
  let valueStr: React.ReactNode;
  // eslint-disable-next-line react/destructuring-assignment
  const split = !Array.isArray(value) ? value.split(", ") : value;
  if (split.length === 1 && split[0] === "") {
    // if value is "", render placeholder
    valueStr = placeholder;
  } else if (split.length === 1 && split[0] !== "") {
    const d = parse(split[0], dateFormat, new Date());
    valueStr = intlFormat(d, intlOptions);
  } else {
    // if value is an array, render placeholder if it's empty
    const { length } = split;
    const ellipsedValue = ellipseWithCount(split, 3);
    valueStr =
      length > 0
        ? ellipsedValue.slice
            .map((str) =>
              str.length > 0
                ? intlFormat(parse(str, dateFormat, new Date()), intlOptions)
                : "",
            )
            .join(", ") +
          (ellipsedValue.length > 0 ? `... +${ellipsedValue.length}` : "")
        : placeholder;
  }
  return (
    <Button
      type="button"
      onClick={openCalendar}
      theme="NONE"
      className={`flex items-center gap-2 border border-primaryGrey bg-white hover:border-primaryElectricBlue px-4 py-2 whitespace-nowrap ${widthClass}`}
      rounded="large"
      id={id}
    >
      {valueStr}
    </Button>
  );
}

type CustomDatePickerProps<Multiple extends boolean> = Omit<
  DatePickerProps<Multiple, false>,
  "onChange" | "placeholder" | "format"
> &
  Omit<CalendarProps<Multiple, false>, "onChange" | "placeholder"> & {
    id?: string;
    intlOptions?: Parameters<typeof intlFormat>["1"];
    placeholder: React.ReactNode;
    onChange: (selectedDates: Date | Date[] | null) => void;
    allowedDates?: Date[];
    widthClass?: string;
  };

export default function CustomDatePicker<Multiple extends boolean>({
  id,
  placeholder,
  onChange,
  allowedDates,
  currentDate = undefined,
  intlOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  },
  widthClass = "w-full",
  ...props
}: CustomDatePickerProps<Multiple>) {
  return (
    <DatePicker
      {...props}
      id={id}
      containerClassName={props.className}
      format="YYYY/MM/DD"
      render={(value: string, openCalendar: () => void) =>
        CustomDatePickerRender(
          value,
          openCalendar,
          placeholder,
          intlOptions,
          id,
          widthClass,
        )
      }
      onChange={(newDate) => {
        if (onChange) {
          if (Array.isArray(newDate)) {
            // if it's an array of dates
            return onChange(
              newDate.map((dateObj) =>
                extractDateInformation(dateObj.toDate()),
              ),
            );
          }
          if (newDate) {
            // if it's a single date
            return onChange(extractDateInformation(newDate.toDate()));
          }
          // if it's null
          return onChange(null);
        }
        return undefined;
      }}
      currentDate={currentDate}
      onOpenPickNewDate={false}
      mapDays={({ date }) => {
        const isAllowed =
          allowedDates !== undefined
            ? {
                disabled: !isDateInArray(date.toDate(), allowedDates),
              }
            : {};
        return {
          ...isAllowed,
          "aria-label": format(date.toDate(), "yyyy-MM-dd"),
        };
      }}
    />
  );
}
