import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { HiCheck } from "react-icons/hi";
import { IoIosClose } from "react-icons/io";

import Button from "@components/data-entry/Button";
import TextField from "@components/data-entry/TextField";
import CalloutBox from "@components/feedback/CalloutBox";
import { CollectionCreateEndpoint } from "@services/api/collections/create";
import { invalidateCollectionsWithCapabilitiesQuery } from "@services/api/old/collections/fetch-collections-with-capabilities";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

interface CollectionItemProps {
  organizationId: string;
  onClose: () => void;
}

function CreateCollectionItem(props: CollectionItemProps) {
  const { organizationId, onClose } = props;
  const { t } = useTranslation();

  const {
    organization: { brand },
  } = useOrganizationAppContext();

  const { mutateAsync: createMutation } = CollectionCreateEndpoint.useHook({
    organizationId,
  });
  const [name, setName] = useState<string>("");

  if (!brand) {
    return (
      <CalloutBox type="ERROR">
        {t("Shared.collections.create.no-brand-error")}
      </CalloutBox>
    );
  }

  return (
    <div className="p-2 mb-6 border-2 rounded-md border-primaryElectricBlue flex gap-2 justify-between items-center">
      <div className="flex flex-col gap-2">
        <label className="font-medium" htmlFor="collection-name">
          {t("Shared.collections.input.label")}
        </label>
        <TextField
          id="collection-name"
          onChange={(value) => {
            setName(value || "");
          }}
          value={name}
          className="w-80"
        />
      </div>
      <div className="flex flex-col items-end align-between">
        <Button
          type="button"
          label="cancel collection creation"
          theme="ICON"
          className="cursor-pointer"
          onClick={() => onClose()}
        >
          <IoIosClose className="w-8 h-8" />
        </Button>
        <Button
          label="Validate collection creation"
          disabled={!name}
          onClick={async () => {
            await createMutation({
              data: {
                name,
                brandId: brand.id,
                tags: [],
              },
            })
              .then(() =>
                invalidateCollectionsWithCapabilitiesQuery(organizationId),
              )
              .finally(() => {
                onClose();
              });
          }}
          theme="PRIMARY"
          className="shrink-0"
        >
          <HiCheck className="w-6 h-6" />
          <span>{t("Shared.collections.create.btn")}</span>
        </Button>
      </div>
    </div>
  );
}

export default CreateCollectionItem;
