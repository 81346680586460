import React from "react";

import { format } from "date-fns";

import { GetOrderDeadline } from "@services/api/order-deadline/get-order-deadline";

interface DisplayOrderDeadlinePerInvitationProps {
  brandId: string;
  season: string;
  year: number;
}

function DisplayOrderDeadlinePerInvitation({
  brandId,
  season,
  year,
}: DisplayOrderDeadlinePerInvitationProps) {
  // fetch order deadline for the showroom
  const { data: orderDeadline } = GetOrderDeadline.useHook({
    brandId,
    season,
    year,
  });

  return (
    <div>
      {orderDeadline?.deadline
        ? format(new Date(orderDeadline.deadline), "MMM d, yyyy")
        : "-"}
    </div>
  );
}

export default DisplayOrderDeadlinePerInvitation;
