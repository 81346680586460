import { z } from "zod";

export const collectionSchema = z.object({
  id: z.string(),
  name: z.string(),
  brandId: z.string().uuid().nullish(),
  tags: z.array(z.string()),
});

export type Collection = z.infer<typeof collectionSchema>;
