import { z } from "zod";

export const contactBrandConnectionStatus = [
  "PENDING",
  "ACCEPTED",
  "REJECTED",
] as const;
export type ContactBrandConnectionStatus =
  (typeof contactBrandConnectionStatus)[number];
export const contactBrandConnectionStatusSchema = z.enum(
  contactBrandConnectionStatus,
);

export const contactBrandConnectionSchema = z.object({
  mainContactFirstname: z.string().nullish(),
  mainContactLastname: z.string().nullish(),
  mainContactEmail: z.string().email().nullish(),
  status: contactBrandConnectionStatusSchema,
});
export type ContactBrandConnection = z.infer<
  typeof contactBrandConnectionSchema
>;
