import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { z } from "zod";

import { Organization } from "@models/Organization";
import {
  OrganizationAccount,
  organizationAccountSchema,
} from "@models/OrganizationAccount";
import LogService from "@services/log/service";

import axiosInstance from "../config";
import { getAPIQueryKey } from "../helper";
import { getFullAccountsEndpoint } from "./get-full-accounts";

export interface SwitchKeyAccountParams {
  organizationId: Organization["id"];
}

const switchKeyAccountBodySchema = organizationAccountSchema.pick({
  id: true,
});
export type SwitchKeyAccountBody = z.infer<typeof switchKeyAccountBodySchema>;

export const switchKeyAccountEndpoint = {
  path: ({
    organizationId,
    accountId,
  }: {
    organizationId: Organization["id"];
    accountId: OrganizationAccount["id"];
  }) =>
    `/organizations/${organizationId}/accounts/${accountId}/switch-key-account`,
  call: (
    { organizationId }: SwitchKeyAccountParams,
    { id: accountId }: SwitchKeyAccountBody,
  ) =>
    axiosInstance
      .post<SwitchKeyAccountBody>(
        switchKeyAccountEndpoint.path({ organizationId, accountId }),
      )
      .then((response) => response.data),
};

export function useSwitchKeyAccount(params: SwitchKeyAccountParams) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: ({ account }: { account: SwitchKeyAccountBody }) =>
      switchKeyAccountEndpoint.call(params, account),
    onSuccess: () => {
      toast.success(t("Toast.account-saved"));
      queryClient.invalidateQueries({
        queryKey: getAPIQueryKey(
          getFullAccountsEndpoint.path(params.organizationId),
        ),
      });
    },
    onError: (err) => {
      toast.error(t("Toast.account-saved-fail"));
      toast.error(`${err}`);
      LogService.error(`${err}`);
    },
  });
}
