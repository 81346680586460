import { Account } from "@models/Account";
import { Contact } from "@models/Contact";
import { OrganizationRepresentativeRole, UserRole } from "@models/types/enums";

interface BaseUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
}
export interface AuthenticatedAdmin extends BaseUser {
  role: "MODARESA_ADMIN";
}
export interface AuthenticatedRepresentative extends BaseUser {
  role: OrganizationRepresentativeRole;
  associatedOrganizations: {
    organizationId: string;
    representativeId: string;
  }[];
}
export interface AuthenticatedBuyer extends BaseUser {
  role: "CONTACT_BUYER";
  contact: Contact;
  account?: Account;
}

export type User =
  | AuthenticatedAdmin
  | AuthenticatedRepresentative
  | AuthenticatedBuyer;

export function isAdmin(user: User): user is AuthenticatedAdmin {
  return user.role === "MODARESA_ADMIN";
}

export function isRepresentative(
  user: User,
): user is AuthenticatedRepresentative {
  return (
    user.role === "ORGANIZATION_MAIN_ADMIN" ||
    user.role === "ORGANIZATION_ADMIN" ||
    user.role === "ORGANIZATION_MANAGER" ||
    user.role === "AGENT" ||
    user.role === "FREELANCE"
  );
}

export function isBuyer(user: User): user is AuthenticatedBuyer {
  return user.role === "CONTACT_BUYER";
}
