import React, { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import BuyerInformations from "@booking/components/appointment/buyer-informations";
import SpecialRequestForm from "@booking/components/appointment/special-request-form";
import BookedAppointmentsList from "@booking/components/appointments-list";
import ContactInformationForm from "@booking/components/contact-information-form";
import ShowroomsList from "@booking/components/showrooms-list";
import UpdateAttendeesForm from "@booking/components/update-attendees-form";
import Button, { buttonStyle } from "@components/data-entry/Button";
import CalloutBox from "@components/feedback/CalloutBox";
import ConfirmModal from "@components/feedback/ConfirmModal";
import Drawer, { useDrawer } from "@components/feedback/Drawer";
import Loading from "@components/feedback/Loading";
import {
  SpecialRequestOldModel,
  SpecialRequestOldModelToInput,
} from "@models/SpecialRequest";
import { AppointmentTypeEnum } from "@models/types/enums";
import NotFound from "@pages/404";
import { useDeleteSpecialRequest } from "@services/api/booking/delete-special-request";
import { BookingGetBookedAppointmentsEndpoint } from "@services/api/booking/get-booked-appointments";
import { BookingGetInvitationInfoEndpoint } from "@services/api/booking/get-invitation-infos";
import { BookingMutations } from "@services/api/old/booking/BookingAPI";
import fetchBookingAppointmentsEndpoint from "@services/api/old/booking/fetch-appointments";
import {
  queryKeysFetchSpecialRequests,
  useFetchSpecialRequests,
} from "@services/api/old/booking/fetch-special-requests";
import getShowroomAppointmentsEndpoint from "@services/api/old/showroom/appointments";
import useAuthentication from "@services/authentication/useAuthentication";
import LogService from "@services/log/service";
import ConditionalRender from "@shared/components/on";
import {
  hasBookingDeadlinePassed,
  isShowroomOver,
} from "@shared/showroom/helpers";

type Appt = BookingGetBookedAppointmentsEndpoint.Output[number];

function isNonEmpty<T>(array: T[]): array is NonEmptyArray<T> {
  return array.length > 0;
}

export default function BookingWelcomePage() {
  const queryClient = useQueryClient();
  const { currentUser } = useAuthentication();
  const { invitationId } = useParams();
  if (!invitationId) {
    throw new Error("no invitation id");
  }
  const navigate = useNavigate();
  const [appointmentToDelete, setAppointmentToDelete] = useState<
    Appt | undefined
  >();
  const [appointmentToUpdate, setAppointmentToUpdate] = useState<
    Appt | undefined
  >();
  const [
    isConfirmingDeletionSpecialRequest,
    setIsConfirmingDeletionSpecialRequest,
  ] = useState(false);

  const [selectedSpecialRequest, setSelectedSpecialRequest] = useState<
    SpecialRequestOldModel | undefined
  >(undefined);

  const {
    data: invitationData,
    isError: isInvitationDataError,
    error: invitationDataError,
    isPending: isLoadingInvitationData,
  } = BookingGetInvitationInfoEndpoint.useHook({ invitationId });
  const {
    data: bookedAppointments = [],
    isPending: isLoadingBookedAppointments,
    isError: isBookedAppointmentsError,
    error: bookedAppointmentsError,
  } = BookingGetBookedAppointmentsEndpoint.useHook({ invitationId });

  const {
    data: bookedSpecialRequests,
    isPending: isLoadingBookedSpecialRequests,
    error: errorBookedSpecialRequests,
    isError: isErrorBookedSpecialRequests,
  } = useFetchSpecialRequests(invitationId);

  const updateAttendeesDrawer = useDrawer({
    onClose: () => {
      setAppointmentToUpdate(undefined);
    },
  });
  const { t } = useTranslation();

  const { mutateAsync: deleteAppointmentMutation, isPending: isDeleteLoading } =
    useMutation({
      ...BookingMutations.deleteAppointment(),
      onSuccess: () => {
        fetchBookingAppointmentsEndpoint.invalidate(queryClient, invitationId);
        toast.success(
          t("Booking.dashboard.appointment-list.delete-appointment-success"),
        );
        setAppointmentToDelete(undefined);
      },
    });
  const { mutateAsync: updateAppointmentAttendeesMutation } = useMutation({
    ...BookingMutations.updateAppointmentAttendees(),
    onSuccess: () => {
      fetchBookingAppointmentsEndpoint.invalidate(queryClient, invitationId);
      toast.success(t("Booking.dashboard.update-attendees.success"));
      updateAttendeesDrawer.closeWithoutConfirmation();
    },
  });

  const { mutateAsync: deleteSpecialRequest } =
    useDeleteSpecialRequest(invitationId);

  React.useEffect(() => {
    // prefetch all the data here
    if (invitationData) {
      invitationData.showrooms.forEach((showroom) => {
        // fetch appointment slots for each collection (+ walktrough) on the first day of each showroom
        queryClient.prefetchQuery({
          ...getShowroomAppointmentsEndpoint.query(invitationId, showroom.id),
        });
      });
    }
  }, [invitationData, invitationId, queryClient]);

  const contactInformationsDrawer = useDrawer();
  const specialRequestDrawer = useDrawer();

  if (
    isLoadingInvitationData ||
    isLoadingBookedAppointments ||
    isLoadingBookedSpecialRequests
  ) {
    return <Loading type="screen" />;
  }

  if (isInvitationDataError) {
    LogService.error(invitationDataError);
    throw invitationDataError;
  }
  if (isBookedAppointmentsError) {
    LogService.error(bookedAppointmentsError);
    throw bookedAppointmentsError;
  }
  if (isErrorBookedSpecialRequests) {
    LogService.error(errorBookedSpecialRequests);
    throw errorBookedSpecialRequests;
  }
  const { organization, showrooms, contact, invitedColleagues } =
    invitationData;

  const handleDeleteAppointment = () => {
    if (appointmentToDelete) {
      deleteAppointmentMutation({
        appointmentId: appointmentToDelete.id,
        invitationId,
      });
    }
  };

  const handleSubmitUpdateAttendees = (contactIds: string[]) => {
    if (appointmentToUpdate) {
      updateAppointmentAttendeesMutation({
        appointmentId: appointmentToUpdate.id,
        invitationId,
        contactIds,
      });
    }
  };

  const handleConfirmDeleteSpecialRequest = () => {
    if (!selectedSpecialRequest) return;

    deleteSpecialRequest(selectedSpecialRequest?.id)
      .then(async () => {
        await queryClient.invalidateQueries({
          queryKey: queryKeysFetchSpecialRequests(invitationId),
        });
        setIsConfirmingDeletionSpecialRequest(false);
        setSelectedSpecialRequest(undefined);
        toast.success(
          t(
            "Booking.AppointmentBookingSlotSelect.special-request-confirm-delete.success",
          ),
        );
      })
      .catch((err: any) => {
        setIsConfirmingDeletionSpecialRequest(false);
        LogService.error(err);
      });
  };

  const showroomIsOver = showrooms.every((showroom) =>
    isShowroomOver(showroom),
  );
  const bookingDeadlineHasPassed = showrooms.every((showroom) =>
    showroom.lastAllowedBookingDate
      ? hasBookingDeadlinePassed(showroom.lastAllowedBookingDate)
      : false,
  );

  const pendingSpecialRequests = bookedSpecialRequests
    ?.filter((ba) => ba.status === "PENDING")
    .sort(
      (a, b) => a.startTime.realDate.getTime() - b.startTime.realDate.getTime(),
    );

  const canBookAppointment = invitationData.showrooms.reduce(
    (canBookOtherShowroom, showroom) => {
      // booked buying appointments
      let hasBookedBuyingAppointment = true;
      if (
        showroom.appointmentTypes.includes(
          AppointmentTypeEnum.BUYING_APPOINTMENT,
        )
      ) {
        hasBookedBuyingAppointment = showroom.collections.every((collection) =>
          bookedAppointments.find(
            (appt) =>
              appt.type === AppointmentTypeEnum.BUYING_APPOINTMENT &&
              appt.collection?.id === collection.id &&
              appt.showroom.id === showroom.id,
          ),
        );
      }

      // booked walkthrough
      let hasBookedWalkthrough = true;
      if (showroom.appointmentTypes.includes(AppointmentTypeEnum.WALKTHROUGH)) {
        hasBookedWalkthrough = bookedAppointments.some(
          (appt) =>
            appt.type === AppointmentTypeEnum.WALKTHROUGH &&
            appt.showroom.id === showroom.id,
        );
      }
      return (
        canBookOtherShowroom ||
        !hasBookedBuyingAppointment ||
        !hasBookedWalkthrough
      );
    },
    false,
  );

  if (!isNonEmpty(showrooms)) {
    return <NotFound />; // @todo do something better
  }

  return (
    <div className="mt-12 mx-6 lg:mx-10">
      <div className="flex justify-between">
        <BuyerInformations
          contact={contact}
          onClickEdit={contactInformationsDrawer.openDrawer}
        />
        <div>
          <Link
            className={buttonStyle({
              className: "capitalize",
              theme: "PRIMARY",
              rounded: "large",
              size: "large",
            })}
            to={
              currentUser
                ? "/buyer/invitations"
                : "/login?context=retailer-signin"
            }
          >
            {currentUser
              ? t("Booking.dashboard.buyer-invitations")
              : t("Booking.dashboard.create-your-account")}
          </Link>
        </div>
      </div>
      <div className="rounded-lg border border-b-primaryLightGrey mt-6 lg:mt-10">
        <div className="bg-primaryLightElectricBlue rounded-t-lg border-b border-b-primaryLightGrey py-6 px-10">
          <h2 className="heading-2-mobile lg:heading-2">{organization.name}</h2>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-6 p-4 lg:p-10">
          <ConditionalRender
            renderIf={!showroomIsOver && !bookingDeadlineHasPassed}
            fallback={
              <div className="flex flex-col items-center w-full">
                <CalloutBox
                  type="INFORMATIVE"
                  className="mb-4 shadow rounded-lg"
                >
                  {showroomIsOver && t("Booking.dashboard.showroom-is-over")}
                  {bookingDeadlineHasPassed &&
                    t("Booking.dashboard.booking-deadline-has-passed")}
                </CalloutBox>
                <img
                  src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExMjE5ZDk5MDAzOGE0YTY0NWMxOWVhZmU4MWJhYmQ3Mzc5MzdkY2VmZCZlcD12MV9pbnRlcm5hbF9naWZzX2dpZklkJmN0PWc/3o6gDY8zzwvNQdFCaQ/giphy.gif"
                  alt="that's all"
                  title="that's all"
                  className="rounded shadow"
                />
              </div>
            }
          >
            <div className="lg:w-1/2 flex flex-col gap-6">
              <div>
                <Trans
                  i18nKey="Booking.dashboard.welcome-text"
                  values={{
                    organizationName: organization.name,
                    accountName: contact.account.name,
                  }}
                >
                  Booking<strong className="font-bold">.</strong>dashboard
                  <br />.<strong className="font-bold">welcome-text</strong>
                </Trans>
              </div>
              <ShowroomsList showrooms={showrooms} />
              <Button
                disabled={!canBookAppointment}
                theme="PRIMARY"
                onClick={() =>
                  navigate(`/booking/${invitationId}/book-appointment`)
                }
                className="w-full flex justify-center font-medium"
                title={t("Booking.dashboard.cannot-book-anymore")}
              >
                {t("Booking.dashboard.book-appointment")}
              </Button>
            </div>
            {showrooms.map((showroom) => (
              <BookedAppointmentsList
                key={showroom.id}
                onEditAttendees={(appointment) => {
                  setAppointmentToUpdate(appointment);
                  updateAttendeesDrawer.openDrawer();
                }}
                onEditAppointment={(appointment) => {
                  navigate(
                    `/booking/${invitationId}/book-appointment/edit/${appointment.id}`,
                  );
                }}
                onRemoveAppointment={(appointment) => {
                  setAppointmentToDelete(appointment);
                }}
                appointments={bookedAppointments.filter(
                  (appt) => appt.showroom.id === showroom.id,
                )}
                specialRequests={pendingSpecialRequests}
                showroom={showroom}
                onRemoveSpecialRequest={(specialRequest) => {
                  setSelectedSpecialRequest(specialRequest);
                  setIsConfirmingDeletionSpecialRequest(true);
                }}
                onEditSpecialRequest={(specialRequest) => {
                  setSelectedSpecialRequest(specialRequest);
                  specialRequestDrawer.openDrawer();
                }}
              />
            ))}
          </ConditionalRender>
        </div>
      </div>
      <ConfirmModal
        show={appointmentToDelete !== undefined}
        title={t("Booking.dashboard.appointment-list.delete-appointment-title")}
        confirmLabel={t(
          "Booking.dashboard.appointment-list.delete-appointment-yes-delete",
        )}
        onCancel={() => {
          setAppointmentToDelete(undefined);
        }}
        onConfirm={handleDeleteAppointment}
        isConfirmLoading={isDeleteLoading}
      >
        {appointmentToDelete && (
          <Trans
            i18nKey="Booking.dashboard.appointment-list.delete-appointment-content"
            values={{
              date: appointmentToDelete.startTime.formatDateAtTimezone(
                appointmentToDelete.showroom.timezone,
              ),
              startTime: appointmentToDelete.startTime.formatTimeAtTimezone(
                appointmentToDelete.showroom.timezone,
              ),
              endTime: appointmentToDelete.endTime.formatTimeAtTimezone(
                appointmentToDelete.showroom.timezone,
              ),
              timeZone: appointmentToDelete.showroom.timezone,
            }}
            components={{
              bold: <strong className="font-bold" />,
              break: <br />,
            }}
          >
            Booking.dashboard.appointment-list.delete-appointment-content
          </Trans>
        )}
      </ConfirmModal>
      <ConfirmModal
        show={isConfirmingDeletionSpecialRequest}
        title={t(
          "Booking.AppointmentBookingSlotSelect.special-request-confirm-delete.title",
        )}
        confirmLabel={t(
          "Booking.AppointmentBookingSlotSelect.special-request-confirm-delete.yes-cancel",
        )}
        cancelLabel={t("Components.confirm-modal.cancel")}
        theme="DANGER"
        onCancel={() => {
          setIsConfirmingDeletionSpecialRequest(false);
        }}
        onConfirm={handleConfirmDeleteSpecialRequest}
      >
        <Trans
          i18nKey="Booking.AppointmentBookingSlotSelect.special-request-confirm-delete.are-you-sure"
          components={{
            br: <br />,
          }}
        />
      </ConfirmModal>
      <Drawer
        {...contactInformationsDrawer.props}
        name="booking-editContactInformation"
        backdrop
        size="LARGE"
        drawerTitle={
          <h2 className="heading-2-mobile lg:heading-2">
            {t("Booking.contact.edit.title")}
          </h2>
        }
      >
        <ContactInformationForm
          invitationId={invitationId}
          contact={contact}
          handleCancel={contactInformationsDrawer.closeWithoutConfirmation}
          onSuccess={contactInformationsDrawer.closeWithoutConfirmation}
        />
      </Drawer>
      <Drawer
        {...updateAttendeesDrawer.props}
        name="booking-editContactInformation"
        backdrop
        size="LARGE"
      >
        <UpdateAttendeesForm
          invitedColleagues={invitedColleagues}
          selectedContacts={appointmentToUpdate?.attendees || []}
          handleCancel={() => {
            setAppointmentToUpdate(undefined);
            updateAttendeesDrawer.closeWithoutConfirmation();
          }}
          onSubmit={handleSubmitUpdateAttendees}
        />
      </Drawer>
      {selectedSpecialRequest && (
        <Drawer
          {...specialRequestDrawer.props}
          name="creating or updating special request drawer"
          backdrop
          size="LARGE"
          drawerTitle={
            <h2 className="heading-2-mobile lg:heading-2">
              {t(
                "Booking.AppointmentBookingSlotSelect.special-request-form.drawer-title",
              )}
            </h2>
          }
        >
          <SpecialRequestForm
            selectedSpecialRequest={SpecialRequestOldModelToInput(
              selectedSpecialRequest!,
              showrooms[0].timezone,
            )}
            invitationId={invitationId}
            appointmentType={selectedSpecialRequest!.type}
            showroom={showrooms[0]}
            onCancel={specialRequestDrawer.closeWithoutConfirmation}
            onSuccess={specialRequestDrawer.closeWithoutConfirmation}
            onError={specialRequestDrawer.closeWithoutConfirmation}
            organization={invitationData.organization}
            account={invitationData.contact.account}
            collection={selectedSpecialRequest.collection}
          />
        </Drawer>
      )}
    </div>
  );
}
