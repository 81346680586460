import { toast } from "react-toastify";

import axiosInstance from "@services/api/config";
import { AccessType } from "@services/authentication/AuthenticationContext";
import { User } from "@services/authentication/authentication.types";
import LogService from "@services/log/service";

const UserAPI = {
  signIn: (token: string, accessType: AccessType | null): Promise<User> => {
    const headers: Record<string, string> = {
      Authorization: `Bearer ${token}`,
      context: accessType || "unknown",
    };

    return axiosInstance
      .get(`/sign-in`, {
        headers,
      })
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        LogService.error(err);
        toast.error(err.message);
      });
  },
};

export default UserAPI;
