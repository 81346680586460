import React, { useState } from "react";

import { t } from "i18next";
import { HiAdjustments, HiOutlineSearch } from "react-icons/hi";
import { useDebouncedCallback } from "use-debounce";

import Tag from "@components/data-display/Tag";
import Button from "@components/data-entry/Button";
import MultiSelect from "@components/data-entry/MultiSelect";
import Select from "@components/data-entry/Select";
import TextField from "@components/data-entry/TextField";
import Toggle from "@components/data-entry/Toggle";
import Loading from "@components/feedback/Loading";
import {
  AccountStatusList,
  OrganizationAccount,
} from "@models/OrganizationAccount";
import { Portfolio } from "@models/Portfolio";
import { useGetPortfolios } from "@services/api/portfolios/get-portfolios";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

export type AccountFilters = {
  text?: string;
  accountStatus?: OrganizationAccount["status"];
  portfolios?: Pick<Portfolio, "id" | "name">[];
  isKeyClient?: OrganizationAccount["isKeyClient"];
};

interface AccountsFilterProps {
  filters: AccountFilters;
  onChange: (values: AccountFilters) => void;
}

function countFilters(filters: AccountFilters) {
  let count = 0;
  if (filters.text) {
    count += 1;
  }
  if (filters.accountStatus?.length) {
    count += 1;
  }
  if (filters.portfolios?.length) {
    count += 1;
  }
  if (filters.isKeyClient) {
    count += 1;
  }
  return count;
}

function AccountsFilters(props: AccountsFilterProps) {
  const { filters, onChange } = props;
  const {
    organization: { id: organizationId },
  } = useOrganizationAppContext();

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string | undefined>(filters.text);

  const debounced = useDebouncedCallback((value: string | undefined) => {
    onChange({
      ...filters,
      text: value,
    });
  }, 300);

  const { data: fetchedPortfolios, isLoading } = useGetPortfolios({
    organizationId,
  });
  if (isLoading) return <Loading type="screen" />;

  const filterCount = countFilters(filters);

  return (
    <div className="flex flex-col justify-start gap-4">
      <div className="flex flex-col items-end w-full gap-4 lg:items-center lg:flex-row">
        <div className="flex items-center justify-start w-full gap-4 lg:w-auto">
          <div className="w-full lg:w-98">
            <TextField
              id="text-filter"
              icon={<HiOutlineSearch className="w-5 h-5 text-gray-600" />}
              placeholder={t("CRM.accounts.filters.text-placeholder")}
              value={textValue}
              onChange={(value: string) => {
                setTextValue(value);
                debounced(value);
              }}
            />
          </div>
          {/* MOBILE VERSION FILTER BUTTON */}
          <Button
            theme="PRIMARY"
            rounded="full"
            className="w-12 h-10 p-0 lg:hidden"
            onClick={() => setShowFilters(!showFilters)}
          >
            <HiAdjustments className="w-4 h-4 text-white" />
          </Button>
          {/* DESKTOP VERSION FILTER BUTTON */}
          <Button
            theme="PRIMARY"
            className="items-center justify-between hidden lg:flex"
            onClick={() => setShowFilters(!showFilters)}
          >
            {t("Common.filters")}
            {filterCount !== 0 && (
              <Tag theme="PRIMARY-REVERSED" size="sm" type="number">
                {filterCount}
              </Tag>
            )}
            {filterCount === 0 && (
              <HiAdjustments className="w-4 h-4 text-white lg:block" />
            )}
          </Button>
        </div>
        {filterCount !== 0 && (
          <Button
            theme="LINK"
            className="underline"
            onClick={() => onChange({})}
          >
            {t("Common.filters-clear")}
          </Button>
        )}
      </div>
      {showFilters && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-wrap items-center justify-start w-full gap-4">
            <div className="w-full lg:w-60">
              <label htmlFor="account-status" className="font-medium">
                {t("CRM.accounts.filters.status-label")}
              </label>
              <Select
                id="account-status"
                name="account-status"
                placeholder={t("CRM.accounts.filters.status-placeholder")}
                defaultValue={{
                  value: filters.accountStatus,
                }}
                onChange={(value) => {
                  onChange({
                    ...filters,
                    accountStatus: value as OrganizationAccount["status"],
                  });
                }}
                options={[
                  {
                    key: "grp_1",
                    label: "Recommended",
                    options: AccountStatusList.map((s) => ({
                      label: t(
                        `SalesCampaign.invitation.account-status.${s.toString()}`,
                      ),
                      value: s,
                    })).sort((a, b) => a.label.localeCompare(b.label)),
                  },
                ]}
              />
            </div>
            <div className="w-full lg:w-fit min-w-[15rem]">
              <label htmlFor="portfolios" className="font-medium">
                {t("CRM.accounts.filters.portfolios-label")}
              </label>
              <MultiSelect
                id="portfolios"
                name="portfolios"
                placeholder={t("CRM.accounts.filters.portfolios-placeholder")}
                value={filters.portfolios?.map((p) => ({
                  label: p.name,
                  value: p.id,
                }))}
                onChange={(value) => {
                  onChange({
                    ...filters,
                    portfolios: value.map((v) => {
                      const portfolio = fetchedPortfolios!.find(
                        (fp) => fp.id === v.value,
                      );
                      return {
                        id: portfolio!.id,
                        name: portfolio!.name,
                      };
                    }),
                  });
                }}
                options={fetchedPortfolios!
                  .map((s) => ({
                    label: s.name,
                    value: s.id,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Toggle
              id="toggleKeyAccounts"
              checked={!!filters.isKeyClient}
              onChange={(value) =>
                onChange({
                  ...filters,
                  isKeyClient: value,
                })
              }
            />
            {t("CRM.accounts.filters.key-accounts-label")}
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountsFilters;
