import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { BlueRow } from "@components/data-display/BlueRow";
import Tag from "@components/data-display/Tag";
import Button from "@components/data-entry/Button";
import Loading from "@components/feedback/Loading";
import Modal, { useModal } from "@components/feedback/Modal";
import { GetOrganizationCollectionsWithCapabilitiesEndpoint } from "@services/api/organization/get-collections-with-capabilities";
import { useOrganizationAppContext } from "@services/application/useApplicationContext";

import CollectionForm from "./CollectionForm";
import { marketCategoriesGrouped } from "./helpers";

interface Collection {
  id: string;
  name: string;
  tags: string[];
}

interface BrandWithCollections {
  brandName: string;
  brandId: string;
  collections: Collection[];
}

export function useCollectionList() {
  const modalState = useModal();
  const [selectedBrandId, setSelectedBrandId] = useState<string | null>(null);

  const { organization } = useOrganizationAppContext();

  // fetch collections
  const {
    data: collections = [],
    status: collectionListStatus,
    error: collectionListError,
  } = GetOrganizationCollectionsWithCapabilitiesEndpoint.useHook({
    organizationId: organization.id,
  });

  const openModalWithBrand = (brandId: string) => {
    setSelectedBrandId(brandId);
    modalState.open();
  };

  // reduce to group collections by brand
  const brandsWithCollections = collections.reduce<BrandWithCollections[]>(
    (acc, coll) => {
      if (coll.brand) {
        const brandName = coll.brand.name;
        const brandId = coll.brand.id;
        const collectionData = {
          id: coll.id,
          name: coll.name,
          tags: coll.tags,
        };

        // check if the brand already exists in the accumulator
        const existingBrand = acc.find(
          (brand) => brand.brandName === brandName,
        );

        // if the brand doesn't exist - add a new entry for the brand
        if (!existingBrand) {
          acc.push({ brandName, brandId, collections: [collectionData] });

          // if the brand exists - push the collection data to the brand's collections array
        } else {
          existingBrand.collections.push(collectionData);
        }
      }
      return acc;
    },
    [],
  );

  return {
    brandsWithCollections,
    collectionListStatus,
    collectionListError,
    selectedBrandId,
    setSelectedBrandId,
    openModalWithBrand,
    modalState,
    organization,
  };
}

type CollectionListProps = ReturnType<typeof useCollectionList>;

function CollectionList({
  brandsWithCollections,
  collectionListStatus,
  collectionListError,
  selectedBrandId,
  setSelectedBrandId,
  openModalWithBrand,
  modalState,
  organization,
}: CollectionListProps) {
  const { t } = useTranslation();
  const { id: organizationId, brand: organizationBrand } = organization;
  // display error page
  if (collectionListError) {
    console.error(collectionListError);
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <p className="text-3xl mb-4 font-bold text-primaryElectricBlue">
            {t("Shared.AgencyCollectionForm.error-title")}
          </p>
          <p className="text-lg">
            {t("Shared.AgencyCollectionForm.error-subtitle")}
          </p>
        </div>
      </div>
    );
  }

  if (collectionListStatus === "pending") {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loading type="screen" />
      </div>
    );
  }

  return (
    <div>
      {organizationBrand && brandsWithCollections.length === 0 && (
        <Button
          className="mb-4"
          theme="PRIMARY"
          onClick={() => openModalWithBrand(organizationBrand.id)}
        >
          {t("OrganizationProfile.collections.add-new-collection")}
        </Button>
      )}
      <div className="grid grid-cols-2 mb-2">
        <p className="font-medium">
          {t("Shared.AgencyCollectionForm.collection-name")}
        </p>
        <p className="font-medium pl-4">
          {t("Shared.AgencyCollectionForm.market-categories")}
        </p>
      </div>
      <hr />
      {(brandsWithCollections || []).map((brand) => (
        <div className="mt-8" key={brand.brandId}>
          <div className="flex items-center">
            <p className="pl-1 pb-1 text-xl font-bold text-primaryElectricBlue mr-2">
              {brand.brandName}
            </p>
            <Button
              theme="SECONDARY"
              className="px-3 py-1"
              onClick={() => openModalWithBrand(brand.brandId)}
              label={t("OrganizationProfile.collections.add-new-collection")}
            >
              +
            </Button>
          </div>
          {brand.collections.map((collection) => (
            <BlueRow key={collection.id} colsClass="grid-cols-2">
              <div className="py-2 px-4 flex items-center">
                <p>{collection.name}</p>
              </div>
              <div className="py-2 px-3 flex flex-wrap items-center">
                {collection.tags?.map((tag) => (
                  <div
                    key={`${collection.id}-${tag}`}
                    className="mx-0.5 my-0.5"
                  >
                    <Tag
                      className=" text-white  text-sm bg-opacity-90"
                      theme="PRIMARY-ELECTRIC-BLUE"
                    >
                      <span key={tag}>
                        {marketCategoriesGrouped
                          .flatMap((category) =>
                            category.options.map((option) => {
                              if (option.value === tag) {
                                // construct translation key without duplicating "HOME"
                                const translationKey =
                                  category.label === "HOME"
                                    ? `Common.market-category.HOME`
                                    : `Common.market-category.${category.label}_${option.label}`;
                                return t(translationKey);
                              }
                              return null;
                            }),
                          )
                          .filter((label) => label)
                          .join(" | ")}{" "}
                      </span>
                    </Tag>
                  </div>
                ))}
              </div>
            </BlueRow>
          ))}
        </div>
      ))}
      <Modal
        title={`Add a new collection for ${brandsWithCollections.find((brand) => brand.brandId === selectedBrandId)?.brandName}`}
        state={modalState}
        centeredTitle
        padding="p-9"
        aria-label={t("OrganizationProfile.collections.add-new-collection")}
      >
        <div className="px-28">
          <CollectionForm
            brandId={selectedBrandId}
            organizationId={organizationId}
            onSuccess={async () => {
              modalState.close();
              setSelectedBrandId(null);
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

export default CollectionList;
