import React, { HTMLProps } from "react";

import { cva } from "class-variance-authority";
import { IoIosClose } from "react-icons/io";

import Button from "./Button";

export const inputStyle = cva(
  "outline-none rounded border w-full text-gray-700 px-3 py-2",
  {
    variants: {
      disabled: {
        true: "bg-primaryLightestGrey",
        false: "",
      },
      errored: {
        true: "border-primaryRed",
        false: "border-primaryLightGrey",
      },
      icon: {
        true: "pl-10",
        false: "",
      },
    },
    defaultVariants: {
      errored: false,
      disabled: false,
      icon: false,
    },
  },
);

// removing onChange and defaultValue because it is a controlled component
interface TextFieldProps
  extends Omit<HTMLProps<HTMLInputElement>, "onChange" | "defaultValue"> {
  className?: string;
  icon?: JSX.Element;
  onChange?: (v: string) => void;
  onClearField?: () => void;
  value?: string;
  clearable?: boolean;
  hasError?: boolean;
  grayCloseIcon?: boolean;
}

function TextField(props: Readonly<TextFieldProps>) {
  const {
    className,
    icon,
    onChange,
    onClearField,
    id,
    value = "",
    clearable = true,
    hasError = false,
    type = "text",
    grayCloseIcon = false,
    ...rest
  } = props;

  const isDisabled = rest.disabled || rest.readOnly;
  const clearField = () => {
    if (onChange && !isDisabled) {
      onChange("");
      if (onClearField) onClearField();
    }
  };

  return (
    <div className="relative w-full h-full text-gray-700">
      {/* added border-primaryLightGrey so that the otb, total budget and notes inputs match the border from the element above which provides appointment info */}
      <div
        className={`flex items-center rounded-lg form-input ${
          isDisabled ? "bg-primaryLightestGrey" : ""
        } ${className || ""} 

        ${hasError ? "border-primaryRed" : "border-primaryLightGrey"}`}
      >
        {icon && <div className="justify-center -mr-px">{icon}</div>}
        <input
          type={type}
          className={`w-full h-6 outline-none ${
            isDisabled ? "bg-primaryLightestGrey hover:cursor-default" : ""
          } ${icon && "px-3"}`}
          id={id}
          name={id}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          value={value || ""}
          {...rest}
        />
        {clearable && !isDisabled && onChange && (
          <Button
            theme="ICON"
            type="button"
            title="clear field"
            className="cursor-pointer absolute top-2 right-2"
            onClick={clearField}
          >
            <IoIosClose
              className={`w-6 h-6 ${grayCloseIcon ? "text-gray-400" : ""}`}
            />
          </Button>
        )}
      </div>
    </div>
  );
}

export default TextField;
