import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { appointmentHydratingSchema } from "@models/Appointment";
import { collectionSchema } from "@models/Collection";
import { Organization } from "@models/Organization";
import { organizationRepresentativeSchema } from "@models/OrganizationRepresentative";
import { showroomSchema } from "@models/Showroom";
import Showroom from "@models/old/Showroom";
import axiosInstance from "@services/api/config";
import { getAPIQueryKey } from "@services/api/helper";

export namespace GetShowroomAppointments {
  export const outputSchema = z.array(
    appointmentHydratingSchema
      .pick({
        id: true,
        accountOtb: true,
        startTime: true,
        endTime: true,
        format: true,
        type: true,
        virtualMeetingApp: true,
        warnings: true,
        bookedThrough: true,
        status: true,
        title: true,
      })
      .extend({
        seller: organizationRepresentativeSchema.pick({
          id: true,
        }),
        collection: collectionSchema.pick({ id: true, name: true }).nullable(),
        showroom: showroomSchema.pick({ id: true }),
      }),
  );
  export type Output = z.infer<typeof outputSchema>;

  export interface HookParams {
    organizationId: Organization["id"];
    showroomId: Showroom["id"];
  }

  export function path({ organizationId, showroomId }: HookParams) {
    return `/organizations/${organizationId}/showrooms/${showroomId}/appointments`;
  }

  export function call(params: HookParams) {
    return axiosInstance
      .get<Output>(path(params))
      .then((r) => outputSchema.parse(r.data))
      .catch(console.error);
  }

  export function getQueryKeys(params: HookParams) {
    return getAPIQueryKey(path(params));
  }

  export function useHook(params: HookParams) {
    return useQuery({
      queryKey: getQueryKeys(params),
      queryFn: () => call(params),
    });
  }
}
