import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { brandSchema } from "@models/Brand";
import axiosInstance from "@services/api/config";
import { GetOrganizationCollectionsEndpoint } from "@services/api/organization/get-collections";
import { GetOrganizationCollectionsWithCapabilitiesEndpoint } from "@services/api/organization/get-collections-with-capabilities";

export namespace OrganizationPartnersWithNonOnboardedBrandEndpoint {
  export const inputSchema = z.object({
    name: z.string(),
  });
  export type Input = z.infer<typeof inputSchema>;

  export const outputSchema = brandSchema.pick({
    id: true,
    name: true,
  });
  export type Output = z.infer<typeof outputSchema>;

  interface HookParams {
    organizationId: string;
  }

  interface MutationParams {
    data: Input;
  }

  type Params = HookParams & MutationParams;

  export const path = ({ organizationId }: Params) =>
    `/organizations/${organizationId}/partner-brands`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(params), inputSchema.parse(params.data))
      .then((res) => outputSchema.parse(res.data));

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: GetOrganizationCollectionsEndpoint.getQueryKeys(hookParams),
        });
        queryClient.invalidateQueries({
          queryKey:
            GetOrganizationCollectionsWithCapabilitiesEndpoint.getQueryKeys(
              hookParams,
            ),
        });
      },
    });
  }
}
