import React from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { z } from "zod";

import { formatDateToISO } from "@helpers/Date";
import { appointmentSerializingSchema } from "@models/Appointment";
import { collectionSchema } from "@models/Collection";
import { organizationAccountSchema } from "@models/OrganizationAccount";
import { organizationContactSchema } from "@models/OrganizationContact";
import { organizationRepresentativeSchema } from "@models/OrganizationRepresentative";
import { showroomSchema } from "@models/Showroom";
import axiosInstance from "@services/api/config";
import { GetDailyCalendarEndpoint } from "@services/api/sales-campaigns/get-daily-calendar";
import LogService from "@services/log/service";

import { GetAppointmentsForInterval } from "./GetAppointmentsForInterval";

export namespace UpsertAppointmentEndpoint {
  /**
   * ENDPOINT SCHEMA DECLARATIONS
   */
  export const inputSchema = appointmentSerializingSchema
    .pick({
      endTime: true,
      format: true,
      startTime: true,
      type: true,
      virtualMeetingApp: true,
      title: true,
      accountOtb: true,
    })
    .extend({
      id: z.string().uuid().nullable(),
      showroom: showroomSchema.pick({ id: true, timezone: true }),
      seller: organizationRepresentativeSchema.pick({
        id: true,
      }),
      account: organizationAccountSchema.pick({ id: true }).nullable(),
      attendees: z.array(organizationContactSchema.pick({ id: true })),
      collection: collectionSchema
        .pick({
          id: true,
        })
        .nullable(),
    });
  export type Input = z.input<typeof inputSchema>;
  export type InputSerialized = z.output<typeof inputSchema>;

  export const outputSchema = GetDailyCalendarEndpoint.appointmentSchema;
  export type OutputSerialized = z.input<typeof outputSchema>;
  export type Output = z.output<typeof outputSchema>;

  interface Params {
    organizationId: string;
    dayAsString: string;
  }
  export interface Variables {
    appointment: Input;
  }
  type Combined = Variables & Params;

  const isUpdate = (params: Variables) => !!params.appointment.id;

  export const putPath = ({ organizationId, appointment }: Combined) =>
    `/organizations/${organizationId}/appointments/${appointment.id}`;
  export const postPath = ({ organizationId }: Combined) =>
    `/organizations/${organizationId}/appointments`;

  export const call = (params: Combined) =>
    (isUpdate(params)
      ? axiosInstance.put<
          any,
          AxiosResponse<OutputSerialized>,
          InputSerialized
        >(putPath(params), inputSchema.parse(params.appointment))
      : axiosInstance.post<
          any,
          AxiosResponse<OutputSerialized>,
          InputSerialized
        >(postPath(params), inputSchema.parse(params.appointment))
    ).then((res) => outputSchema.parse(res.data));

  export function useHook(params: Params) {
    const queryClient = useQueryClient();
    const invalidateDailyAppointments =
      GetAppointmentsForInterval.useInvalidate();
    const getDailyAppointmentOptimistic =
      GetAppointmentsForInterval.useOptimisticUpdate();

    const { t } = useTranslation();
    return useMutation({
      mutationFn: (data: Variables) => call({ ...data, ...params }),
      onMutate: async (variables) => {
        const getEndpointParams: GetAppointmentsForInterval.HookParams = {
          organizationId: params.organizationId,
          dayAsString: params.dayAsString,
        };
        const queryKey =
          GetAppointmentsForInterval.getQueryKeys(getEndpointParams);
        const oldAppointments =
          queryClient.getQueryData<GetAppointmentsForInterval.Output>(queryKey);

        // optimistic update only for existing appointments (used mostly for drag'n drop feel)
        if (isUpdate(variables)) {
          await getDailyAppointmentOptimistic.update(
            getEndpointParams,
            variables.appointment,
          );
        }
        return oldAppointments;
      },
      onError: (error, variables, context) => {
        if (context) {
          const getEndpointParams: GetAppointmentsForInterval.HookParams = {
            organizationId: params.organizationId,
            dayAsString: params.dayAsString,
          };
          getDailyAppointmentOptimistic.revert(getEndpointParams, context);
        }

        toast.error(
          <Trans
            i18nKey={
              isUpdate(variables)
                ? "Toast.appointment-updated-fail"
                : "Toast.appointment-created-fail"
            }
            values={{
              error,
            }}
            components={{
              br: <br />,
            }}
          />,
        );
        LogService.error(error);
      },
      onSuccess: (data, variables) => {
        toast.success(
          t(
            isUpdate(variables)
              ? "Toast.appointment-updated"
              : "Toast.appointment-created",
          ),
        );
        invalidateDailyAppointments({
          organizationId: params.organizationId,
          dayAsString: params.dayAsString,
        });
        if (isUpdate(variables)) {
          // if it's an update, we want to invalidate the getDailyCalendar for the old date
          // but only if it's not the same as the new one (otherwise we would invalidate twice the same query)
          const appointmentDate = formatDateToISO(
            variables.appointment.startTime.toLocalDate(
              variables.appointment.showroom.timezone,
            ),
          );
          if (params.dayAsString !== appointmentDate) {
            invalidateDailyAppointments({
              organizationId: params.organizationId,
              dayAsString: appointmentDate,
            });
          }
        }
      },
    });
  }
}
