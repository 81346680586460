import React, {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import * as Sentry from "@sentry/react";
import { User as FirebaseUser } from "firebase/auth";
import posthog from "posthog-js";
import { useLocalStorage } from "usehooks-ts";

import {
  STORAGEKEY_SHOWROOM_CREATION_FORM_DATA,
  STORAGEKEY_SHOWROOM_CREATION_STEP,
} from "@app/modules/showroom/form/helpers";
import axiosInstance from "@services/api/config";
import OrganizationAPI from "@services/api/old/organization/OrganizationAPI";
import UserAPI from "@services/api/old/user/UserAPI";
import useApplicationContext from "@services/application/useApplicationContext";
import {
  auth,
  firebaseAuthenticationService as authenticationService,
} from "@services/authentication/firebase";

import {
  User,
  isAdmin,
  isBuyer,
  isRepresentative,
} from "./authentication.types";

export type AccessType =
  | "brand-signin"
  | "retailer-signin"
  | "brand-signup"
  | "retailer-signup";

export interface Impersonation {
  email: string;
  organizationId: string;
  impersonatedUser: User;
  realUser: User;
}

export interface AuthenticationContextType {
  authenticating: boolean;
  currentUser: User | null;
  getToken: () => Promise<string | null>;
  googleSignIn: (accessType: AccessType) => Promise<void>;
  microsoftSignIn: (accessType: AccessType) => Promise<void>;
  credentialsSignIn: (
    accessType: AccessType,
    email: string,
    password: string,
  ) => Promise<void>;
  signUpWithEmailAndPassword: (
    accessType: AccessType,
    email: string,
    password: string,
  ) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  confirmPasswordReset: (oobCode: string, newPassword: string) => Promise<void>;
  signOut: () => Promise<void>;
  impersonation: Impersonation | null;
  impersonateRepresentative: (
    organizationId: string,
    email: string,
  ) => Promise<void>;
  stopImpersonation: () => Promise<void>;
  updateUser: (token: string) => Promise<void>;
}

const AuthenticationContext = createContext<
  AuthenticationContextType | undefined
>(undefined);

function AuthenticationProvider({
  children,
}: Readonly<{ children: ReactNode }>) {
  const [authenticating, setAuthenticating] = useState(true);
  const { setOrganization, setCurrentSalesCampaignId } =
    useApplicationContext();
  const [impersonation, setImpersonation] =
    useLocalStorage<Impersonation | null>("impersonation", null);
  const [currentUser, setCurrentUser] = useLocalStorage<User | null>(
    "user",
    impersonation?.impersonatedUser || null,
  );
  let initialAccessType: AccessType | null = null;
  if (currentUser) {
    initialAccessType =
      currentUser.role === "CONTACT_BUYER" ? "retailer-signin" : "brand-signin";
  }
  const [appAccessType, setAppAccessType] = useState<AccessType | null>(
    initialAccessType,
  );

  const updateUser = useCallback(
    async (token: string) => {
      const user = await UserAPI.signIn(token, appAccessType);
      // setup Analytics and Logging
      Sentry.setUser({
        email: user.email,
        id: user.email,
      });
      posthog.identify(user.email);

      // setup organization representative
      if (isRepresentative(user)) {
        if (user.associatedOrganizations.length > 0) {
          const [{ organizationId }] = user.associatedOrganizations;
          const organization =
            await OrganizationAPI.getOrganization(organizationId);
          setOrganization(organization);
          setCurrentUser(user);
          posthog.group("organization", organization.name);
        } else {
          setCurrentUser(user);
        }
      }

      // setup buyer
      if (isBuyer(user)) {
        setCurrentUser(user);
        if (user.account) {
          posthog.group("organization", user.account.name);
        }
      }

      // setup admin
      if (isAdmin(user)) {
        setCurrentUser(impersonation ? impersonation.impersonatedUser : user);
        posthog.group("organization", "Modaresa");
      }
    },
    [appAccessType, impersonation],
  );

  useEffect(() => {
    auth.onAuthStateChanged(async (firebaseUser: FirebaseUser | null) => {
      // if no firebase user, we are not authenticated
      if (!firebaseUser?.email) {
        setImpersonation(null);
        setCurrentUser(null);
        setOrganization(undefined);
        setCurrentSalesCampaignId(null);
        setAuthenticating(false);
        return;
      }
      // if we are here, we are authenticated through firebase, we should fetch the user from API
      const token = await firebaseUser.getIdToken(true);
      await updateUser(token);
      // this will create the user if it does not exist
      setAuthenticating(false);
    });
    // No deps to avoid infinite loop
    // eslint-disable-next-line
  }, [appAccessType]);

  const getToken = async () => {
    let token = null;
    if (auth.currentUser) {
      token = await auth.currentUser.getIdToken();
    }
    return token;
  };

  const googleSignIn = async (accessType: AccessType) => {
    setAppAccessType(accessType);
    await authenticationService.oauthSignIn("google");
  };
  const microsoftSignIn = async (accessType: AccessType) => {
    setAppAccessType(accessType);
    await authenticationService.oauthSignIn("microsoft");
  };
  const credentialsSignIn = async (
    accessType: AccessType,
    email: string,
    password: string,
  ) => {
    setAppAccessType(accessType);
    await authenticationService.signIn(email, password);
  };
  const signUpWithEmailAndPassword = async (
    email: string,
    password: string,
  ) => {
    await authenticationService.signUpWithEmailAndPassword(email, password);
  };

  const resetPassword = (email: string) =>
    authenticationService.sendPasswordResetEmail(email);

  const confirmPasswordReset = async (oobCode: string, newPassword: string) => {
    await authenticationService.confirmPasswordReset(oobCode, newPassword);
  };

  const signOut = async () => {
    await authenticationService.signOut();
  };

  const impersonateRepresentative = useCallback(
    async (organizationId: string, email: string) => {
      if (!currentUser) {
        throw new Error("No current user");
      }
      const impersonatedUser = await axiosInstance
        .get<User>(`/users/by-email/${email}`)
        .then((response) => response.data);
      setCurrentUser(impersonatedUser);
      const organization =
        await OrganizationAPI.getOrganization(organizationId);
      setOrganization(organization);
      setImpersonation({
        email,
        organizationId,
        impersonatedUser,
        realUser: currentUser,
      });
      localStorage.removeItem(STORAGEKEY_SHOWROOM_CREATION_STEP);
      localStorage.removeItem(STORAGEKEY_SHOWROOM_CREATION_FORM_DATA);
    },
    [currentUser, setOrganization, setCurrentUser, setImpersonation],
  );

  const stopImpersonation = useCallback(async () => {
    if (!impersonation) {
      throw new Error("No data in local storage for Connected-As");
    }
    setCurrentUser(impersonation.realUser);
    setOrganization(undefined);
    setImpersonation(null);
    setCurrentSalesCampaignId(null);
  }, [
    impersonation,
    setOrganization,
    setCurrentSalesCampaignId,
    setCurrentUser,
    setImpersonation,
  ]);

  const context = useMemo<AuthenticationContextType>(
    () => ({
      authenticating,
      currentUser,
      getToken,
      googleSignIn,
      microsoftSignIn,
      credentialsSignIn,
      signUpWithEmailAndPassword,
      resetPassword,
      confirmPasswordReset,
      signOut,
      impersonation,
      impersonateRepresentative,
      stopImpersonation,
      updateUser,
    }),
    [
      authenticating,
      currentUser,
      impersonateRepresentative,
      impersonation,
      stopImpersonation,
      updateUser,
    ],
  );

  return (
    <AuthenticationContext.Provider value={context}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export { AuthenticationProvider, AuthenticationContext };
