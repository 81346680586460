import React, { forwardRef } from "react";

/**
 * Augmented components usage :
 *
 * <HTML.Div if={isRendered}>Content</HTML.div>
 *
 */

interface ConditionalProps {
  if?: boolean;
}

/**
 * This function is a higher order component that augments an HTML element with different props :
 * - IF: A boolean that determines if the element should be rendered.
 *
 * @param Component The HTML element to augment.
 * @returns The augmented HTML element.
 */
const withAugments = <HTMLTagName extends keyof JSX.IntrinsicElements>(
  Component: HTMLTagName,
) =>
  forwardRef<
    HTMLElement,
    JSX.IntrinsicElements[HTMLTagName] & ConditionalProps
  >(({ if: fi = true, ...props }, ref) =>
    fi
      ? React.createElement(
          Component,
          {
            ...props,
            ref,
          },
          props.children,
        )
      : null,
  );

/**
 * Those are augmented versions of HTML elements that can be conditionally rendered.
 * You can add more elements by following the same pattern.
 */
const HTML = {
  Div: withAugments("div"),
  Span: withAugments("span"),
  H1: withAugments("h1"),
  P: withAugments("p"),
  Img: withAugments("img"),
  Label: withAugments("label"),
  Button: withAugments("button"),
  Input: withAugments("input"),
  // Add other elements if needed
};

export default HTML;
