import { z } from "zod";

import { ShowroomSeasonList } from "./types/enums";

export const orderDeadlineRequestSchema = z.object({
  contactId: z.string(),
  brandId: z.string(),
  season: z.enum(ShowroomSeasonList),
  year: z.number(),
});
export type OrderDeadlineRequest = z.infer<typeof orderDeadlineRequestSchema>;
