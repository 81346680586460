import { useMutation } from "@tanstack/react-query";

import { Organization } from "@models/Organization";
import { OrganizationContact } from "@models/OrganizationContact";
import axiosInstance from "@services/api/config";

export const putRestoreContactEndpoint = {
  path: (
    organizationId: Organization["id"],
    contactId: OrganizationContact["id"],
  ) => `/organizations/${organizationId}/contacts/${contactId}/restore`,
};

export function usePutRestoreContact(organizationId: string) {
  return useMutation({
    mutationFn: (contactId: string) =>
      axiosInstance.put(
        putRestoreContactEndpoint.path(organizationId, contactId),
      ),
  });
}
