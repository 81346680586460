import { z } from "zod";

export const contactSchema = z.object({
  id: z.string().uuid(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  phoneNumber: z.string().nullable(),
});

export type Contact = z.infer<typeof contactSchema>;
