import React from "react";

import { GetOrderDeadline } from "@services/api/order-deadline/get-order-deadline";

import OrderDeadlineDisplay from "./OrderDeadlineDisplay";

export interface OrderDeadlineFetchAndDisplayProps {
  brandId: string;
  season: string;
  year: number;
}

function OrderDeadlineFetchAndDisplay({
  brandId,
  season,
  year,
}: OrderDeadlineFetchAndDisplayProps) {
  const { data: orderDeadline } = GetOrderDeadline.useHook({
    brandId,
    season,
    year,
  });

  return (
    <OrderDeadlineDisplay
      deadline={orderDeadline?.deadline}
      brandId={brandId}
      season={season}
      year={year}
    />
  );
}

export default OrderDeadlineFetchAndDisplay;
