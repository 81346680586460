/* eslint-disable no-param-reassign */
import React from "react";

import GjsEditor from "@grapesjs/react";
import { Editor } from "grapesjs";

export default function MJMLEditor() {
  const onEditor = (editor: Editor) => {
    console.log("Editor loaded", { editor });
  };

  return (
    <GjsEditor
      grapesjs="https://unpkg.com/grapesjs"
      grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
      options={{
        height: "100vh",
        storageManager: false,
        richTextEditor: {
          actions: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            // @ts-ignore
            {
              name: "custom-vars",
              icon: `<select class="gjs-field">
                      <option value="">- Dynamic Variables -</option>
                      <option value="[[firstname]]">FirstName</option>
                      <option value="[[lastname]]">LastName</option>
                      <option value="[[age]]">Age</option>
                    </select>`,
              // Bind the 'result' on 'change' listener
              event: "change",
              result: (rte: any, action: any) =>
                rte.insertHTML(action.btn.firstChild.value),
              // Reset the select on change
              update: (_rte: any, action: any) => {
                // @ts-ignore @eslint-disable-next-line import/no-param-reassign
                action.btn.firstChild.value = "";
              },
            },
          ],
        },
      }}
      plugins={[
        {
          id: "gjs-blocks-basic",
          src: "https://unpkg.com/grapesjs-blocks-basic",
        },
      ]}
      onEditor={onEditor}
    />
  );
}
