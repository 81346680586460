import { AccountFilters } from "@app/modules/crm/components/accounts/accounts-filter";
import {
  AccountStatus,
  OrganizationAccount,
} from "@models/OrganizationAccount";
import { Portfolio } from "@models/Portfolio";

function filterAccounts<
  Row extends {
    name: OrganizationAccount["name"];
    city: OrganizationAccount["city"];
    type: OrganizationAccount["type"];
    collections: { status: AccountStatus }[];
    portfolios: Pick<Portfolio, "id" | "name">[];
    isKeyClient?: OrganizationAccount["isKeyClient"];
  },
>(accounts: Row[], filters: AccountFilters): Row[] {
  const { text, accountStatus, portfolios } = filters;

  return accounts.filter((account) => {
    if (text && text.length > 0) {
      const textLower = text.toLowerCase();

      const hasName = account.name.toLowerCase().includes(textLower);
      const hasCity = account.city.toLowerCase().includes(textLower);
      const hasType = account.type.toLowerCase().includes(textLower);

      if (!(hasName || hasCity || hasType)) {
        return false;
      }
    }

    if (account.collections && accountStatus) {
      if (account.collections!.every((c) => c.status !== accountStatus))
        return false;
    }

    if (account.portfolios && portfolios && portfolios.length > 0) {
      const filterPortfoliosIds = portfolios.map((p) => p.id);
      const accountPortfolios = account.portfolios.map((p) => p.id);
      if (accountPortfolios.every((ap) => !filterPortfoliosIds.includes(ap)))
        return false;
    }

    if (!account.isKeyClient && filters.isKeyClient) {
      return false;
    }

    if (!account.isKeyClient && filters.isKeyClient) {
      return false;
    }

    return true;
  });
}

export default filterAccounts;
