import { useState } from "react";

import { AccountStatus } from "@models/OrganizationAccount";
import { InvitationStatusEnum, MarketType } from "@models/types/enums";
import { fullName } from "@shared/helpers/formatters";

type ANY = "any";

type FiltersValues = {
  wildcard: string;
  invitationStatus: InvitationStatusEnum | ANY;
  showroomId: string | ANY;
  accountStatus: AccountStatus | ANY;
  collectionId: string | ANY;
  market: MarketType | ANY;
  portfolioId: string | ANY;
  isKeyAccountFilter: boolean | undefined;
};

export function useInvitationFilter() {
  const [wildcard, setWildcard] = useState<FiltersValues["wildcard"]>("");

  const [invitationStatus, setInvitationStatus] =
    useState<FiltersValues["invitationStatus"]>("any");
  const [showroomId, setShowroomId] =
    useState<FiltersValues["showroomId"]>("any");

  const [accountStatus, setAccountStatus] =
    useState<FiltersValues["accountStatus"]>("any");
  const [collectionId, setCollectionId] =
    useState<FiltersValues["collectionId"]>("any");

  const [market, setMarket] = useState<FiltersValues["market"]>("any");

  const [portfolioId, setPortfolioId] =
    useState<FiltersValues["portfolioId"]>("any");

  const [isKeyAccountFilter, setIsKeyAccountFilter] = useState<boolean>();

  return {
    count:
      (wildcard === "" ? 0 : 1) +
      (invitationStatus === "any" ? 0 : 1) +
      (showroomId === "any" ? 0 : 1) +
      (accountStatus === "any" ? 0 : 1) +
      (collectionId === "any" ? 0 : 1) +
      (market === "any" ? 0 : 1) +
      (portfolioId === "any" ? 0 : 1) +
      (isKeyAccountFilter === undefined ? 0 : 1),
    values: {
      wildcard,
      invitationStatus,
      showroomId,
      accountStatus,
      collectionId,
      market,
      portfolioId,
      isKeyAccountFilter,
    },
    setWildcard,
    setShowroomId,
    setAccountStatus,
    setCollectionId,
    setMarket,
    setPortfolioId,
    setInvitationStatus,
    setIsKeyAccountFilter,
  };
}

type Row = {
  firstName: string;
  lastName: string;
  email: string;
  position: string | null;
  markets: MarketType[];
  portfolios: {
    id: string;
  }[];
  account: {
    name: string;
    isKeyClient: boolean;
    collectionInformationByAccounts: {
      collectionId: string;
      status: AccountStatus;
    }[];
  };
  invitationsPerShowroom: Record<
    string,
    { id: string; status: string; emailStatus: string } | undefined
  >;
};

export function filterInvitations<R extends Row>(
  invitationRows: R[],
  filters: FiltersValues,
) {
  return invitationRows.filter((row) => {
    // invitation filter
    if (filters.invitationStatus !== "any") {
      // on a specific showroom
      if (filters.showroomId !== "any") {
        // filter out if showroom invitation status does not match filtered status
        const invitation = row.invitationsPerShowroom[filters.showroomId];
        if (!invitation || invitation.status !== filters.invitationStatus) {
          return false;
        }
      } else {
        // filter out if no invitation matches the filtered status
        const hasMatchingInvitation = Object.values(
          row.invitationsPerShowroom,
        ).some((i) => i?.status === filters.invitationStatus);
        if (!hasMatchingInvitation) {
          return false;
        }
      }
    }

    // account status with collection filter
    if (filters.accountStatus !== "any") {
      const matchingStatuses =
        row.account.collectionInformationByAccounts.filter(
          (c) => c.status === filters.accountStatus,
        );

      if (filters.collectionId !== "any") {
        if (
          !matchingStatuses.some((c) => c.collectionId === filters.collectionId)
        ) {
          return false;
        }
      } else if (matchingStatuses.length === 0) {
        return false;
      }
    }

    // market filter
    if (filters.market !== "any") {
      if (!row.markets.includes(filters.market)) {
        return false;
      }
    }

    // portfolio filter
    if (filters.portfolioId !== "any") {
      if (!row.portfolios.some((p) => p.id === filters.portfolioId)) {
        return false;
      }
    }

    // key accounts filter
    if (filters.isKeyAccountFilter !== undefined) {
      if (filters.isKeyAccountFilter !== row.account.isKeyClient) {
        return false;
      }
    }

    // wildcard filter
    if (filters.wildcard !== "") {
      const wildcard = filters.wildcard.toLowerCase();
      if (
        !row.account.name.toLowerCase().includes(wildcard) &&
        !fullName(row).toLowerCase().includes(wildcard) &&
        !(row.position || "").toLowerCase().includes(wildcard) &&
        !row.email.toLowerCase().includes(wildcard)
      ) {
        return false;
      }
    }

    return true;
  });
}
