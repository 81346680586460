import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { appointmentHydratingSchema } from "@models/Appointment";
import { collectionSchema } from "@models/Collection";
import { meetingReportSchema } from "@models/MeetingReport";
import { organizationAccountSchema } from "@models/OrganizationAccount";
import { organizationContactSchema } from "@models/OrganizationContact";
import {
  organizationRepresentativeSchema,
  representativeSchema,
} from "@models/OrganizationRepresentative";
import { portfolioSchema } from "@models/Portfolio";
import { showroomSchema } from "@models/Showroom";
import { zonedDateSerializingSchema } from "@models/types/Dates";
import {
  VirtualMeetingAppsList,
  appointmentFormatSchema,
  appointmentTypeSchema,
} from "@models/types/enums";
import axiosInstance from "@services/api/config";

export namespace BookingBookAppointmentEndpoint {
  export const inputSchema = z.object({
    bookingId: z.string().optional(),
    showroom: z.object({
      id: z.string(),
    }),
    seller: z.object({
      id: z.string(),
    }),
    collection: z
      .object({
        id: z.string(),
      })
      .nullable(),
    account: z.object({
      id: z.string(),
    }),
    attendees: z
      .array(
        z.object({
          id: z.string(),
        }),
      )
      .nonempty(),
    startTime: zonedDateSerializingSchema,
    endTime: zonedDateSerializingSchema,
    format: appointmentFormatSchema,
    virtualMeetingApp: z.enum(VirtualMeetingAppsList).nullable(),
    type: appointmentTypeSchema,
    accountOtb: z.number().nullable(),
    collectionInterests: z.array(
      z.object({
        id: z.string().uuid(),
      }),
    ),
  });
  export type Input = z.input<typeof inputSchema>;

  export const outputSchema = appointmentHydratingSchema
    .pick({
      id: true,
      accountOtb: true,
      startTime: true,
      endTime: true,
      format: true,
      title: true,
      type: true,
      virtualMeetingApp: true,
      warnings: true,
    })
    .extend({
      meetingReport: meetingReportSchema
        .pick({
          otb: true,
          actualBudget: true,
          notes: true,
          appointmentId: true,
        })
        .nullable(),
      collection: collectionSchema
        .pick({
          id: true,
          name: true,
          brandId: true,
        })
        .nullable(),
      account: organizationAccountSchema.pick({
        city: true,
        countryCode: true,
        id: true,
        name: true,
        status: true,
        type: true,
      }),
      attendees: z.array(
        organizationContactSchema.pick({
          email: true,
          firstName: true,
          id: true,
          lastName: true,
          markets: true,
          phoneNumber: true,
          position: true,
        }),
      ),
      portfolios: z.array(
        portfolioSchema
          .pick({
            brandId: true,
            collectionId: true,
            color: true,
            id: true,
            name: true,
          })
          .extend({
            manager: organizationRepresentativeSchema.pick({
              firstName: true,
              id: true,
              lastName: true,
              role: true,
            }),
            sellers: z.array(
              organizationRepresentativeSchema.pick({
                firstName: true,
                id: true,
                lastName: true,
                role: true,
                virtualMeetingApps: true,
              }),
            ),
          }),
      ),
      seller: representativeSchema.pick({
        email: true,
        firstName: true,
        id: true,
        languages: true,
        lastName: true,
        virtualMeetingAppLinks: true,
      }),
      showroom: showroomSchema.pick({
        city: true,
        countryCode: true,
        directions: true,
        formattedAddress: true,
        id: true,
        timezone: true,
      }),
    });
  export type Output = z.infer<typeof outputSchema>;
  interface HookParams {
    invitationId: string;
  }

  interface MutationParams {
    data: Input;
  }

  type Params = HookParams & MutationParams;

  export const path = ({ invitationId }: Params) =>
    `/bookings/${invitationId}/appointments`;

  export const call = (params: Params) =>
    axiosInstance
      .post(path(params), inputSchema.parse(params.data))
      .then((res) => outputSchema.parse(res.data))
      .catch((err) => {
        if (err.issues) {
          console.error(err.issues);
        }
        throw err;
      });

  export function useHook(hookParams: HookParams) {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: (mutationParams: MutationParams) =>
        call({
          ...mutationParams,
          ...hookParams,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [],
        });
      },
    });
  }
}
