import React from "react";

import { useTranslation } from "react-i18next";

import BuyerInvitationRowsDisplay from "@app/modules/invitation/BuyerInvitationRowsDisplay";
import { PageHeader, PageTitle } from "@components/data-display/Page";
import Loading from "@components/feedback/Loading";
import { GetBuyerInvitations } from "@services/api/invitations/GetBuyerInvitations";
import { withBuyerOnly } from "@shared/components/guards/authentication";

function BuyerInvitationsPage() {
  const {
    data: buyerInvitations,
    status: buyerInvitationsStatus,
    error: buyerInvitationsError,
  } = GetBuyerInvitations.useHook();

  const { t } = useTranslation();

  // display error page
  if (buyerInvitationsError) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <p className="text-3xl mb-4 font-bold text-primaryElectricBlue">
            {t("BuyerAccount.buyer-invitations.error-title")}
          </p>
          <p className="text-lg">
            {t("BuyerAccount.buyer-invitations.error-message")}
          </p>
        </div>
      </div>
    );
  }

  return buyerInvitationsStatus === "pending" ? (
    <div className="flex items-center justify-center h-screen">
      <Loading type="screen" />
    </div>
  ) : (
    <div>
      {buyerInvitations.length > 0 ? (
        <>
          <PageHeader>
            <div className="flex flex-row ">
              <PageTitle>
                {t("BuyerAccount.buyer-invitations.title.my-invites")}
              </PageTitle>
            </div>
          </PageHeader>
          <div className="w-full">
            <div className="pl-7 mt-7 mb-2 grid grid-cols-8">
              <p className="pl-3 font-light text-left text-sm col-span-1">
                {t("BuyerAccount.buyer-invitations.table-header.brand")}
              </p>
              <p className="font-light text-left text-sm col-span-1">
                {t("BuyerAccount.buyer-invitations.table-header.opening-days")}
              </p>
              <p className="font-light text-left text-sm col-span-1">
                {t("BuyerAccount.buyer-invitations.table-header.location")}
              </p>
              <p className="font-light text-left text-sm col-span-1">
                {t("BuyerAccount.buyer-invitations.table-header.season")}
              </p>
              <p className="font-light text-left text-sm col-span-1">
                {t("BuyerAccount.buyer-invitations.table-header.status")}
              </p>
              <p className="font-light text-left text-sm col-span-1">
                {t(
                  "BuyerAccount.buyer-invitations.table-header.order-deadline",
                )}
              </p>
              <p className="font-light text-left text-sm col-span-1">
                {t("BuyerAccount.buyer-invitations.table-header.appointments")}
              </p>
              <p className="font-light text-left text-sm col-span-1">
                {t("BuyerAccount.buyer-invitations.table-header.actions")}
              </p>
            </div>
            <hr />
            <div className="px-8 pt-1">
              {buyerInvitations.map((buyerInvitation, i) => (
                <BuyerInvitationRowsDisplay
                  key={i}
                  buyerInvitation={buyerInvitation}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-screen">
          <div className="text-center">
            <p className="text-3xl mb-4 font-bold text-primaryElectricBlue">
              No Data
            </p>
            <p className="text-lg">
              {t("BuyerAccount.buyer-invitations.empty")}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default withBuyerOnly(BuyerInvitationsPage);
