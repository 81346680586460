import {
  countries as countriesList,
  name as countryName,
  flag,
} from "country-emoji";

const countries: Record<string, any> = {
  AF: {
    code: "AF",
    name: "Afghanistan",
    phoneNumberPrefix: "+93",
    continentCode: "AS",
  },
  AL: {
    code: "AL",
    name: "Albania",
    phoneNumberPrefix: "+355",
    continentCode: "EU",
  },
  DZ: {
    code: "DZ",
    name: "Algeria",
    phoneNumberPrefix: "+213",
    continentCode: "AF",
  },
  AS: {
    name: "American Samoa",
    phoneNumberPrefix: "+1-684",
    continentCode: "OC",
  },
  AD: {
    code: "AD",
    name: "Andorra",
    phoneNumberPrefix: "+376",
    continentCode: "EU",
  },
  AO: {
    code: "AO",
    name: "Angola",
    phoneNumberPrefix: "+244",
    continentCode: "AF",
  },
  AI: {
    code: "AI",
    name: "Anguilla",
    phoneNumberPrefix: "+1-264",
    continentCode: "NA",
  },
  AG: {
    name: "Antigua and Barbuda",
    phoneNumberPrefix: "+1-268",
    continentCode: "NA",
  },
  AR: {
    code: "AR",
    name: "Argentina",
    phoneNumberPrefix: "+54",
    continentCode: "SA",
  },
  AM: {
    code: "AM",
    name: "Armenia",
    phoneNumberPrefix: "+374",
    continentCode: "AS",
  },
  AW: {
    code: "AW",
    name: "Aruba",
    phoneNumberPrefix: "+297",
    continentCode: "NA",
  },
  AU: {
    code: "AU",
    name: "Australia",
    phoneNumberPrefix: "+61",
    continentCode: "OC",
  },
  AT: {
    code: "AT",
    name: "Austria",
    phoneNumberPrefix: "+43",
    continentCode: "EU",
  },
  AZ: {
    code: "AZ",
    name: "Azerbaijan",
    phoneNumberPrefix: "+994",
    continentCode: "AS",
  },
  BS: {
    code: "BS",
    name: "Bahamas",
    phoneNumberPrefix: "+1-242",
    continentCode: "NA",
  },
  BH: {
    code: "BH",
    name: "Bahrain",
    phoneNumberPrefix: "+973",
    continentCode: "AS",
  },
  BD: {
    code: "BD",
    name: "Bangladesh",
    phoneNumberPrefix: "+880",
    continentCode: "AS",
  },
  BB: {
    code: "BB",
    name: "Barbados",
    phoneNumberPrefix: "+1-246",
    continentCode: "NA",
  },
  BY: {
    code: "BY",
    name: "Belarus",
    phoneNumberPrefix: "+375",
    continentCode: "EU",
  },
  BE: {
    code: "BE",
    name: "Belgium",
    phoneNumberPrefix: "+32",
    continentCode: "EU",
  },
  BZ: {
    code: "BZ",
    name: "Belize",
    phoneNumberPrefix: "+501",
    continentCode: "NA",
  },
  BJ: {
    code: "BJ",
    name: "Benin",
    phoneNumberPrefix: "+229",
    continentCode: "AF",
  },
  BM: {
    code: "BM",
    name: "Bermuda",
    phoneNumberPrefix: "+1-441",
    continentCode: "NA",
  },
  BT: {
    code: "BT",
    name: "Bhutan",
    phoneNumberPrefix: "+975",
    continentCode: "AS",
  },
  BO: {
    code: "BO",
    name: "Bolivia",
    phoneNumberPrefix: "+591",
    continentCode: "SA",
  },
  BA: {
    name: "Bosnia and Herzegovina",
    phoneNumberPrefix: "+387",
    continentCode: "EU",
  },
  BW: {
    code: "BW",
    name: "Botswana",
    phoneNumberPrefix: "+267",
    continentCode: "AF",
  },
  BR: {
    code: "BR",
    name: "Brazil",
    phoneNumberPrefix: "+55",
    continentCode: "SA",
  },
  BN: {
    code: "BN",
    name: "Brunei",
    phoneNumberPrefix: "+673",
    continentCode: "AS",
  },
  BG: {
    code: "BG",
    name: "Bulgaria",
    phoneNumberPrefix: "+359",
    continentCode: "EU",
  },
  BF: {
    code: "BF",
    name: "Burkina Faso",
    phoneNumberPrefix: "+226",
    continentCode: "AF",
  },
  BI: {
    code: "BI",
    name: "Burundi",
    phoneNumberPrefix: "+257",
    continentCode: "AF",
  },
  KH: {
    code: "KH",
    name: "Cambodia",
    phoneNumberPrefix: "+855",
    continentCode: "AS",
  },
  CM: {
    code: "CM",
    name: "Cameroon",
    phoneNumberPrefix: "+237",
    continentCode: "AF",
  },
  CA: {
    code: "CA",
    name: "Canada",
    phoneNumberPrefix: "+1",
    continentCode: "NA",
  },
  CV: {
    code: "CV",
    name: "Cape Verde",
    phoneNumberPrefix: "+238",
    continentCode: "AF",
  },
  KY: {
    name: "Cayman Islands",
    phoneNumberPrefix: "+1-345",
    continentCode: "NA",
  },
  CF: {
    name: "Central African Republic",
    phoneNumberPrefix: "+236",
    continentCode: "AF",
  },
  TD: {
    code: "TD",
    name: "Chad",
    phoneNumberPrefix: "+235",
    continentCode: "AF",
  },
  CL: {
    code: "CL",
    name: "Chile",
    phoneNumberPrefix: "+56",
    continentCode: "SA",
  },
  CN: {
    code: "CN",
    name: "China",
    phoneNumberPrefix: "+86",
    continentCode: "AS",
  },
  CX: {
    name: "Christmas Island",
    phoneNumberPrefix: "+53",
    continentCode: "AS",
  },
  CC: {
    code: "CC",
    name: "Cocos Islands",
    phoneNumberPrefix: "+61",
    continentCode: "AS",
  },
  CO: {
    code: "CO",
    name: "Colombia",
    phoneNumberPrefix: "+57",
    continentCode: "SA",
  },
  KM: {
    code: "KM",
    name: "Comoros",
    phoneNumberPrefix: "+269",
    continentCode: "AF",
  },
  CD: {
    name: "Democratic Republic of the Congo",
    phoneNumberPrefix: "+243",
    continentCode: "AF",
  },
  CG: {
    name: "Republic of the Congo",
    phoneNumberPrefix: "+242",
    continentCode: "AF",
  },
  CK: {
    code: "CK",
    name: "Cook Islands",
    phoneNumberPrefix: "+682",
    continentCode: "OC",
  },
  CR: {
    code: "CR",
    name: "Costa Rica",
    phoneNumberPrefix: "+506",
    continentCode: "NA",
  },
  CI: {
    code: "CI",
    name: "Ivory Coast",
    phoneNumberPrefix: "+225",
    continentCode: "AF",
  },
  HR: {
    code: "HR",
    name: "Croatia",
    phoneNumberPrefix: "+385",
    continentCode: "EU",
  },
  CU: {
    code: "CU",
    name: "Cuba",
    phoneNumberPrefix: "+53",
    continentCode: "NA",
  },
  CY: {
    code: "CY",
    name: "Cyprus",
    phoneNumberPrefix: "+357",
    continentCode: "AS",
  },
  CZ: {
    name: "Czech Republic",
    phoneNumberPrefix: "+420",
    continentCode: "EU",
  },
  DK: {
    code: "DK",
    name: "Denmark",
    phoneNumberPrefix: "+45",
    continentCode: "EU",
  },
  DJ: {
    code: "DJ",
    name: "Djibouti",
    phoneNumberPrefix: "+253",
    continentCode: "AF",
  },
  DM: {
    code: "DM",
    name: "Dominica",
    phoneNumberPrefix: "+1-767",
    continentCode: "NA",
  },
  DO: {
    name: "Dominican Republic",
    phoneNumberPrefix: "+1-809 ",
    continentCode: "NA",
  },
  TP: { code: "TP", name: "East Timor", phoneNumberPrefix: "+670" },
  EC: {
    code: "EC",
    name: "Ecuador",
    phoneNumberPrefix: "+593",
    continentCode: "SA",
  },
  EG: {
    code: "EG",
    name: "Egypt",
    phoneNumberPrefix: "+20",
    continentCode: "AF",
  },
  SV: {
    code: "SV",
    name: "El Salvador",
    phoneNumberPrefix: "+503",
    continentCode: "NA",
  },
  GQ: {
    name: "Equatorial Guinea",
    phoneNumberPrefix: "+240",
    continentCode: "AF",
  },
  ER: {
    code: "ER",
    name: "Eritrea",
    phoneNumberPrefix: "+291",
    continentCode: "AF",
  },
  EE: {
    code: "EE",
    name: "Estonia",
    phoneNumberPrefix: "+372",
    continentCode: "EU",
  },
  ET: {
    code: "ET",
    name: "Ethiopia",
    phoneNumberPrefix: "+251",
    continentCode: "AF",
  },
  FK: {
    name: "Falkland Islands",
    phoneNumberPrefix: "+500",
    continentCode: "SA",
  },
  FO: {
    name: "Faroe Islands",
    phoneNumberPrefix: "+298",
    continentCode: "EU",
  },
  FJ: {
    code: "FJ",
    name: "Fiji",
    phoneNumberPrefix: "+679",
    continentCode: "OC",
  },
  FI: {
    code: "FI",
    name: "Finland",
    phoneNumberPrefix: "+358",
    continentCode: "EU",
  },
  FR: {
    code: "FR",
    name: "France",
    phoneNumberPrefix: "+33",
    continentCode: "EU",
  },
  GF: {
    code: "GF",
    name: "French Guiana",
    phoneNumberPrefix: "+594",
    continentCode: "SA",
  },
  PF: {
    name: "French Polynesia",
    phoneNumberPrefix: "+689",
    continentCode: "OC",
  },
  GA: {
    code: "GA",
    name: "Gabon",
    phoneNumberPrefix: "+241",
    continentCode: "AF",
  },
  GM: {
    code: "GM",
    name: "Gambia",
    phoneNumberPrefix: "+220",
    continentCode: "AF",
  },
  GE: {
    code: "GE",
    name: "Georgia",
    phoneNumberPrefix: "+995",
    continentCode: "AS",
  },
  DE: {
    code: "DE",
    name: "Germany",
    phoneNumberPrefix: "+49",
    continentCode: "EU",
  },
  GH: {
    code: "GH",
    name: "Ghana",
    phoneNumberPrefix: "+233",
    continentCode: "AF",
  },
  GI: {
    code: "GI",
    name: "Gibraltar",
    phoneNumberPrefix: "+350",
    continentCode: "EU",
  },
  GR: {
    code: "GR",
    name: "Greece",
    phoneNumberPrefix: "+30",
    continentCode: "EU",
  },
  GL: {
    code: "GL",
    name: "Greenland",
    phoneNumberPrefix: "+299",
    continentCode: "NA",
  },
  GD: {
    code: "GD",
    name: "Grenada",
    phoneNumberPrefix: "+1-473",
    continentCode: "NA",
  },
  GP: {
    code: "GP",
    name: "Guadeloupe",
    phoneNumberPrefix: "+590",
    continentCode: "NA",
  },
  GU: {
    code: "GU",
    name: "Guam",
    phoneNumberPrefix: "+1-671",
    continentCode: "OC",
  },
  GT: {
    code: "GT",
    name: "Guatemala",
    phoneNumberPrefix: "+502",
    continentCode: "NA",
  },
  GN: {
    code: "GN",
    name: "Guinea",
    phoneNumberPrefix: "+224",
    continentCode: "AF",
  },
  GW: {
    name: "Guinea-Bissau",
    phoneNumberPrefix: "+245",
    continentCode: "AF",
  },
  GY: {
    code: "GY",
    name: "Guyana",
    phoneNumberPrefix: "+592",
    continentCode: "SA",
  },
  HT: {
    code: "HT",
    name: "Haiti",
    phoneNumberPrefix: "+509",
    continentCode: "NA",
  },
  HN: {
    code: "HN",
    name: "Honduras",
    phoneNumberPrefix: "+504",
    continentCode: "NA",
  },
  HK: {
    code: "HK",
    name: "Hong Kong",
    phoneNumberPrefix: "+852",
    continentCode: "AS",
  },
  HU: {
    code: "HU",
    name: "Hungary",
    phoneNumberPrefix: "+36",
    continentCode: "EU",
  },
  IS: {
    code: "IS",
    name: "Iceland",
    phoneNumberPrefix: "+354",
    continentCode: "EU",
  },
  IN: {
    code: "IN",
    name: "India",
    phoneNumberPrefix: "+91",
    continentCode: "AS",
  },
  ID: {
    code: "ID",
    name: "Indonesia",
    phoneNumberPrefix: "+62",
    continentCode: "AS",
  },
  IR: {
    code: "IR",
    name: "Iran",
    phoneNumberPrefix: "+98",
    continentCode: "AS",
  },
  IQ: {
    code: "IQ",
    name: "Iraq",
    phoneNumberPrefix: "+964",
    continentCode: "AS",
  },
  IE: {
    code: "IE",
    name: "Ireland",
    phoneNumberPrefix: "+353",
    continentCode: "EU",
  },
  IL: {
    code: "IL",
    name: "Israel",
    phoneNumberPrefix: "+972",
    continentCode: "AS",
  },
  IT: {
    code: "IT",
    name: "Italy",
    phoneNumberPrefix: "+39",
    continentCode: "EU",
  },
  JM: {
    code: "JM",
    name: "Jamaica",
    phoneNumberPrefix: "+1-876",
    continentCode: "NA",
  },
  JP: {
    code: "JP",
    name: "Japan",
    phoneNumberPrefix: "+81",
    continentCode: "AS",
  },
  JO: {
    code: "JO",
    name: "Jordan",
    phoneNumberPrefix: "+962",
    continentCode: "AS",
  },
  KZ: {
    code: "KZ",
    name: "kazakhstan",
    phoneNumberPrefix: "+7",
    continentCode: "AS",
  },
  KE: {
    code: "KE",
    name: "Kenya",
    phoneNumberPrefix: "+254",
    continentCode: "AF",
  },
  KI: {
    code: "KI",
    name: "Kiribati",
    phoneNumberPrefix: "+686",
    continentCode: "OC",
  },
  KP: {
    code: "KP",
    name: "North Korea",
    phoneNumberPrefix: "+850",
    continentCode: "AS",
  },
  KR: {
    code: "KR",
    name: "South Korea",
    phoneNumberPrefix: "+82",
    continentCode: "AS",
  },
  KW: {
    code: "KW",
    name: "Kuwait",
    phoneNumberPrefix: "+965",
    continentCode: "AS",
  },
  KG: {
    code: "KG",
    name: "Kyrgyzstan",
    phoneNumberPrefix: "+996",
    continentCode: "AS",
  },
  LA: {
    code: "LA",
    name: "Laos",
    phoneNumberPrefix: "+856",
    continentCode: "AS",
  },
  LV: {
    code: "LV",
    name: "Latvia",
    phoneNumberPrefix: "+371",
    continentCode: "EU",
  },
  LB: {
    code: "LB",
    name: "Lebanon",
    phoneNumberPrefix: "+961",
    continentCode: "AS",
  },
  LS: {
    code: "LS",
    name: "Lesotho",
    phoneNumberPrefix: "+266",
    continentCode: "AF",
  },
  LR: {
    code: "LR",
    name: "Liberia",
    phoneNumberPrefix: "+231",
    continentCode: "AF",
  },
  LY: {
    code: "LY",
    name: "Libya",
    phoneNumberPrefix: "+218",
    continentCode: "AF",
  },
  LI: {
    name: "Liechtenstein",
    phoneNumberPrefix: "+423",
    continentCode: "EU",
  },
  LT: {
    code: "LT",
    name: "Lithuania",
    phoneNumberPrefix: "+370",
    continentCode: "EU",
  },
  LU: {
    code: "LU",
    name: "Luxembourg",
    phoneNumberPrefix: "+352",
    continentCode: "EU",
  },
  MO: {
    code: "MO",
    name: "Macau",
    phoneNumberPrefix: "+853",
    continentCode: "AS",
  },
  MK: {
    code: "MK",
    name: "Macedonia",
    phoneNumberPrefix: "+389",
    continentCode: "EU",
  },
  MG: {
    code: "MG",
    name: "Madagascar",
    phoneNumberPrefix: "+261",
    continentCode: "AF",
  },
  MW: {
    code: "MW",
    name: "Malawi",
    phoneNumberPrefix: "+265",
    continentCode: "AF",
  },
  MY: {
    code: "MY",
    name: "Malaysia",
    phoneNumberPrefix: "+60",
    continentCode: "AS",
  },
  MV: {
    code: "MV",
    name: "Maldives",
    phoneNumberPrefix: "+960",
    continentCode: "AS",
  },
  ML: {
    code: "ML",
    name: "Mali",
    phoneNumberPrefix: "+223",
    continentCode: "AF",
  },
  MT: {
    code: "MT",
    name: "Malta",
    phoneNumberPrefix: "+356",
    continentCode: "EU",
  },
  MH: {
    name: "Marshall Islands",
    phoneNumberPrefix: "+692",
    continentCode: "OC",
  },
  MQ: {
    code: "MQ",
    name: "Martinique",
    phoneNumberPrefix: "+596",
    continentCode: "NA",
  },
  MR: {
    code: "MR",
    name: "Mauritania",
    phoneNumberPrefix: "+222",
    continentCode: "AF",
  },
  MU: {
    code: "MU",
    name: "Mauritius",
    phoneNumberPrefix: "+230",
    continentCode: "AF",
  },
  YT: {
    code: "YT",
    name: "Mayotte",
    phoneNumberPrefix: "+269",
    continentCode: "AF",
  },
  MX: {
    code: "MX",
    name: "Mexico",
    phoneNumberPrefix: "+52",
    continentCode: "NA",
  },
  FM: {
    code: "FM",
    name: "Micronesia",
    phoneNumberPrefix: "+691",
    continentCode: "OC",
  },
  MD: {
    code: "MD",
    name: "Moldova",
    phoneNumberPrefix: "+373",
    continentCode: "EU",
  },
  MC: {
    code: "MC",
    name: "Monaco",
    phoneNumberPrefix: "+377",
    continentCode: "EU",
  },
  ME: {
    code: "ME",
    name: "Montenegro",
    phoneNumberPrefix: "+382",
    continentCode: "EU",
  },
  MN: {
    code: "MN",
    name: "Mongolia",
    phoneNumberPrefix: "+976",
    continentCode: "AS",
  },
  MS: {
    code: "MS",
    name: "Montserrat",
    phoneNumberPrefix: "+1-664",
    continentCode: "NA",
  },
  MA: {
    code: "MA",
    name: "Morocco",
    phoneNumberPrefix: "+212",
    continentCode: "AF",
  },
  MZ: {
    code: "MZ",
    name: "Mozambique",
    phoneNumberPrefix: "+258",
    continentCode: "AF",
  },
  MM: {
    code: "MM",
    name: "Myanmar",
    phoneNumberPrefix: "+95",
    continentCode: "AS",
  },
  NA: {
    code: "NA",
    name: "Namibia",
    phoneNumberPrefix: "+264",
    continentCode: "AF",
  },
  NR: {
    code: "NR",
    name: "Nauru",
    phoneNumberPrefix: "+674",
    continentCode: "OC",
  },
  NP: {
    code: "NP",
    name: "Nepal",
    phoneNumberPrefix: "+977",
    continentCode: "AS",
  },
  NL: {
    code: "NL",
    name: "Netherlands",
    phoneNumberPrefix: "+31",
    continentCode: "EU",
  },
  AN: {
    name: "Netherlands Antilles",
    phoneNumberPrefix: "+599",
    continentCode: "NA",
  },
  NC: {
    name: "New Caledonia",
    phoneNumberPrefix: "+687",
    continentCode: "OC",
  },
  NZ: {
    code: "NZ",
    name: "New Zealand",
    phoneNumberPrefix: "+64",
    continentCode: "OC",
  },
  NI: {
    code: "NI",
    name: "Nicaragua",
    phoneNumberPrefix: "+505",
    continentCode: "NA",
  },
  NE: {
    code: "NE",
    name: "Niger",
    phoneNumberPrefix: "+227",
    continentCode: "AF",
  },
  NG: {
    code: "NG",
    name: "Nigeria",
    phoneNumberPrefix: "+234",
    continentCode: "AF",
  },
  NU: {
    code: "NU",
    name: "Niue",
    phoneNumberPrefix: "+683",
    continentCode: "OC",
  },
  NF: {
    name: "Norfolk Island",
    phoneNumberPrefix: "+672",
    continentCode: "OC",
  },
  MP: {
    name: "Northern Mariana Islands",
    phoneNumberPrefix: "+1-670",
    continentCode: "OC",
  },
  NO: {
    code: "NO",
    name: "Norway",
    phoneNumberPrefix: "+47",
    continentCode: "EU",
  },
  OM: {
    code: "OM",
    name: "Oman",
    phoneNumberPrefix: "+968",
    continentCode: "AS",
  },
  PK: {
    code: "PK",
    name: "Pakistan",
    phoneNumberPrefix: "+92",
    continentCode: "AS",
  },
  PW: {
    code: "PW",
    name: "Palau",
    phoneNumberPrefix: "+680",
    continentCode: "OC",
  },
  PS: {
    name: "Palestinian State",
    phoneNumberPrefix: "+970",
    continentCode: "AS",
  },
  PA: {
    code: "PA",
    name: "Panama",
    phoneNumberPrefix: "+507",
    continentCode: "NA",
  },
  PG: {
    name: "Papua New Guinea",
    phoneNumberPrefix: "+675",
    continentCode: "OC",
  },
  PY: {
    code: "PY",
    name: "Paraguay",
    phoneNumberPrefix: "+595",
    continentCode: "SA",
  },
  PE: {
    code: "PE",
    name: "Peru",
    phoneNumberPrefix: "+51",
    continentCode: "SA",
  },
  PH: {
    code: "PH",
    name: "Philippines",
    phoneNumberPrefix: "+63",
    continentCode: "AS",
  },
  PL: {
    code: "PL",
    name: "Poland",
    phoneNumberPrefix: "+48",
    continentCode: "EU",
  },
  PT: {
    code: "PT",
    name: "Portugal",
    phoneNumberPrefix: "+351",
    continentCode: "EU",
  },
  PR: {
    name: "Puerto Rico",
    phoneNumberPrefix: "+1-787",
    continentCode: "NA",
  },
  QA: {
    code: "QA",
    name: "Qatar",
    phoneNumberPrefix: "+974",
    continentCode: "AS",
  },
  RE: {
    code: "RE",
    name: "Reunion",
    phoneNumberPrefix: "+262",
    continentCode: "AF",
  },
  RO: {
    code: "RO",
    name: "Romania",
    phoneNumberPrefix: "+40",
    continentCode: "EU",
  },
  RS: {
    code: "RS",
    name: "Serbia",
    phoneNumberPrefix: "+381",
    continentCode: "EU",
  },
  RU: {
    name: "Russian Federation",
    phoneNumberPrefix: "+7",
    continentCode: "EU",
  },
  RW: {
    code: "RW",
    name: "Rwanda",
    phoneNumberPrefix: "+250",
    continentCode: "AF",
  },
  SH: {
    code: "SH",
    name: "Saint Helena",
    phoneNumberPrefix: "+290",
    continentCode: "AF",
  },
  KN: {
    name: "Saint Kitts and Nevis",
    phoneNumberPrefix: "+1-869",
    continentCode: "NA",
  },
  LC: {
    code: "LC",
    name: "Saint Lucia",
    phoneNumberPrefix: "+1-758",
    continentCode: "NA",
  },
  PM: {
    name: "Saint Pierre and Miquelon",
    phoneNumberPrefix: "+508",
    continentCode: "NA",
  },
  VC: {
    name: "Saint Vincent and the Grenadines",
    phoneNumberPrefix: "+1-784",
    continentCode: "NA",
  },
  WS: {
    code: "WS",
    name: "Samoa",
    phoneNumberPrefix: "+685",
    continentCode: "OC",
  },
  SM: {
    code: "SM",
    name: "San Marino",
    phoneNumberPrefix: "+378",
    continentCode: "EU",
  },
  ST: {
    name: "Sao Tome and Principe",
    phoneNumberPrefix: "+239",
    continentCode: "AF",
  },
  SA: {
    code: "SA",
    name: "Saudi Arabia",
    phoneNumberPrefix: "+966",
    continentCode: "AS",
  },
  SN: {
    code: "SN",
    name: "Senegal",
    phoneNumberPrefix: "+221",
    continentCode: "AF",
  },
  SC: {
    code: "SC",
    name: "Seychelles",
    phoneNumberPrefix: "+248",
    continentCode: "AF",
  },
  SL: {
    code: "SL",
    name: "Sierra Leone",
    phoneNumberPrefix: "+232",
    continentCode: "AF",
  },
  SG: {
    code: "SG",
    name: "Singapore",
    phoneNumberPrefix: "+65",
    continentCode: "AS",
  },
  SK: {
    code: "SK",
    name: "Slovakia",
    phoneNumberPrefix: "+421",
    continentCode: "EU",
  },
  SI: {
    code: "SI",
    name: "Slovenia",
    phoneNumberPrefix: "+386",
    continentCode: "EU",
  },
  SB: {
    name: "Solomon Islands",
    phoneNumberPrefix: "+677",
    continentCode: "OC",
  },
  SO: {
    code: "SO",
    name: "Somalia",
    phoneNumberPrefix: "+252",
    continentCode: "AF",
  },
  ZA: {
    code: "ZA",
    name: "South Africa",
    phoneNumberPrefix: "+27",
    continentCode: "AF",
  },
  ES: {
    code: "ES",
    name: "Spain",
    phoneNumberPrefix: "+34",
    continentCode: "EU",
  },
  LK: {
    code: "LK",
    name: "Sri Lanka",
    phoneNumberPrefix: "+94",
    continentCode: "AS",
  },
  SD: {
    code: "SD",
    name: "Sudan",
    phoneNumberPrefix: "+249",
    continentCode: "AF",
  },
  SR: {
    code: "SR",
    name: "Suriname",
    phoneNumberPrefix: "+597",
    continentCode: "SA",
  },
  SJ: {
    name: "Svalbard and Jan Mayen",
    phoneNumberPrefix: "+47",
    continentCode: "EU",
  },
  SZ: {
    code: "SZ",
    name: "Swaziland",
    phoneNumberPrefix: "+268",
    continentCode: "AF",
  },
  SE: {
    code: "SE",
    name: "Sweden",
    phoneNumberPrefix: "+46",
    continentCode: "EU",
  },
  CH: {
    code: "CH",
    name: "Switzerland",
    phoneNumberPrefix: "+41",
    continentCode: "EU",
  },
  SY: {
    code: "SY",
    name: "Syria",
    phoneNumberPrefix: "+963",
    continentCode: "AS",
  },
  TW: {
    code: "TW",
    name: "Taiwan",
    phoneNumberPrefix: "+886",
    continentCode: "AS",
  },
  TJ: {
    code: "TJ",
    name: "Tajikistan",
    phoneNumberPrefix: "+992",
    continentCode: "AS",
  },
  TZ: {
    code: "TZ",
    name: "Tanzania",
    phoneNumberPrefix: "+255",
    continentCode: "AF",
  },
  TH: {
    code: "TH",
    name: "Thailand",
    phoneNumberPrefix: "+66",
    continentCode: "AS",
  },
  TO: {
    code: "TO",
    name: "Tonga",
    phoneNumberPrefix: "+676",
    continentCode: "OC",
  },
  TT: {
    name: "Trinidad and Tobago",
    phoneNumberPrefix: "+1-868",
    continentCode: "NA",
  },
  TN: {
    code: "TN",
    name: "Tunisia",
    phoneNumberPrefix: "+216",
    continentCode: "AF",
  },
  TR: {
    code: "TR",
    name: "Turkey",
    phoneNumberPrefix: "+90",
    continentCode: "AS",
  },
  TM: {
    code: "TM",
    name: "Turkmenistan",
    phoneNumberPrefix: "+993",
    continentCode: "AS",
  },
  TC: {
    name: "Turks and Caicos Islands",
    phoneNumberPrefix: "+1-649",
    continentCode: "NA",
  },
  TV: {
    code: "TV",
    name: "Tuvalu",
    phoneNumberPrefix: "+688",
    continentCode: "OC",
  },
  UG: {
    code: "UG",
    name: "Uganda",
    phoneNumberPrefix: "+256",
    continentCode: "AF",
  },
  UA: {
    code: "UA",
    name: "Ukraine",
    phoneNumberPrefix: "+380",
    continentCode: "EU",
  },
  AE: {
    name: "United Arab Emirates",
    phoneNumberPrefix: "+971",
    continentCode: "AS",
  },
  GB: {
    code: "GB",
    name: "United Kingdom",
    phoneNumberPrefix: "+44",
    continentCode: "EU",
  },
  US: {
    code: "US",
    name: "United States",
    phoneNumberPrefix: "+1",
    continentCode: "NA",
  },
  UY: {
    code: "UY",
    name: "Uruguay",
    phoneNumberPrefix: "+598",
    continentCode: "SA",
  },
  UZ: {
    code: "UZ",
    name: "Uzbekistan",
    phoneNumberPrefix: "+998",
    continentCode: "AS",
  },
  VU: {
    code: "VU",
    name: "Vanuatu",
    phoneNumberPrefix: "+678",
    continentCode: "OC",
  },
  VA: { code: "VA", name: "Vatican City State", phoneNumberPrefix: "+418" },
  VE: {
    code: "VE",
    name: "Venezuela",
    phoneNumberPrefix: "+58",
    continentCode: "SA",
  },
  VN: {
    code: "VN",
    name: "Vietnam",
    phoneNumberPrefix: "+84",
    continentCode: "AS",
  },
  VI: {
    name: "British Virgin Islands",
    phoneNumberPrefix: "+1-284",
    continentCode: "NA",
  },
  VQ: { code: "VQ", name: "U.S. Virgin Islands", phoneNumberPrefix: "+1-340" },
  WF: {
    name: "Wallis and Futuna",
    phoneNumberPrefix: "+681",
    continentCode: "OC",
  },
  YE: {
    code: "YE",
    name: "Yemen",
    phoneNumberPrefix: "+967",
    continentCode: "AS",
  },
  ZM: {
    code: "ZM",
    name: "Zambia",
    phoneNumberPrefix: "+260",
    continentCode: "AF",
  },
  ZW: {
    code: "ZW",
    name: "Zimbabwe",
    phoneNumberPrefix: "+263",
    continentCode: "AF",
  },
};

export const countriesOptions = Array.from(
  Object.keys(countriesList).map((countryCode) => ({
    value: countryCode,
    flag: flag(countryCode),
    label: countryName(countryCode) || "",
  })),
).sort((a, b) => a.label!.localeCompare(b.label!));

export default countries;
