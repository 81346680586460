import { difference, groupBy, intersection } from "remeda";

import { arrayFilterUnique } from "@helpers/Array";
import { Appointment } from "@models/Appointment";
import { Collection } from "@models/Collection";
import { Showroom } from "@models/Showroom";
import {
  AppointmentFormat,
  AppointmentFormatWithMeetingApp,
  AppointmentTypeEnum,
  CollectionRelatedAppointmentTypeList,
  VirtualMeetingApps,
} from "@models/types/enums";
import { extractSellers } from "@shared/helpers/portfolios";

type GenericShowroom = {
  appointmentFormats: AppointmentFormat[];
};

type GenericPortfolio = {
  collectionId: string | null;
  sellers: { id: string }[];
};
type GenericSeller = {
  id: string;
  appointmentTypes: AppointmentTypeEnum[];
  languages: string[];
  virtualMeetingAppLinks: Partial<Record<VirtualMeetingApps, string>> | null;
};

type BookedAppointment = Appointment & {
  collection: Pick<Collection, "id" | "name">;
};

// This interface decribes what each seller covers, including
// - the appointment type
// - the appointment format
// - the collections
// - the languages
// It is used to filter the sellers in the booking process
export interface SellerInformations {
  id: string;
  appointmentTypes: AppointmentTypeEnum[];
  formatsWithMeetingApps: AppointmentFormatWithMeetingApp[];
  collectionIds: string[];
  languages: string[];
}

export function extractVirtualMeetingApps(
  selectedAppointmentsFormats: AppointmentFormatWithMeetingApp[],
) {
  return selectedAppointmentsFormats
    .filter((f) => f !== "IN_PERSON")
    .map((f) => f.replace("VIRTUAL_", ""));
}

export function toFormatWithMeetingApp(
  virtualMeetingApps: string[],
): AppointmentFormatWithMeetingApp[] {
  return virtualMeetingApps.map(
    (a) => `VIRTUAL_${a as VirtualMeetingApps}` as const,
  );
}

function computeAvailableFormatsWithMeetingApps(
  showroom: GenericShowroom,
  virtualMeetingAppsLinks: { [key in VirtualMeetingApps]?: string },
) {
  let availableFormatsWithMeetingApps: AppointmentFormatWithMeetingApp[] = [];

  if (showroom.appointmentFormats.includes("IN_PERSON")) {
    availableFormatsWithMeetingApps.push("IN_PERSON");
  }

  if (showroom.appointmentFormats.includes("VIRTUAL")) {
    availableFormatsWithMeetingApps = availableFormatsWithMeetingApps.concat(
      toFormatWithMeetingApp(Object.keys(virtualMeetingAppsLinks)),
    );
  }

  return availableFormatsWithMeetingApps;
}

/**
 * Sellers have languages and virtual meeting apps in their data
 * This functions adds the collections and appointment types to the seller data
 * @param sellers : the list of sellers to transform
 * @param isOrganizationBasedOnWholeShowroom :
 * - if true, each seller will cover the collections from their portfolios (using param portfolios)
 * - otherwise, they will cover every single collection (using param selectedCollectionsIds)
 * @param portfolios
 * @param collectionIds
 */
export function transformToSellerInformations(
  sellers: {
    id: string;
    appointmentTypes: AppointmentTypeEnum[];
    virtualMeetingAppLinks: { [key in VirtualMeetingApps]?: string } | null;
    languages: string[];
  }[],
  showroom: GenericShowroom,
  portfolios: {
    sellers: { id: string }[];
    collectionId: string | null;
  }[],
): SellerInformations[] {
  return sellers.map((s) => ({
    id: s.id,
    appointmentTypes: s.appointmentTypes,
    formatsWithMeetingApps: computeAvailableFormatsWithMeetingApps(
      showroom,
      s.virtualMeetingAppLinks || {},
    ),
    collectionIds: portfolios
      .filter((p) => p.sellers.map((ps) => ps.id).includes(s.id))
      .map((p) => p.collectionId as string), // force as string because TS doesn't understand that we filter null values
    languages: s.languages,
  }));
}

export function filterSellersBasedOnPortfolios<
  P extends GenericPortfolio,
  S extends GenericSeller,
>(
  accountPortfolios: P[],
  showroomSellers: S[],
  selectedCollectionsIds?: string[],
) {
  // filter only if collections are defined
  const filteredPortfolios =
    selectedCollectionsIds === undefined ||
    selectedCollectionsIds[0] === AppointmentTypeEnum.WALKTHROUGH
      ? accountPortfolios
      : accountPortfolios.filter(
          (p) =>
            p.collectionId
              ? p.collectionId === selectedCollectionsIds[0]
              : true, // if organization is based on whole showroom, portfolios won't have any collectionId, so it's true
        );

  const availableSellers =
    filteredPortfolios.length > 0
      ? extractSellers(filteredPortfolios)
      : showroomSellers;

  const accountSellersIds = availableSellers.map((s) => s.id);
  const accountSellersShowroomSetup = showroomSellers.filter((s) =>
    accountSellersIds.includes(s.id),
  );
  return accountSellersShowroomSetup;
}

export function filterSellersBasedOnAppointmentType<
  P extends GenericPortfolio,
  S extends GenericSeller,
>(
  accountPortfolios: P[],
  showroomSellers: S[],
  selectedAppointmentType: AppointmentTypeEnum,
  selectedCollectionsIds?: string[],
) {
  const sellers = filterSellersBasedOnPortfolios(
    accountPortfolios,
    showroomSellers,
    selectedCollectionsIds,
  ).filter((s) => s.appointmentTypes.includes(selectedAppointmentType));

  return sellers;
}

export function filterSellersForStep3<
  P extends GenericPortfolio,
  S extends GenericSeller,
>(
  accountPortfolios: P[],
  showroomSellers: S[],
  selectedAppointmentType: AppointmentTypeEnum,
) {
  const sellers = filterSellersBasedOnAppointmentType(
    accountPortfolios,
    showroomSellers,
    selectedAppointmentType,
  );
  return sellers;
}

export function filterSellersForStep4<
  P extends GenericPortfolio,
  S extends GenericSeller,
>(
  accountPortfolios: P[],
  showroomSellers: S[],
  selectedAppointmentType: AppointmentTypeEnum,
  selectedAppointmentsFormats: AppointmentFormatWithMeetingApp[],
) {
  const step3Sellers = filterSellersForStep3(
    accountPortfolios,
    showroomSellers,
    selectedAppointmentType,
  );
  const virtualMeetingApps = extractVirtualMeetingApps(
    selectedAppointmentsFormats,
  );
  const step4Sellers =
    selectedAppointmentsFormats.includes("IN_PERSON") ||
    selectedAppointmentsFormats.length === 0
      ? step3Sellers
      : step3Sellers.filter(
          (s) =>
            intersection(
              Object.keys(s.virtualMeetingAppLinks || {}),
              virtualMeetingApps,
            ).length > 0,
        );
  return step4Sellers;
}

export function filterSellersBasedOnFormatAndLanguage<
  P extends GenericPortfolio,
  S extends GenericSeller,
>(
  accountPortfolios: P[],
  showroomSellers: S[],
  selectedAppointmentType: AppointmentTypeEnum,
  selectedAppointmentsFormats: AppointmentFormatWithMeetingApp[],
  selectedLanguages: string[],
) {
  const step4Sellers = filterSellersForStep4(
    accountPortfolios,
    showroomSellers,
    selectedAppointmentType,
    selectedAppointmentsFormats,
  );
  const step5Sellers =
    selectedLanguages.length > 0
      ? step4Sellers.filter(
          (s) => intersection(s.languages, selectedLanguages).length > 0,
        )
      : step4Sellers;
  return step5Sellers;
}

interface GetSellerInformationsProps<P, S, Sh> {
  currentStep: number;
  accountPortfolios: P[];
  showroom: Sh & {
    sellers: S[];
    collections: Pick<Collection, "id" | "name">[];
  };
  selectedAppointmentType: AppointmentTypeEnum | undefined;
  selectedAppointmentsFormats: AppointmentFormatWithMeetingApp[];
  selectedLanguages: string[];
}

export function getSellerInformations<
  P extends GenericPortfolio,
  S extends GenericSeller,
  Sh extends GenericShowroom,
>({
  currentStep,
  accountPortfolios,
  showroom,
  selectedAppointmentType,
  selectedAppointmentsFormats,
  selectedLanguages,
}: GetSellerInformationsProps<P, S, Sh>): SellerInformations[] {
  const { sellers: showroomSellers } = showroom;
  let sellers = [];
  // we do this filtering because sometimes sellers don't have any language (ask Louise)
  // a seller with no appointmentType or no language cannot match any criteria, so we exclude them
  // In the future we might want to filter those without any supported format
  const filteredShowroomSellers = showroomSellers.filter(
    (s) => s.appointmentTypes.length > 0 && s.languages.length > 0,
  );

  switch (currentStep) {
    case 1:
      sellers = filterSellersBasedOnPortfolios(
        accountPortfolios,
        filteredShowroomSellers,
      );
      break;
    case 2:
      sellers = filterSellersBasedOnAppointmentType(
        accountPortfolios,
        filteredShowroomSellers,
        selectedAppointmentType as AppointmentTypeEnum,
      );
      break;
    case 3:
      sellers = filterSellersBasedOnFormatAndLanguage(
        accountPortfolios,
        filteredShowroomSellers,
        selectedAppointmentType as AppointmentTypeEnum,
        selectedAppointmentsFormats,
        selectedLanguages,
      );
      break;
    default:
      return [];
  }
  const sellerInformations = transformToSellerInformations(
    sellers,
    showroom,
    accountPortfolios,
  );

  return sellerInformations;
}

export function getDisplayedAppointmentTypes<
  S extends Pick<Showroom, "appointmentTypesDuration">,
>(showroom: S) {
  return groupBy(showroom.appointmentTypesDuration, (item) => item.type);
}

export function getAvailableAppointmentTypes(
  sellerInformations: SellerInformations[],
) {
  return sellerInformations
    .flatMap((s) => s.appointmentTypes)
    .filter(arrayFilterUnique);
}

export function getBookedAppointmentsTypes(
  availableTypes: AppointmentTypeEnum[],
  bookedAppointments: {
    type: AppointmentTypeEnum;
  }[],
  sellerInformations: SellerInformations[],
): AppointmentTypeEnum[] {
  const nonCollectionRelatedTypes = bookedAppointments
    .map((ba) => ba.type)
    .filter((at) => !CollectionRelatedAppointmentTypeList.includes(at))
    .filter(arrayFilterUnique);

  // disable collection related appointment types where all collections are booked
  const availableCollectionIds = sellerInformations
    .flatMap((si) => si.collectionIds)
    .filter(arrayFilterUnique);
  const collectionRelatedTypes = availableTypes.filter(
    (at) =>
      // is the length of booked appointments for that type equals the available collections
      bookedAppointments.filter((ba) => ba.type === at).length ===
      availableCollectionIds.length,
  );
  return [...nonCollectionRelatedTypes, ...collectionRelatedTypes].filter(
    arrayFilterUnique,
  );
}

export function getBookedCollectionIds(
  bookedAppointments: {
    type: AppointmentTypeEnum;
    collection: { id: string } | null;
  }[],
): Collection["id"][] {
  return bookedAppointments
    .filter((ba) => CollectionRelatedAppointmentTypeList.includes(ba.type))
    .flatMap((ba) => ba.collection?.id || "") // use of empty string because it is used as a blacklist, so it won't interfere with anything
    .filter(arrayFilterUnique);
}

export function getBookedAppointmentForCollectionId(
  collectionId: Collection["id"],
  bookedAppointments: BookedAppointment[],
) {
  return bookedAppointments.find((ba) => ba.collection?.id === collectionId);
}

export function getUnavailableAppointmentTypes(
  sellerInformations: SellerInformations[],
  showroom: Pick<Showroom, "appointmentTypesDuration">,
) {
  const displayedAppointmentTypesWithDuration = Object.keys(
    getDisplayedAppointmentTypes(showroom),
  );
  const availableAppointmentTypes =
    getAvailableAppointmentTypes(sellerInformations);

  //   displayed appointment types
  // - available appointment types
  // = unavailable appointment types
  return difference(
    displayedAppointmentTypesWithDuration,
    availableAppointmentTypes,
  );
}
