import { z } from "zod";

export const AccountTypeList = [
  "BOUTIQUE",
  "DEPARTMENT",
  "FRANCHISE",
  "MULTIBRAND",
  "ONLINE",
] as const;

export const AccountStatusList = [
  "PROSPECT",
  "ACTIVE",
  "NOT_ACCESSIBLE",
] as const;
export const AccountStatusEnum = z.enum(AccountStatusList);
export type AccountStatus = (typeof AccountStatusList)[number];

export const organizationAccountSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.enum(AccountTypeList),
  status: AccountStatusEnum,
  city: z.string(),
  countryCode: z.string(),
  continentCode: z.string(),
  isKeyClient: z.boolean(),
  organizationId: z.string(),
});

export type OrganizationAccount = z.infer<typeof organizationAccountSchema>;
