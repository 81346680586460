import { useMutation } from "@tanstack/react-query";
import { z } from "zod";

import { contactSchema } from "@models/Contact";
import axiosInstance from "@services/api/config";

export namespace UpdateBuyerContact {
  export const inputSerializingSchema = contactSchema.pick({
    firstName: true,
    lastName: true,
    email: true,
    phoneNumber: true,
  });
  export type Input = z.input<typeof inputSerializingSchema>;

  export const outputHydratingSchema = contactSchema.pick({
    id: true,
    firstName: true,
    lastName: true,
    email: true,
    phoneNumber: true,
  });
  export type Output = z.output<typeof outputHydratingSchema>;

  interface MutationParams {
    data: Input;
  }

  type Params = MutationParams;

  export const path = () => `/buyer/contact`;

  export const call = (params: Params) =>
    axiosInstance
      .put(path(), inputSerializingSchema.parse(params.data))
      .then((res) => outputHydratingSchema.parse(res.data));

  export function useHook() {
    return useMutation({
      mutationFn: (mutationParams: MutationParams) => call(mutationParams),
    });
  }
}
