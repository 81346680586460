import React, { useCallback } from "react";

import { useQueries } from "@tanstack/react-query";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { HiCheck } from "react-icons/hi2";
import { toast } from "react-toastify";

import { BlueRow } from "@components/data-display/BlueRow";
import Button from "@components/data-entry/Button";
import { OrderDeadlineRequest } from "@models/OrderDeadlineRequest";
import { ShowroomSeason } from "@models/types/enums";
import { CreateOrderDeadlineRequest } from "@services/api/order-deadline-request/CreateOrderDeadlineRequest";
import { GetOrderDeadline } from "@services/api/order-deadline/get-order-deadline";

import { getNextFourFashionShows } from "../crm/components/header";

interface OpeningDays {
  day: Date;
  keyAccountsOpeningHour: Date | null;
  keyAccountsClosingHour: Date | null;
  customOpeningHour: Date | null;
  customClosingHour: Date | null;
}

interface Showroom {
  id: string;
  name: string;
  season: ShowroomSeason;
  year: number;
  openingDays: OpeningDays[];
  formattedAddress: string;
}

interface BuyerBrand {
  id: string;
  name: string;
  OrderDeadlineRequest: Pick<
    OrderDeadlineRequest,
    "brandId" | "season" | "year"
  >[];
  organization?: {
    id?: string;
    showrooms: Showroom[];
  };
}

interface BuyerBrandProps {
  buyerBrand: BuyerBrand;
}

function BuyerBrandsRowsDisplay({ buyerBrand }: BuyerBrandProps) {
  const { t } = useTranslation();
  const upcomingFourFashionSeasons = getNextFourFashionShows(
    new Date(),
    buyerBrand.id,
  );

  const queryArray = Array.from(upcomingFourFashionSeasons).map(
    ({ season, year }) => ({
      queryKey: GetOrderDeadline.getQueryKeys({
        brandId: buyerBrand.id,
        season,
        year,
      }),
      queryFn: () =>
        GetOrderDeadline.call({
          brandId: buyerBrand.id,
          season,
          year,
        }),
    }),
  );

  const { data: orderDeadlines } = useQueries({
    queries: queryArray,
    combine: (queries) => ({
      data: queries.flatMap((q) => q.data || []),
      error: queries.find((q) => q.error)?.error,
      status:
        queries.find((q) => q.status === "error")?.status ||
        queries.find((q) => q.status === "pending")?.status ||
        "success",
    }),
  });

  const { mutate: createOrderDeadlineRequest } =
    CreateOrderDeadlineRequest.useHook();

  const handleCreateOrderDeadlineRequest = useCallback(
    (params: CreateOrderDeadlineRequest.Input) => {
      createOrderDeadlineRequest({
        data: params,
      });
      toast.success(t("BuyerAccount.crm.toast.order-deadline-requested"));
    },
    [createOrderDeadlineRequest],
  );

  return (
    <>
      <div className="mt-5 mb-2 grid grid-cols-6">
        <p className="font-light text-left text-sm pl-2 col-span-1">
          {t("BuyerAccount.crm.secondary-table-header.season")}
        </p>
        <p className="font-light text-left text-sm pl-2 col-span-1">
          {t("BuyerAccount.crm.secondary-table-header.showroom-name")}
        </p>
        <p className="font-light text-left text-sm pl-4 col-span-1">
          {t("BuyerAccount.crm.secondary-table-header.opening-days")}
        </p>
        <p className="font-light text-left text-sm pl-4 col-span-2">
          {t("BuyerAccount.crm.secondary-table-header.location")}
        </p>
        <p className="font-light text-left text-sm pl-4 col-span-1">
          {t("BuyerAccount.crm.secondary-table-header.order-deadline")}
        </p>
      </div>

      <hr />

      <div className="mt-5">
        {upcomingFourFashionSeasons.map(({ season, year }) => {
          const matchingDeadline = orderDeadlines?.find(
            (deadline) =>
              deadline.season.toLowerCase() === season.toLowerCase() &&
              deadline.year === year &&
              deadline.brandId === buyerBrand.id,
          );
          const matchingRequest = buyerBrand.OrderDeadlineRequest.find(
            (request) =>
              request.brandId === buyerBrand.id &&
              request.season === season &&
              request.year === year,
          );

          // display all showrooms for all seasons (more or equal to four rows)
          const showroomsForSeason = buyerBrand.organization?.showrooms.filter(
            (showroom) =>
              showroom.season.toLowerCase() === season.toLowerCase() &&
              showroom.year === year,
          );

          // if no showrooms for the season, display the season and the deadline
          if (showroomsForSeason?.length === 0) {
            return (
              <BlueRow key={`${season}-${year}`} colsClass="grid-cols-6">
                <div className="py-3 px-4 font-light text-left col-span-5 flex items-center">
                  <p className="text-sm col-span-1">
                    {season} {year}
                  </p>
                </div>
                <div className="py-3 px-4 font-light text-left col-span-1">
                  <div className="text-sm col-span-">
                    {matchingDeadline?.deadline ? (
                      format(new Date(matchingDeadline.deadline), "MMM d, yyyy")
                    ) : (
                      <Button
                        theme="PRIMARY"
                        disabled={!!matchingRequest}
                        className="rounded-full"
                        onClick={() =>
                          handleCreateOrderDeadlineRequest({
                            brandId: buyerBrand.id,
                            season,
                            year,
                          })
                        }
                      >
                        {matchingRequest ? (
                          <>
                            <HiCheck className="size-4" />
                            {t("BuyerAccount.crm.button.already-requested")}
                          </>
                        ) : (
                          t("BuyerAccount.crm.button.request-deadline")
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </BlueRow>
            );
          }

          // if there are existing showrooms for the season, display the showroom data
          return showroomsForSeason?.map((showroom) =>
            buyerBrand.organization?.id ? (
              <BlueRow key={showroom.id} colsClass="grid-cols-6">
                <div className="py-3 px-4 font-light text-left col-span-1">
                  <p className="text-sm col-span-1">
                    {showroom.season} {showroom.year}
                  </p>
                </div>
                <div
                  key={showroom.id}
                  className="py-3 px-4 font-light text-left col-span-1"
                >
                  <p className="text-sm col-span-1">{showroom.name}</p>
                </div>
                <div className="text-sm py-3 px-4 font-light text-left col-span-1">
                  {format(
                    new Date(
                      showroom.openingDays[showroom.openingDays.length - 1].day,
                    ),
                    "MMM d yyyy",
                  )}{" "}
                  - {format(showroom.openingDays[0].day, "MMM d yyyy")}
                </div>
                <div className="py-3 px-4 font-light text-left col-span-2">
                  <p className="text-sm col-span-1">
                    {showroom.formattedAddress}
                  </p>
                </div>
                <div className="py-3 px-4 font-light text-left col-span-1">
                  <p className="text-sm col-span-1">
                    {matchingDeadline?.deadline
                      ? format(
                          new Date(matchingDeadline.deadline),
                          "MMM d, yyyy",
                        )
                      : "-"}
                  </p>
                </div>
              </BlueRow>
            ) : (
              <BlueRow key={showroom.id} colsClass="grid-cols-2">
                <div className="py-3 px-4 font-light text-left col-span-1">
                  <p className="text-sm col-span-1">
                    {showroom.season} {showroom.year}
                  </p>
                </div>
                <div className="py-3 px-4 font-light text-left col-span-1">
                  <p className="text-sm col-span-1">
                    {matchingDeadline?.deadline
                      ? format(
                          new Date(matchingDeadline.deadline),
                          "MMM d, yyyy",
                        )
                      : "-"}
                  </p>
                </div>
              </BlueRow>
            ),
          );
        })}
      </div>
    </>
  );
}

export default BuyerBrandsRowsDisplay;
