import React from "react";

import { Outlet } from "react-router-dom";

import { GuestOnly } from "@shared/components/guards/authentication";

export default function AuthLayout() {
  return (
    <GuestOnly>
      <section className="bg-blueGray-50">
        <div className="w-full lg:w-6/12 px-4 mx-auto pt-6">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            <div className="flex flex-col gap-4 items-stretch">
              <Outlet />
            </div>
          </div>
        </div>
      </section>
    </GuestOnly>
  );
}
